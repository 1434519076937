import React, {useEffect} from "react";
import {IFormValues, ServiceEntry} from "./index";
import {useServiceQuery, useUpdateServiceMutation} from "generated/graphql";
import {localCache} from "../../graphqlDocuments";
import {useNavigate, useParams} from "react-router-dom";
import {IRecordSavedProps, IUpdateRet, RecordSavedSourceEnum, SaveRetErrorType} from "../../types";
import {EntryMode, utils} from "../../utils";

interface IProps {
  recordSaved: (args: IRecordSavedProps) => void;
}
const INVALID = "INVALID"
export const ChangeServiceContainer: React.FC<IProps> = (props) => {
  const nav = useNavigate();
  let { serviceId = INVALID } = useParams();

  const {data, loading} = useServiceQuery({
    fetchPolicy: "network-only",
    variables: {
      companyId: localCache().companyId,
      serviceId
    },
    onCompleted() {
      if (serviceId === INVALID) {
        nav(-1)
      }
    }

  })

  useEffect(() => {
    if (serviceId === INVALID) {
      nav(-1)
    }
  })

  const initialValues: IFormValues = {
    serviceDescription: data?.service.service?.description,
    serviceCptCode: data?.service?.service?.cptCode||'',
  };

  const [updateService] = useUpdateServiceMutation();

  const handleCancel = () => {
    nav(-1);
  };

  const handleSave = async (values: IFormValues): Promise<IUpdateRet> => {
    try {
      const companyId = localCache().companyId;
      if (!values.serviceDescription) {
        return {
          success: false,
          errorMessage: "Please enter Service Description",
        };
      }

      const ret = await updateService({
        variables: {
          description: values.serviceDescription,
          cptCode: values.serviceCptCode,
          companyId: companyId,
          serviceId,
        },
      });
      const retService = ret?.data?.updateService;

      // if all ok, then return success and invoke recordSaved callback
      if (retService) {
        if (props.recordSaved) {
          const snackbarDescription = `Service Changed: ${retService.service?.description}`;
          props.recordSaved({
            source: RecordSavedSourceEnum.service,
            id: retService?.service?.id||'',
            snackbarDescription,
            optionsDescription: utils.formatService(retService?.service?.description, retService?.service?.cptCode)
          });
        }
        nav(-1);
        return { success: true };
      }

      return {
        success: false,
        errorMessage: "Error encountered during update",
      };
    } catch (e:any) {
      return {
        success: false,
        errorMessage: e.message || "got network error",
        errorType: SaveRetErrorType.Network,
      };
    }
  };


  return (
    <ServiceEntry
      initialValues={initialValues}
      handleCancel={handleCancel}
      handleSave={handleSave}
      mode={EntryMode.CHANGE}
      loading={loading}
    />
  );
};
