import React, { Dispatch } from "react";

import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import MenuIcon from "@mui/icons-material/Menu";
import { Checkbox, Fab, Paper, Toolbar, Button, useTheme } from "@mui/material";
import { IThemeInfo, ThemeTypeEnum } from "./types";
import { colorArray } from "utils";
import { Exact, UpdateThemeMutation } from "../../generated/graphql";
import { MutationFunctionOptions } from "@apollo/client";
import {makeStyles, createStyles} from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullScreen: {
      margin: `24px 16px`,
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-start",
        margin: `32px 24px}`,
      },
    },
    paper: {
      position: "relative",
      overflow: "hidden",
    },
    specs: {
      marginTop: 10,
      marginLeft: 24,
    },
    appFrame: {
      position: "relative",
      height: 200,
      // backgroundColor: theme.palette.background.paper,
    },
    menuButton: {
      marginRight: 20,
    },
    checkMark: {
      margin: "10px auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(1),
      left: theme.spacing(3),
    },
  })
);

interface IProps {
  idx: number;
  themeInfo: IThemeInfo;
  setThemeInfo: Dispatch<IThemeInfo>;
  isCustom: boolean;
  setOpen?: Dispatch<boolean>;
  updateThemeMutation: (
    options?:
      | MutationFunctionOptions<
      UpdateThemeMutation,
      Exact<{ primaryColor: string; secondaryColor: string }>
      >
      | undefined
  ) => Promise<any>;
}

export const ColorDemo: React.FC<IProps> = ({
                                              idx,
                                              themeInfo,
                                              setThemeInfo,
                                              isCustom,
                                              setOpen,
                                              updateThemeMutation,
                                            }) => {
  const theme = useTheme();
  const classes = useStyles();
  const primaryMain = isCustom
    ? themeInfo.customPrimary
    : colorArray[idx].primary;
  const secondaryMain = isCustom
    ? themeInfo.customSecondary
    : colorArray[idx].secondary;

  const primary = theme.palette.augmentColor({
    color: {
      main: primaryMain,
    },
  });

  const secondary = theme.palette.augmentColor({
    color: {
      main: secondaryMain,
    },
  });

  // const secondary = theme.palette.augmentColor({ main: secondaryMain });

  // const primary = theme.palette.augmentColor({ color: primaryMain, name: 'primary' })
  const isSelected =
    (themeInfo.presetIdx === idx &&
      themeInfo.themeType === ThemeTypeEnum.usePreset) ||
    (themeInfo.themeType === ThemeTypeEnum.custom && isCustom);

  const handleClick = () => {
    if (isCustom) {
      setThemeInfo({ ...themeInfo, themeType: ThemeTypeEnum.custom });
    } else {
      setThemeInfo({
        ...themeInfo,
        themeType: ThemeTypeEnum.usePreset,
        presetIdx: idx,
      });
      localStorage.setItem("primaryColor", primaryMain)
      localStorage.setItem("secondaryColor", secondaryMain)
      // debugger;
      updateThemeMutation({
        variables: {
          primaryColor: primaryMain,
          secondaryColor: secondaryMain,
        },
      });
    }
  };

  return (
    <div className={classes.fullScreen}>
      <Paper
        className={classes.paper}
        elevation={isSelected ? 20 : 3}
        onClick={handleClick}
      >
        <div className={classes.appFrame}>
          <AppBar position="static" style={{ backgroundColor: primary.main }}>
            <Toolbar style={{ color: primary.contrastText }}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                size="large">
                <MenuIcon />
              </IconButton>

              <Typography component="div" variant="h6" color="inherit">
                {idx === 0 ? "DEFAULT" : "Theme"}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.checkMark}>
            <Typography variant="body1">
              {isCustom ? "Customize:" : "Select Theme:"}
            </Typography>
            <Checkbox
              checked={isSelected}
              onChange={handleClick}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
          {/*<div className={classes.checkMark}>{isSelected && '\u2714'}</div>*/}
          {isSelected && isCustom ? (
            <Button
              style={{
                color: secondary.contrastText,
                backgroundColor: secondary.main,
              }}
              variant="contained"
              className={classes.fab}
              onClick={() => {
                setOpen && setOpen(true);
              }}
            >
              Edit
            </Button>
          ) : (
            <Fab
              className={classes.fab}
              style={{
                color: secondary.contrastText,
                backgroundColor: secondary.main,
              }}
              aria-label="add"
            >
              <AddIcon htmlColor={secondary.contrastText} />
              {/*Secondary*/}
            </Fab>
          )}
        </div>
      </Paper>
      <div className={classes.specs}>
        <div>{`Primary: ${primary.main}`}</div>
        <div>{`Secondary: ${secondary.main}`}</div>
      </div>
    </div>
  );
};
