import mobile from "is-mobile";
import {APP_START_DATE} from "./constants";
import dayjs from "dayjs";
import { Client } from "../generated/graphql";
// import dayjs from "dayjs";

// const md = new MobileDetect(window.navigator.userAgent)
const isTouchDevice = mobile({ tablet: true });

export const utils = {
  isTouchDevice,
  formatService(
    description: string = "Session",
    cptCode: string | undefined | null = ""
  ) {
    // const cpt = cptCode ? `${cptCode}` : "";
    const ret = cptCode ? `${cptCode} ${description}` : description;
    return ret;
  },
  defaultImportThruDate() {
    const dayOfMonth = dayjs().date()
    const date = dayOfMonth < 5 ? dayjs().subtract(1, "month").endOf("month") : dayOfMonth > 25 ? dayjs().endOf("month") : dayjs();
    return date.format("YYYY-MM-DD");

  },
  todayIso() {
    const isoDate = new Date().toISOString().substr(0, 10);
    return isoDate;
  },
  fullClientName(client:Partial<Client> ) {
    let fullName1, fullName2;
    if (!client?.firstName2 && !client?.lastName2) {
      fullName1 = `${client?.firstName} ${client?.lastName}`;
    } else if (client?.firstName2 && !client?.lastName2) {
      fullName1 = `${client?.firstName} and ${client?.firstName2} ${client?.lastName}`;
    } else {
      fullName1 = `${client?.firstName} ${client?.lastName}`;
      fullName2 = `${client?.firstName2} ${client?.lastName2}`;
    }
    return {fullName1, fullName2}
  },
  isValidDateString(isoDate: string): boolean {
    const parseDate = Date.parse(isoDate);
    if (isoDate < APP_START_DATE  || isoDate > "2060") {
      return false
    }
    if (isNaN(parseDate)) {
      return false;
    } else {
      const date = new Date(isoDate);
      const formatted = date.toISOString().substr(0, 10);
      return formatted === isoDate;
    }
  },
  isValidDate(d: any) {
    return d instanceof Date && !isNaN(d.getTime());
  },
  randomString: () => `${Math.random().toString(36).substr(4, 7)}`,
  UriLastSegment: (uri: string | null) => {
    if (!uri) {
      return null;
    }
    const parts = uri
      .toLowerCase()
      .trim()
      .split("/")
      .filter((n) => n);
    if (parts.length === 0) {
      return null;
    }
    return parts[parts.length - 1];
  },

};



