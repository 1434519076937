import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);

export const formatCurrency = function (
  locale: string,
  currency: string,
  minFractionDigits?: number,
  maxFractionDigits?: number
) {
  const maxDigits =  maxFractionDigits === undefined ? 2 : maxFractionDigits
  var formatter = new Intl.NumberFormat(locale, {
    currency: currency,
    maximumFractionDigits: maxFractionDigits ? maxFractionDigits : maxDigits,
    minimumFractionDigits: minFractionDigits ? minFractionDigits : 0,
    minimumIntegerDigits: 1,
    style: "currency",
    useGrouping: true,
  });
  return formatter;
};

export const formatDateCustomized = (date: string | null) => {
  const nineMonthsAgo = dayjs().subtract(9, "months");
  if (!date || date < "2000") {
    return "n/a";
  }

  const dayjsDate = dayjs(date);
  if (dayjsDate.isToday()) {
    return "Today";
  } else if (dayjsDate.isYesterday()) {
    return "Yesterday";
  } else if (dayjsDate.isAfter(nineMonthsAgo)) {
    return dayjsDate.format("MMM Do");
  } else {
    // hide suffix if small
    return dayjsDate.fromNow();
  }
};
