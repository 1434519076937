import {Stack} from "@mui/material";
import React from "react";


interface IFieldWrapper {
  spacing?: string
  children: React.ReactNode
}

export const FieldWrapper = (props:IFieldWrapper) => {
  const {children, spacing = "16px"} = props;
  return (
    <Stack spacing={spacing}>
      {children}
    </Stack>
  )
}
