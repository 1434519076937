import React from "react";
import { Card, CardContent } from "@mui/material";
import { Form, Formik, FormikErrors, FormikProps, FormikValues } from "formik";
import { IFormValues, SetSubmitting } from "./index";
import { TextInput } from "components/TextInput";
import { BackButton, SubmitButton } from "../Buttons";
// import {EntryMode} from "../../helpers";
import { EntryContainer } from "../Containers";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { EntryMode } from "../../utils";
import {DataEntrySkeleton} from "../Skeleton/DataEntrySkeleton";

interface IProps {
  mode: EntryMode;
  initialValues: IFormValues;
  handleCancel(): void;
  handleSave(values: IFormValues, setSubmitting: SetSubmitting): any;
  loading?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 5,
    [theme.breakpoints.up("md")]: {
      marginTop: 15,
    },
  },
  fieldWrapper: {
    marginTop: 7,
    "& > div": {
      marginBottom: "16px",
      "&:last-child": {
        marginBottom: 1,
      },
    },
  },
  submit: {
    margin: "30px 15px 20px auto",
    display: "block",
    width: 120,
  },
}));

const validate = (values: FormikValues) => {
  const errors: FormikErrors<IFormValues> = {};
  if (!values.serviceDescription) {
    errors.serviceDescription = "Enter service Description";
  }
  return errors;
};

export const ServiceEntry: React.FC<IProps> = (props) => {
  const { loading = false, initialValues, handleSave, mode } = props;
  const classes = useStyles();

  if (loading) {
    return (
      <DataEntrySkeleton
        title={mode === EntryMode.ADD ? `Add Service` : `Change Client`}
      />
    );
  }

  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => {
        const { setSubmitting } = formikHelpers;
        handleSave(values, setSubmitting);
      }}
    >
      {(formikProps: FormikProps<IFormValues>) => {
        const { isSubmitting, isValidating } = formikProps;
        return (
          <Form noValidate autoComplete="off" className={classes.root}>
            <BackButton label="Cancel" />
            <EntryContainer
              title={
                mode === EntryMode.ADD ? `Add Service` : "Change Service"
              }
            >
              <div>
                <Card>
                  <CardContent>
                    <div className={classes.fieldWrapper}>
                      <TextInput
                        required
                        label="Service Description"
                        name="serviceDescription"
                        disabled={isSubmitting}
                        helperText="e.g.Individual psychotherapy 45 minutes"
                      />
                      <TextInput
                        label="Insurance/CPT Code"
                        name="serviceCptCode"
                        disabled={isSubmitting}
                        helperText="e.g. 90834 (optional)"
                      />
                    </div>
                  </CardContent>
                </Card>
                <SubmitButton disabled={isSubmitting || isValidating} />
                {/*<Button*/}
                {/*  variant="contained"*/}
                {/*  type="submit"*/}
                {/*  className={classes.submit}*/}
                {/*  disabled={isSubmitting || isValidating}*/}
                {/*>*/}
                {/*  Save*/}
                {/*</Button>*/}
              </div>
            </EntryContainer>
          </Form>
        );
      }}
    </Formik>
  );
};
