import validator from "validator";

export const validateEmailList = (list:string|undefined):string|null => {
  if (!list) {
    return "Please enter email address"
  }

  // clean up input list - remove false positive when user types a comma.
  const wkList = list.trim().slice(-1) === ',' ? list.slice(0, -1) : list
  const array = wkList.split(',');
  const errors = array.filter((email:string) => {
    const valid = validator.isEmail(email.trim())
    return !valid
  })

  if (errors.length === 0) {
    return null;
  }


  // for now, just return one invalid email
  return `${errors[0]} is an invalid email`
}
