import { Box, Button } from "@mui/material";
import {AuthenticationService} from "../../services";

export function ErrorBoundaryScreen(props: any) {
  console.log("errors", props);
  debugger;
  var getUrl = window.location;
  const url = getUrl.protocol + "//" + getUrl.host;
  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: "white", padding: "20px" }}
    >
      <Box display="flex" flexDirection="column">
        <Box style={{ justifySelf: "center", fontSize: "100px", color: "red" }}>
          {"\u26A0"}
        </Box>
        <Box mt="20px" typography="h4">
          Oops!!
        </Box>
        <Box mt="20px" typography="h5">
          Something went wrong.
        </Box>
        <Box mt="20px" typography="h5">
          We've been notified and will look into this right away.
        </Box>
        <Button
          onClick={() => {
            AuthenticationService.signOut()
            window.location.href = `${url}/home`;
          }}
          size="large"
          type="submit"
          variant="contained"
          sx={{ maxWidth: "250px", mt: "40px" }}
        >
          Return to Home screen
        </Button>
      </Box>
    </Box>
  );
}
