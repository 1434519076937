import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { IFormValues } from "./index";
import { InvoiceReportRequest, PdfViewer } from "components/Reports";
import {
  ReportDispositionEnum,
  ReportFormatEnum,
  useInvoiceReportMutation,
} from "generated/graphql";
import { IUpdateRet, SaveRetErrorType } from "types";
import { localCache } from "graphqlDocuments";
import dayjs from "dayjs";
import {AuthenticationService} from "../../../services";

export const InvoiceReportController: React.FC = () => {
  const [executeInvoiceReport, { data }] = useInvoiceReportMutation({});

  const executeRequest = async (values: IFormValues): Promise<IUpdateRet> => {
    try {
      const companyId = localCache().companyId;
      let startDate;
      let endDate;
      const dateFormat = "YYYY-MM-DD";
      switch (values.dateChoice) {
        case "thisMonth": {
          startDate = dayjs().startOf("month").format(dateFormat);
          endDate = dayjs().endOf("month").format(dateFormat);
          break;
        }
        case "lastMonth": {
          startDate = dayjs().subtract(1, "month").startOf("month").format(dateFormat);
          endDate = dayjs().subtract(1, "month").endOf("month").format(dateFormat);
          break;
        }
        case "thisYear": {
          startDate = dayjs().startOf("year").format(dateFormat);
          endDate = dayjs().endOf("year").format(dateFormat);
          break;
        }
        case "lastYear": {
          startDate = dayjs()
            .startOf("year")
            .subtract(1, "year")
            .format(dateFormat);
          endDate = dayjs()
            .endOf("year")
            .subtract(1, "year")
            .format(dateFormat);
          break;
        }
        case "all": {
          startDate = "1000-01-01";
          endDate = "3000-01-01";
          break;
        }
      }
      const variables = {
        companyId,
        startDate,
        endDate,
        reportFormat:
          values.reportFormat === "PDF"
            ? ReportFormatEnum.Pdf
            : ReportFormatEnum.Csv,
        reportDisposition:
          values.reportFormat === "PDF"
            ? ReportDispositionEnum.Print
            : ReportDispositionEnum.Download,
      };

      const { data } = await executeInvoiceReport({
        variables,
      });

      if (!data?.invoiceReport.success) {
        return {
          success: false,
          errorMessage: data?.invoiceReport.error?.message,
        };
      }

      if (values.reportFormat === "CSV") {
        const fileName = data?.invoiceReport?.fileName;
        if (!fileName) {
          return {
            success: false,
            errorMessage: "Error occurred - please try again",
          };
        }

        const token = await AuthenticationService.getToken();
        const input = `${process.env.REACT_APP_API_URL}/download/${fileName}`;
        await fetch(input, {
          headers: {
            authorization: token ? `Bearer ${token}` : "",
            companyid: localCache().companyId,
          }
        }).then((response) => {
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = "invoices.csv";
            a.click();
          });
        });
      }
      return {
        success: true,
      };
    } catch (e) {
      console.log("error", e);
      return {
        success: false,
        errorMessage: "got network error",
        errorType: SaveRetErrorType.Network,
      };
    }
  };

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={<InvoiceReportRequest executeRequest={executeRequest} />}
        />
        <Route
          path="pdf-viewer"
          element={<PdfViewer base64Pdf={data?.invoiceReport?.base64Pdf} />}
        />
      </Routes>
      <Outlet />
    </React.Fragment>
  );
};
