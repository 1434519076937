import React, { useEffect, useState } from "react";
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikValues,
} from "formik";
import { Box, Button, Container, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextInput } from "components/TextInput";
import { useAuth } from "../../context";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import validator from "validator";
import {AlertDialog, IAlertProps} from "../../components/Alert";

const useStyles = makeStyles(() => ({
  panel: {
    height: 200,
    maxWidth: 350,
    padding: 20,
    // margin: "0 20px",
    position: "relative",
    width: "80vw",
  },
  fieldWrapper: {
    marginTop: 7,
    "& > div": {
      marginBottom: "16px",
      "&:last-child": {
        marginBottom: 1,
      },
    },
  },
}));

interface IFormValues {
  email: string;
}

const initialValues: IFormValues = {
  email: "",
};

const validate = (values: FormikValues) => {
  const errors: FormikErrors<IFormValues> = {};

  if (!values.email) {
    errors.email = "Please enter Email";
  }

  if (!validator.isEmail(values.email.trim())) {
    errors.email = "Enter valid Email";
  }

  return errors;
};

export function ForgotPassword() {
  const classes = useStyles();
  const { forgotPassword } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [alertArgs, setAlertArgs] = React.useState<IAlertProps>({
    open: false,
    message: "",
    title: "",
  });
  const nav = useNavigate();
  const [emailSent, setEmailSet] = useState(false);
  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    formikHelpers.setSubmitting(true);
    try {
      await forgotPassword(values.email);
      setEmailSet(true);
    } catch (e) {
      setAlertArgs({
        open: true,
        title: "Correction required",
        message: "Invalid Email",
      });

    }
  };

  useEffect(() => {
    if (!emailSent) {
      return;
    }
    enqueueSnackbar("Please check your email to reset password", {
      variant: "success",
    });
    nav("/sign-in", { replace: true });
  }, [emailSent, enqueueSnackbar, nav]);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formikProps: FormikProps<IFormValues>) => {
        const { isSubmitting } = formikProps;
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "90vh",
              width: "100vw",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AlertDialog alertProps={alertArgs} setAlertProps={setAlertArgs} />
            <Container
              maxWidth="md"
              sx={{width: "350px" }}
              disableGutters={true}
            >
              <Box textAlign="center" mb={3}>
                <Box typography="h4">Workbox</Box>
                <Box typography="h4">Forgot Password</Box>
                <Box m="20px 8px 10px 8px" typography="body1">
                  Enter the email address you use to sign in, and we’ll send you
                  a link to reset your password.
                </Box>
              </Box>
              <Form noValidate={true}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Paper elevation={8} className={classes.panel}>
                    <div className={classes.fieldWrapper}>
                      <TextInput type="email" name="email" required />
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        mt: 6,
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        onClick={() => nav(-1)}
                        variant="text"
                        color="primary"
                        type="submit"
                      >
                        Cancel
                      </Button>

                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Send reset link
                      </Button>
                    </Box>
                  </Paper>
                </Box>
              </Form>
            </Container>
          </Box>
        );
      }}
    </Formik>
  );
}
