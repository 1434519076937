import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import {Box, Typography, useTheme} from "@mui/material";

// import {Backdrop} from "@mui/material";

interface IUseStyles {
  loading: boolean;
}

const useMakeStyles = (props: IUseStyles):any => {
  const theme = useTheme();
  const position = "fixed";
  const flexDirection = "column";
  return {
    fullScreen: {
      display: props.loading ? "flex" : "none",
      top: 0,
      left: 0,
      zIndex: 9999,
      width: "100vw",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      position,
      flexDirection,
    },
    msg: {
      marginLeft: "20px",
      color: "white",
      marginTop: "30px",
      backgroundColor: "rgb(32,32,32)",
      fontSize: "1.5rem",
      padding: "15px",
      ml:{xs:0, lg:"240px"}
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    progress: {
      height: "40px",
      ml:{xs:0, lg:"240px"}
    },
  };
};

// const useStyles = makeStyles((theme:Theme) =>
//   createStyles({
//     fullScreen: (props: IUseStyles) => ({
//       display: props.loading ? "flex" : "none",
//       flexDirection: "column",
//       alignItems: "center",
//       justifyContent: "center",
//       width: "100vw",
//       height: "100vh",
//       position: "fixed",
//       top: 0,
//       left: 0,
//       zIndex: 9999,
//       // backgroundColor: "rgba(0,0,0,.20)"
//     }),
//     msg: {
//       marginLeft: 20,
//       color: "white",
//       marginTop:30,
//       backgroundColor: "grey",
//       fontSize:"1.5rem",
//       padding:"15px"
//     },
//     backdrop: {
//       zIndex: theme.zIndex.drawer + 1,
//       color: "#fff",
//     },
//     progress: {
//       height: 40,
//     },
//   })
// );

export function LoadingScreen({
                                loading = true,
                                msg = "Loading...",
                              }: {
  loading?: boolean;
  msg?: string;
}) {
  // const classes = useStyles({ loading });

  const classes = useMakeStyles({ loading });

  if (!loading) {
    return null;
  }

  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <Box sx={classes.fullScreen} onClick={handleClick}>
      <Box sx={classes.progress}>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? "800ms" : "0ms",
          }}
          unmountOnExit
        >
          <div>
            <CircularProgress disableShrink={true} />
          </div>
        </Fade>
      </Box>
      <Typography sx={classes.msg} variant="h6">
        {msg}
      </Typography>
    </Box>
  );
}
