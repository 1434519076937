import { useEffect, useState } from "react";
import { LoadingScreen } from "../../components/LoadingScreen";
import {
  ProductPurchasedEnum,
  useCreateCheckoutSessionMutation,
} from "../../generated/graphql";
import { localCache } from "../../graphqlDocuments";
import { Box } from "@mui/material";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "");

export const StripePaymentEntry = () => {
  const [sessionUrl, setSessionUrl] = useState<null | string>(null);
  // const loc = useLocation();
  // const nav = useNavigate();
  const [
    createCheckoutSession,
    { loading, error, data },
  ] = useCreateCheckoutSessionMutation({
    onCompleted(data) {
      if (data.createCheckoutSession.success) {
        setSessionUrl(data?.createCheckoutSession?.sessionUrl!);
      }
    },
  });

  useEffect(() => {
    if (sessionUrl) {
      window.location.href = sessionUrl;
    }
  }, [sessionUrl]);

  useEffect(() => {
    // get current route, find last section and then remove it.
    const url = window.location.href.split("?")[0].replace(/\/$/, "");
    const parts = url.split("/");
    const finalSection = parts[parts.length - 1];
    const myhref = url.split(finalSection)[0];
    const cancelUrl = myhref + "stripe-cancelled";
    const successUrl = myhref + "stripe-successful";
    createCheckoutSession({
      variables: {
        companyId: localCache().companyId,
        cancelUrl,
        successUrl,
        productPurchased: ProductPurchasedEnum.Standard,
      },
    });
  }, [createCheckoutSession]);

  if (loading || error) {
    return <LoadingScreen loading={true} />;
  }

  // if (!clientSecret) {
  //   return (
  //     <AlertDialog alertProps={alertArgs} setAlertProps={setAlertArgs} />
  //   )
  // }
  // const options = {
  //   clientSecret,
  // };

  return (
    <Box sx={{ m: "100px auto" }}>
      {data?.createCheckoutSession?.sessionUrl}
    </Box>
  );

  // return (
  //  <Elements stripe={stripePromise} options={options}>
  //     <CheckoutForm />
  //  </Elements>
  // );
};
