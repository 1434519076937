import {IFormValues} from "./index";
import React from "react";
import {AlertDialog, IAlertProps} from "components/Alert";
import {Form, Formik, FormikErrors, FormikHelpers, FormikValues,} from "formik";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import {EntryContainer} from "components/Containers";
import {SubmitButton} from "components/Buttons";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import {IUpdateRet} from "../../../types";
import {useNavigate} from "react-router-dom";
import { useTopOfForm } from "../../../hooks";

interface IProps {
  executeRequest: (values: IFormValues, file?: File) => Promise<IUpdateRet>;
}

export const PaymentReportRequest: React.FC<IProps> = ({ executeRequest }) => {
  const [alertArgs, setAlertArgs] = React.useState<IAlertProps>({
    open: false,
    message: "",
    title: "",
  });

  const nav = useNavigate();
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);

  // const classes = useStyles();

  useTopOfForm()

  const MenuItems = [
    <MenuItem key={1} value="all">
      All Dates
    </MenuItem>,
    <MenuItem key={2} value="ytd">
      This Year
    </MenuItem>,
    <MenuItem key={3} value="lastYear">
      Last Year
    </MenuItem>,
    <MenuItem key={4} value="custom">
      Custom
    </MenuItem>,
  ];

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<IFormValues> = {};

    if (values.dateChoice === "custom") {
      if (!values.dateRange[0] || !values.dateRange[1]) {
        errors.dateRange = "Select dates";
      }
    }

    return errors;
  };

  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    const ret = await executeRequest(values);
    formikHelpers.setSubmitting(false);
    if (ret.success) {
      if (values.reportFormat === "PDF") {
        nav("pdf-viewer");
      } else {
        setAlertArgs({
          open: true,
          title: "Status",
          message: "CSV Download complete",
        });

      }
    } else {
      setAlertArgs({
        open: true,
        title: "Report Unsuccessful",
        message: ret.errorMessage,
      });
    }
  };

  const initialValues: IFormValues = {
    dateChoice: "all",
    dateRange: [null, null],
    reportFormat: "PDF",
    reportDisposition: "PRINT",
  };

  return (
    <Box mt="15px">
      <AlertDialog alertProps={alertArgs} setAlertProps={setAlertArgs} />
      <Formik
        enableReinitialize={true}
        validate={validate}
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          handleSubmit(values, formikHelpers);
        }}
      >
        {(formikHelpers) => {
          const {
            values,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            errors,
            touched,
          } = formikHelpers;
          const dateRangeError =
            touched.dateRange && !datePickerOpen ? errors.dateRange : "";
          return (
            <Form noValidate autoComplete="off" >
              <EntryContainer title="Payments Report">
                <Card raised={true}>
                  <CardContent>
                    <Stack spacing="16px">
                      <FormControl variant="filled" fullWidth>
                        <InputLabel id="date-choice-label">Dates</InputLabel>
                        <Select
                          labelId="date-choice-label"
                          id="date-select"
                          value={values.dateChoice}
                          label="Date Range"
                          onChange={(event) => {
                            setFieldValue("dateChoice", event.target.value);
                          }}
                        >
                          {MenuItems}
                        </Select>
                      </FormControl>
                      <Box
                        sx={{
                          display:
                            values.dateChoice !== "custom" ? "none" : "block",
                        }}
                      >
                        <DateRangePicker
                          // startText="Start Date"
                          // endText="End Date"
                          // allowSameDateSelection={true}
                          onOpen={() => {
                            setDatePickerOpen(true);
                            setFieldTouched("dateRange");
                          }}
                          open={datePickerOpen}
                          onClose={() => setDatePickerOpen(false)}
                          value={values.dateRange}
                          onChange={(newValue: any) => {
                            // console.log("keyboard input", keyboardInputValue)
                            setFieldValue("dateRange", newValue);
                          }}
                          renderInput={(startProps:any, endProps:any) => (
                            <React.Fragment>
                              <TextField
                                sx={{ flexGrow: 1 }}
                                {...startProps}
                                error={Boolean(dateRangeError)}
                                helperText={dateRangeError}
                              />
                              <Box sx={{ mx: 2 }}> to </Box>
                              <TextField
                                sx={{ flexGrow: 1 }}
                                {...endProps}
                                helperText=""
                              />
                            </React.Fragment>
                          )}
                        />
                      </Box>
                      <FormGroup
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        {/*<Box mr="10px">Report format</Box>*/}
                        <RadioGroup
                          onChange={(event) => {
                            const value = (event.target as HTMLInputElement)
                              .value;
                            setFieldValue("reportFormat", value, true);
                          }}
                          value={values.reportFormat}
                          row
                          aria-label="report-format"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="PDF"
                            control={<Radio />}
                            label="PDF"
                          />
                          <FormControlLabel
                            value="CSV"
                            control={<Radio />}
                            label="CSV"
                          />
                        </RadioGroup>
                      </FormGroup>
                      {/*<FormGroup sx={{ display:"flex", alignItems:"center", flexDirection:"row" }}>*/}
                      {/*  /!*<Box mr="10px">Report format</Box>*!/*/}
                      {/*  <RadioGroup*/}
                      {/*    onChange={(event) => {*/}
                      {/*      const value = (event.target as HTMLInputElement).value ;*/}
                      {/*      setFieldValue(*/}
                      {/*        "reportDisposition",*/}
                      {/*        value,*/}
                      {/*        true*/}
                      {/*      );*/}
                      {/*    }}*/}
                      {/*    value={values.reportDisposition}*/}
                      {/*    row*/}
                      {/*    aria-label="report-disposition"*/}
                      {/*    name="row-radio-buttons-group"*/}
                      {/*  >*/}
                      {/*    <FormControlLabel value="PRINT" control={<Radio />} label="View" />*/}
                      {/*    <FormControlLabel value="DOWNLOAD" control={<Radio />} label="Download" />*/}
                      {/*  </RadioGroup>*/}
                      {/*</FormGroup>*/}
                    </Stack>
                  </CardContent>
                </Card>
                <SubmitButton disabled={isSubmitting} label="Submit" />
              </EntryContainer>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
