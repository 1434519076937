// capture firebase sign-in status
// if has a valid "currentUser", then load up "Me" data which gets company assignments
// And then get basic Startup data (e.g., languages, clients, sessions, etc.)
// follow AuthStatus to see different stages this process can go through

import React, { Dispatch, useContext, useEffect, useState } from "react";
import { AuthenticationService } from "services";
import { useApolloClient } from "@apollo/client";
import { PENDING } from "utils/constants";
import { ServerError } from "@apollo/client/link/utils";
import { ServerParseError } from "@apollo/client/link/http";
import { GraphQLError } from "graphql/error";
import { useMeQuery, useStartupQuery } from "../generated/graphql";
import { localCache } from "../graphqlDocuments";
import { AuthStatus } from "../types";
import { LoadingScreen } from "../components/LoadingScreen";
import { auth } from "services/Core";

import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  User,
} from "firebase/auth";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { GraphQLErrorScreen } from "../components/Errors";

interface IErrors {
  graphQLErrors?: ReadonlyArray<GraphQLError>;
  networkErrors?: Error | ServerError | ServerParseError;
}

interface IAuth {
  currentUser: User | null | "pending";
  setCurrentUser: Dispatch<User | null>;
  errors?: IErrors;
  setErrors: Dispatch<IErrors> | null;
  authStatus: AuthStatus;
  setAuthStatus: Dispatch<AuthStatus>;
  login: (email: string, password: string) => any;
  registerFirebase: (email: string, password: string) => any;
  forgotPassword: (email: string) => any;
  resetPassword: (oobCode: string, newPassword: string) => any;
  signOut: () => any;
}

export const useAuth = () => useContext(AuthenticationContext);

export const AuthenticationContext = React.createContext<IAuth>({
  currentUser: null,
  setCurrentUser: () => {},
  errors: undefined,
  setErrors: () => {},
  authStatus: AuthStatus.begin,
  setAuthStatus: () => {},
  login: () => Promise,
  registerFirebase: () => Promise,
  signOut: () => {},
  forgotPassword: () => Promise,
  resetPassword: () => Promise,
});

type TProps = {
  children: JSX.Element;
};

export function AuthenticationProvider({ children }: TProps) {
  const [currentUser, setCurrentUser] = useState<User | null | "pending">(
    AuthenticationService.currentUser() || PENDING
  );
  const loc = useLocation();
  const isRegisterScreen = loc.pathname.toLowerCase().includes("register");

  const [errors, setErrors] = useState<IErrors>({});
  const client = useApolloClient();

  const [authStatus, setAuthStatus] = useState<AuthStatus>(AuthStatus.begin);
  const { loading, error } = useMeQuery({
    fetchPolicy: "network-only",
    skip: !authStatus || authStatus !== AuthStatus.needMeData,
    onCompleted: (meData) => {
      // TODO: deal with bad user data - eg, no company, multiple companies, etc
      if (!meData?.me?.user) {
        throw new Error("Signin Error - please contact Workbox");
      } else if (meData?.me?.user?.staffs.length === 0) {
        throw new Error("Signin Error - please contact Workbox");
      } else if (meData?.me?.user?.staffs.length > 1) {
        throw new Error("Signin Error - please contact Workbox");
      } else {
        const userId = meData?.me?.user.id;
        const companyId = meData?.me?.user?.staffs[0].company.id;
        const staffId = meData?.me?.user?.staffs[0].id;
        const primaryCompanyId = meData?.me?.user?.primaryCompanyId;
        const locale = meData.me.user.staffs[0].company.country.locale;
        const activeServiceTypes = meData?.me?.user?.activeServiceTypes;
        if (companyId && staffId) {
          // for now, only allow 1 company per user
          if (companyId !== primaryCompanyId) {
            throw new Error("Received invalid companyId for user");
          }
          if (
            meData?.me?.user?.themePrimaryColor &&
            meData?.me?.user?.themeSecondaryColor
          ) {
            const primaryColor = meData?.me?.user?.themePrimaryColor;
            const secondaryColor = meData?.me?.user?.themeSecondaryColor;

            // if we've got special saved colors, then save it now
            // so future login sessions start with the right colors
            if (primaryColor) {
              localStorage.setItem("primaryColor", primaryColor);
              localStorage.setItem("secondaryColor", secondaryColor);
            }
          }
          if (localCache().companyId !== companyId) {
            localCache().companyId = companyId;
            localCache().staffId = staffId.toString();
            localCache().userId = userId;
            localCache().locale = locale;
          }
          localCache().activeServiceTypes = new Set(activeServiceTypes);
          setAuthStatus(AuthStatus.needStartup);
        } else {
          throw new Error("Signin Error - please contact Workbox 2");
        }
      }
    },
  });

  useEffect(() => {
    // if going to register screen, then log off any existing user.
    if (isRegisterScreen) {
      return;
    }

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user ? user : null);
      if (!user) {
        client.clearStore().then(() => {
          setAuthStatus(AuthStatus.begin);
          sessionStorage.clear();
        });
      } else {
        setAuthStatus(AuthStatus.needMeData);
        Sentry.setUser({ email: user.email || undefined });
      }
    });

    return unsubscribe;
  }, [client,isRegisterScreen]);

  // skip if no company has been set or authentication is done
  const skip = !localCache().companyId || authStatus !== AuthStatus.needStartup;
  const { loading: startupLoading, error: startupError } = useStartupQuery({
    variables: {
      companyId: localCache().companyId,
    },
    skip,
    onCompleted: () => {
      setAuthStatus(AuthStatus.done);
    },
  });

  if (error) {
    return <GraphQLErrorScreen error={error} signOut={true} />;
  }

  if (startupError) {
    return <GraphQLErrorScreen error={startupError} signOut={true} />;
  }

  if (loading || startupLoading) {
    return <LoadingScreen loading={true} />;
  }

  function login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function register(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function forgotPassword(email: string) {
    const redirect_uri = window.location.hostname === 'localhost' ?  `http://localhost:3000/sign-in` : 'https://workboxapp.com/sign-in'
    return sendPasswordResetEmail(auth, email, {
      url: redirect_uri,
    });
  }

  function resetPassword(oobCode: string, newPassword: string) {
    return confirmPasswordReset(auth, oobCode, newPassword);
  }

  function signOut() {
    return AuthenticationService.signOut();
  }

  return (
    <AuthenticationContext.Provider
      value={{
        authStatus,
        setAuthStatus,
        currentUser,
        setCurrentUser,
        errors,
        setErrors,
        login,
        registerFirebase: register,
        signOut,
        forgotPassword,
        resetPassword,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
