import { Countries, Languages } from '../utils'
import {BillingDateRangeFilterEnum} from "./billingDateRangeFIlter";
import React from "react";

const countryCodeKeys = Object.keys(Countries) as  Array<keyof typeof Countries>;
export type ICountryCode = (typeof countryCodeKeys)[number];

export interface IAddress {
  street1: string
  street2: string
  city: string
  state: string
  zip: string
  country: ICountryCode
}

const languagesCodeKeys = Object.keys(Languages) as  Array<keyof typeof Languages>
export type ILanguageCode = (typeof languagesCodeKeys)[number]

export enum RecordSavedSourceEnum {
  'service',
  'session',
  'client',
  'invoice',
  'payment',
  'importAssignment'
}

export interface IClientService {
  description: string;
  id: string
  serviceId:string
  sessionFee: number
}
export interface IRecordSavedProps {
  source: RecordSavedSourceEnum;
  id: string;
  optionsDescription: string;
  snackbarDescription: string;
  clientId?: string;
  element?: number;
  clientServices?: Array<IClientService>
}


export enum SaveRetErrorType  {
  Application,
  Network
}

export interface IUpdateRet {
  success: boolean;
  errorMessage?: string;
  errorType?: SaveRetErrorType
  id?:string
}

export enum AuthStatus {
  begin,
  registrationInProgress,
  registrationDone,
  needMeData,
  needSignIn,
  needStartup,
  selectCompany,
  missingCompanyAssignment,
  done,
  signOut,
  networkFailure
}

export interface IBillingDates {
  billingDateBase: string;
  billingDateFilter: BillingDateRangeFilterEnum,
  billingCustomStartDate?: string;
  billingCustomEndDate?: string;
}

export interface IAlert {
  show: boolean;
  description?: string;
}

export interface IChildren {
  children: React.ReactNode
}
