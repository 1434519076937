import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IAlertProps } from "./index";

interface IProps {
  alertProps: IAlertProps;
  setAlertProps(alertProps: IAlertProps): void;
  title?:string;
  callBackAfterClose?:(arg:any) => {}
}

export function AlertDialog(props: IProps) {
  const { open, message, title = "", callBackAfterClose } = props.alertProps;
  const setAlertProps = props.setAlertProps;

  const handleClose = () => {
    setAlertProps({ ...props.alertProps, open: false });
    callBackAfterClose && callBackAfterClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>{message}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
