import { Form, Formik, FormikErrors, FormikHelpers, FormikProps, FormikValues } from "formik";
import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Paper, Typography } from "@mui/material";
import { ISignInFormProps } from "./Interface";
import { makeStyles } from "@mui/styles";
import { TextInput } from "components/TextInput";
import { useNavigate } from "react-router-dom";
import logo from "images/workbox-logo.png";

const useStyles = makeStyles(() => ({
  panel: {
    // height: 300,
    maxWidth: 350,
    padding: 20,
    // margin: "0 20px",
    position: "relative",
    width: "80vw",
  },
  fieldWrapper: {
    marginTop: 7,
    "& > div": {
      marginBottom: "16px",
      "&:last-child": {
        marginBottom: 1,
      },
    },
  },
  submit: {
    margin: "25px auto",
  },

  submit2: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    bottom: 20,
    left: "50%",
    "-webkit-transform": "translateX(-50%)",
  },
}));

interface IFormValues {
  email: string;
  password: string;
  staySignedIn: boolean;
}

const validate = (values: FormikValues) => {
  const errors: FormikErrors<IFormValues> = {};

  if (!values.email) {
    errors.email = "Please enter Email";
  }
  if (!values.password) {
    errors.password = "Please enter Password";
  }
  return errors;
};

export function SignIn(props: ISignInFormProps) {
  const { email, password, onSubmit, staySignedIn = false } = props;
  const classes = useStyles();
  const nav = useNavigate();
  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    formikHelpers.setSubmitting(true);
    const ret = await onSubmit(values);
    if (!ret) {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Box>
      <Box
        component="nav"
        sx={{
          alignItems: "center",
          paddingTop:"30px",
          justifyContent: "center",
          display: "flex",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,

        }}
      >
        <Box sx={{      "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
          }}}>
        <img
          alt="Workbox Logo"
          style={{ justifySelf: "center", width: "300px" }}
          src={logo}
          onClick={() => nav("/home", {replace:true})}
        />
        </Box>
      </Box>
      <Formik
        initialValues={{ email, password, staySignedIn }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {(formikProps: FormikProps<IFormValues>) => {
          const { isSubmitting, setFieldValue, values } = formikProps;
          return (
            <Container maxWidth="md" sx={{ mt: 2 }} disableGutters={true}>
              <Box textAlign="center" mb={3}>
                <Box typography="h4">Sign in to Workbox</Box>
              </Box>
              <Form noValidate={true}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Paper elevation={8} className={classes.panel}>
                    <div className={classes.fieldWrapper}>
                      <TextInput type="email" name="email" required />
                      <TextInput
                        type="password"
                        name="password"
                        required
                        id="current-password"
                        autoComplete="current-password"
                      />
                    </div>
                    <FormGroup sx={{ mt: "4px", color: "rgb(96,96,96)" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.staySignedIn}
                            onChange={() => {
                              setFieldValue(
                                "staySignedIn",
                                !values.staySignedIn
                              );
                            }}
                          />
                        }
                        label="Stay signed in?"
                      />
                    </FormGroup>

                    <Box
                      sx={{ display: "flex", mt: 6, justifyContent: "center" }}
                    >
                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Sign In
                      </Button>
                    </Box>
                  </Paper>
                  <Box
                    sx={{ display: "flex", mt: 6, justifyContent: "center" }}
                  >
                    <Typography
                      variant="body2"
                      onClick={() => {
                        nav("/forgot-password");
                      }}
                      sx={{
                        m: 1,
                        color: "rgba(10,10,10,.5)",
                        fontSize: ".82rem",
                        fontWeight: 500,
                        "&:hover": {
                          textDecoration: "underline",
                          cursor: "pointer",
                        },
                      }}
                    >
                      Forgot Password?
                    </Typography>
                    <Typography
                      variant="body2"
                      onClick={() => nav("/terms")}
                      sx={{
                        m: 1,
                        color: "rgba(10,10,10,.5)",
                        fontSize: ".82rem",
                        fontWeight: 500,
                        "&:hover": {
                          textDecoration: "underline",
                          cursor: "pointer",
                        },
                      }}
                    >
                      Terms of Service
                    </Typography>
                    <Typography
                      variant="body2"
                      onClick={() => nav("/privacy")}
                      sx={{
                        m: 1,
                        color: "rgba(10,10,10,.5)",
                        fontSize: ".82rem",
                        fontWeight: 500,
                        "&:hover": {
                          textDecoration: "underline",
                          cursor: "pointer",
                        },
                      }}
                    >
                      Privacy Policy
                    </Typography>
                  </Box>
                </Box>
              </Form>
            </Container>
          );
        }}
      </Formik>
    </Box>
  );
}
