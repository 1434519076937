import React, {useContext, useEffect} from "react";
import {ISignInFormProps} from "./Interface";
import {SignIn} from "./SignIn";
import {AuthenticationContext} from "context/AuthenticationProvider";
import {makeStyles} from "@mui/styles";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AuthStatus} from "types";
import {auth} from "services/Core";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {AlertDialog, IAlertProps} from "../../components/Alert";
import {localCache} from "graphqlDocuments";

//GoVienna!
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export function SignInContainer() {
  const classes = useStyles();
  const nav = useNavigate();

  const [alertArgs, setAlertArgs] = React.useState<IAlertProps>({
    open: false,
    message: "",
    title: "",
  });
  const [searchParams] = useSearchParams();
  const nextRoute = searchParams.get("next");
  const { authStatus, currentUser } = useContext(AuthenticationContext);

  useEffect(() => {
    if (authStatus === AuthStatus.done) {
      nav(nextRoute || "/clients", { replace: true });
    }
  }, [authStatus, nav, nextRoute, currentUser]);

  async function handleSubmit({
                                email,
                                password,
                                staySignedIn,
                              }: ISignInFormProps) {
    if (!staySignedIn) {
      await setPersistence(auth, browserSessionPersistence);
    } else {
      await setPersistence(auth, browserLocalPersistence);
    }

    localCache().staySignedIn = staySignedIn || false;

    signInWithEmailAndPassword(auth, email, password)
      .then((currentUser) => {
        const user = currentUser.user;
        if (!user || !user.uid) {
          throw new Error("Invalid User Credentials.  Call support");
        }
        // Sentry.setUser({ email:email || undefined });
      })
      .catch((e) => {
        debugger
        console.log(e);
        if (e.code === "auth/network-request-failed") {
          setAlertArgs({
            open: true,
            title: "Correction required",
            message: "Network error - cannot authorize login",
          });
        } else {
          setAlertArgs({
            open: true,
            title: "Correction required",
            message: "Incorrect email or password",
          });
        }
        return false;
      });
  }

  return (
    <div className={classes.root}>
      <AlertDialog alertProps={alertArgs} setAlertProps={setAlertArgs} />
      <SignIn email="" password="" onSubmit={handleSubmit} />
    </div>
  );
}
