import {IFormValues} from "./index";
import React from "react";
import {AlertDialog, IAlertProps} from "components/Alert";
import {Form, Formik, FormikErrors, FormikHelpers, FormikValues,} from "formik";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from "@mui/material";
import {EntryContainer} from "components/Containers";
import {SubmitButton} from "components/Buttons";
import {IUpdateRet} from "../../../types";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import { useTopOfForm } from "../../../hooks";

interface IProps {
  executeRequest: (values: IFormValues, file?: File) => Promise<IUpdateRet>;
}

export const InvoiceReportRequest: React.FC<IProps> = ({ executeRequest }) => {
  const [alertArgs, setAlertArgs] = React.useState<IAlertProps>({
    open: false,
    message: "",
    title: "",
  });

  const nav = useNavigate();

  // const classes = useStyles();

  useTopOfForm()

  const thisMonth = dayjs().format("MMM YYYY")
  const lastMonth = dayjs().subtract(1, "month").format("MMM YYYY")
  const thisYear = dayjs().format("YYYY")
  const lastYear = dayjs().subtract(1, "year").format("YYYY")

  const MenuItems = [
    <MenuItem key={1} value="all">
      All Dates
    </MenuItem>,
    <MenuItem key={2} value="thisMonth">
      {`This month (${thisMonth})`}
    </MenuItem>,
    <MenuItem key={3} value="lastMonth">
      {`Last month (${lastMonth})`}
    </MenuItem>,
    <MenuItem key={4} value="thisYear">
      {`This year (${thisYear})`}
    </MenuItem>,
    <MenuItem key={5} value="lastYear">
      { `Last year (${lastYear})` }
    </MenuItem>,
  ];

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<IFormValues> = {};

    if (values.dateChoice === "custom") {
      if (!values.dateRange[0] || !values.dateRange[1]) {
        errors.dateRange = "Select dates";
      }
    }

    return errors;
  };

  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    const ret = await executeRequest(values);
    formikHelpers.setSubmitting(false);
    if (ret.success) {
      if (values.reportFormat === "PDF") {
        nav("pdf-viewer");
      } else {
        setAlertArgs({
          open: true,
          title: "Status",
          message: "CSV Download complete",
        });

      }
    } else {
      setAlertArgs({
        open: true,
        title: "Report Unsuccessful",
        message: ret.errorMessage,
      });
    }
  };

  const initialValues: IFormValues = {
    dateChoice: "all",
    dateRange: [null, null],
    reportFormat: "PDF",
    reportDisposition: "PRINT",
  };

  return (
    <Box mt="15px">
      <AlertDialog alertProps={alertArgs} setAlertProps={setAlertArgs} />
      <Formik
        enableReinitialize={true}
        validate={validate}
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          handleSubmit(values, formikHelpers);
        }}
      >
        {(formikHelpers) => {
          const {
            values,
            isSubmitting,
            setFieldValue,
          } = formikHelpers;
          return (
            <Form noValidate autoComplete="off" >
              {/*<AlertDialog*/}
              {/*  alertProps={alertArgs}*/}
              {/*  setAlertProps={setAlertArgs}*/}
              {/*/>*/}
              <EntryContainer title="Invoices Report">
                <Card raised={true}>
                  <CardContent>
                    <Stack spacing="16px">
                      <FormControl variant="filled" fullWidth>
                        <InputLabel id="date-choice-label">Dates</InputLabel>
                        <Select
                          labelId="date-choice-label"
                          id="date-select"
                          value={values.dateChoice}
                          label="Date Range"
                          onChange={(event) => {
                            setFieldValue("dateChoice", event.target.value);
                          }}
                        >
                          {MenuItems}
                        </Select>
                      </FormControl>
                      <FormGroup
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        {/*<Box mr="10px">Report format</Box>*/}
                        <RadioGroup
                          onChange={(event) => {
                            const value = (event.target as HTMLInputElement)
                              .value;
                            setFieldValue("reportFormat", value, true);
                          }}
                          value={values.reportFormat}
                          row
                          aria-label="report-format"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="PDF"
                            control={<Radio />}
                            label="PDF"
                          />
                          <FormControlLabel
                            value="CSV"
                            control={<Radio />}
                            label="CSV"
                          />
                        </RadioGroup>
                      </FormGroup>
                      {/*<FormGroup sx={{ display:"flex", alignItems:"center", flexDirection:"row" }}>*/}
                      {/*  /!*<Box mr="10px">Report format</Box>*!/*/}
                      {/*  <RadioGroup*/}
                      {/*    onChange={(event) => {*/}
                      {/*      const value = (event.target as HTMLInputElement).value ;*/}
                      {/*      setFieldValue(*/}
                      {/*        "reportDisposition",*/}
                      {/*        value,*/}
                      {/*        true*/}
                      {/*      );*/}
                      {/*    }}*/}
                      {/*    value={values.reportDisposition}*/}
                      {/*    row*/}
                      {/*    aria-label="report-disposition"*/}
                      {/*    name="row-radio-buttons-group"*/}
                      {/*  >*/}
                      {/*    <FormControlLabel value="PRINT" control={<Radio />} label="View" />*/}
                      {/*    <FormControlLabel value="DOWNLOAD" control={<Radio />} label="Download" />*/}
                      {/*  </RadioGroup>*/}
                      {/*</FormGroup>*/}
                    </Stack>
                  </CardContent>
                </Card>
                <SubmitButton disabled={isSubmitting} label="Submit" />
              </EntryContainer>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
