import { InvoiceDateFormatEnum, StripeStatusEnum, useCompanyQuery } from "../generated/graphql";
import { localCache } from "../graphqlDocuments";

interface IReturn {
  companyId: string;
  companyName: string;
  languageIsoCode: string;
  currencyIsoCode: string;
  countryIsoCode: string;
  countryId: string;
  locale: string;
  firstImportDate: string | undefined | null;
  importFutureDays: number;
  importIncludesWeekends: boolean;
  trialEndDate: string;
  stripeStatusEnum: StripeStatusEnum;
  invoiceBodyDateFormat: InvoiceDateFormatEnum;
  invoiceEmailDateFormat: InvoiceDateFormatEnum;
  invoiceEmailBodyText: string;
  allowMultipleImportSelections:boolean;
  paymentReceiptSubject:string;
  paymentReceiptBodyText:string;
  hidePaymentReceipt:boolean
}

interface IProps {
  bypassError?: boolean;
}
export const useCompanyGetter = (props?: IProps): IReturn => {
  const bypassError = props?.bypassError;

  try {
    const { data, error } = useCompanyQuery({
      variables: { companyId: localCache().companyId },
      skip: !localCache().companyId,
      fetchPolicy: "cache-only",
    });

    if (error) {
      throw new Error(error.message);
    }

    if (!data && (bypassError||!localCache().companyId)) {
      return {
        companyId:"",
        countryId:"",
        companyName: "",
        languageIsoCode: "",
        currencyIsoCode: "",
        countryIsoCode: "",
        locale: "",
        firstImportDate: undefined,
        importFutureDays: 0,
        importIncludesWeekends: false,
        trialEndDate: "2100-01-01",
        stripeStatusEnum: StripeStatusEnum.Trialing,
        invoiceBodyDateFormat: InvoiceDateFormatEnum.InvoiceMonth,
        invoiceEmailDateFormat: InvoiceDateFormatEnum.MonthOfService,
        invoiceEmailBodyText: "",
        allowMultipleImportSelections: false,
        paymentReceiptSubject:"",
        paymentReceiptBodyText: "",
        hidePaymentReceipt:false
      };
    }

    if (!data?.company) {
      debugger
      throw new Error("no company returned");
    }

    const companyName = data?.company.name;
    const languageIsoCode = data?.company.language.isoCode;
    const currencyIsoCode = data?.company.currency.isoCode;
    const countryId = data?.company.country.id;
    const countryIsoCode = data?.company.country.isoCode;
    const firstImportDate = data.company.firstImportDate;
    const importFutureDays = data.company.importFutureDays;
    const importIncludesWeekends = data.company.importIncludesWeekends;
    const stripeStatusEnum = data.company.stripeStatusEnum;
    const trialEndDate = data.company.trialEndDate || "1900-01-01"
    const invoiceBodyDateFormat = data.company.invoiceBodyDateFormat;
    const invoiceEmailDateFormat = data.company.invoiceEmailDateFormat;
    const invoiceEmailBodyText = data.company.invoiceEmailBodyText || "";
    const allowMultipleImportSelections = data.company.allowMultipleImportSelections || false
    const paymentReceiptSubject = data.company.paymentReceiptSubject || ""
    const paymentReceiptBodyText = data.company.paymentReceiptBodyText || ""
    const hidePaymentReceipt = data.company.hidePaymentReceipt || false

    const locale =
      countryIsoCode && languageIsoCode
        ? languageIsoCode + "-" + countryIsoCode.toUpperCase()
        : "en-US";

    return {
      companyId: data?.company.id,
      countryId,
      companyName,
      languageIsoCode,
      currencyIsoCode,
      countryIsoCode,
      locale,
      firstImportDate,
      importFutureDays,
      importIncludesWeekends,
      stripeStatusEnum,
      trialEndDate,
      invoiceBodyDateFormat,
      invoiceEmailDateFormat,
      invoiceEmailBodyText,
      allowMultipleImportSelections,
      paymentReceiptSubject,
      paymentReceiptBodyText,
      hidePaymentReceipt
    };
  } catch (err: any) {
    throw new Error(err.message);
  }
};

