import { IMuiSelect } from 'types/select'
import {ClientType} from "../../generated/graphql"
import {localCache} from "../../graphqlDocuments";
import {toFloat} from "../../utils";

interface ServiceFee {
  service: IMuiSelect | null;
  sessionFee: string
}

export interface IFormValues {
  clientId?: string
  clientType: ClientType
  contactName?: string
  email?: string
  companyName?: string
  firstName?: string
  lastName?: string
  firstName2?: string
  lastName2?: string
  email2?: string
  street1?: string
  street2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
  attention?: string
  invoiceHeaderText?: string
  serviceFees: Array<ServiceFee>
  abbrevName?: string
  openingBalance: string
  salutation?:string
  otherInfo?: string
  sendPaymentReceipt: boolean
}

const getServiceFees = (values:IFormValues) => {
  if (!values.serviceFees) {
    return null;
  }
  const serviceFees = values.serviceFees.filter(serviceFee => {
    return Boolean(serviceFee.sessionFee) && Boolean(serviceFee.service?.id)
  }).map(serviceFee => {
    // should never happen due to filter above
    if (!serviceFee.service?.id) {
      throw new Error("Invalid service id")
    }
    return {
      serviceId: serviceFee.service?.id,
      sessionFee: toFloat(serviceFee.sessionFee),
    }
  })
  return serviceFees;
}

export const mapFormValuesToVariables = (values:IFormValues) => {
  const serviceFees = getServiceFees(values)
  if (!values.firstName || !values.lastName) {
    throw new Error("First or last client name blank")
  }

  const fields = {
    companyId: localCache().companyId,
    clientType: values.clientType,
    firstName: values.firstName,
    lastName: values.lastName,
    name: values.companyName,
    email: values.email,
    street1: values.street1,
    street2: values.street2,
    city: values.city,
    state: values.state,
    zip: values.zip,
    attention: values.attention,
    invoiceHeaderText: values.invoiceHeaderText,
    country: values.country,
    abbrevName: values.abbrevName,
    colorNumber: 0,
    firstName2: values.firstName2,
    lastName2: values.lastName2,
    email2: values.email2,
    companyName: values.companyName,
    salutation: values.salutation,
    otherInfo: values.otherInfo,
    sendPaymentReceipt: values.sendPaymentReceipt,
    serviceFees
  };
  return fields;
}

// export type ActionType = ClientActionType
//
// export type ClientActionType  =
//   | { type: 'SHOW_CLIENT_ENTRY' }
//   | { type: 'CLIENT_ADDED', payload: {
//     client: CreateClientMutation,
//   }}
//   | { type: 'SET_INITIAL_VALUES'; payload: {values: IFormValues}}

export const initialValues: IFormValues = {
  clientType: ClientType.Individual,
  firstName: "",
  lastName: "",
  email: "",
  companyName: "",
  contactName: "",
  serviceFees: [{service:null, sessionFee:""}],
  street1: "",
  street2: "",
  city: "",
  state: "",
  zip: "",
  country:"",
  attention: "",
  abbrevName: "",
  openingBalance: '',
  firstName2: '',
  lastName2: '',
  email2: '',
  salutation:'',
  otherInfo:'',
  sendPaymentReceipt: false
};

export interface IScreenState {
  initialValues: IFormValues
  selectedService: IMuiSelect|null
  options: Array<IMuiSelect>
  nav(name: string|number, options?:object): void
}

export type ActionType =
  | {
  type: "SERVICE_ELEMENT_SELECTED";
  payload: {
    element: number;
  };
}

export interface IReducerState {
  serviceElement: number
}
export const initialState: IReducerState = {
  serviceElement: 0,
}

