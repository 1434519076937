import { IMuiSelect } from "types";
import {Status, useServicesQuery} from "../../generated/graphql";
import { localCache } from "../../graphqlDocuments";
import { orderBy } from "lodash";
import { SelectItem, ISelect } from "../Select";
import {utils} from "../../utils";
import {useField} from "formik";

export function SelectService(props:ISelect) {

  const {hideAddNewButton = false} = props;

  // const [services, setServices] = React.useState<Array<IMuiSelect>>([]);
  const { data } = useServicesQuery({
    variables: {companyId: localCache().companyId},
    fetchPolicy: "cache-only"
  })

  const services = data?.services.filter(item => item.status === Status.Active)
  const options: Array<IMuiSelect> = orderBy(services, ["description", "cptCode"]).map(
    (service) => {
      return {
        id: service.id,
        description: utils.formatService(service.description, service.cptCode||undefined)
      };
    }
  );

  const name = props.name ? props.name : 'service';

  const [, meta] = useField(name);

  return (
    <SelectItem
      onItemSelected={props.onItemSelected}
      name={name}
      label={meta.value ? "Service Provided" : "Select Service Provided"}
      options={options}
      mobileSearchLabel="Search Services"
      addNewItemLabel="Service"
      onShowAddScreen={props.onShowAddScreen}
      disableClearable={props.disableClearable}
      helperText={!meta.value ? "e.g. Individual psychotherapy, 45 minutes" : undefined}
      hideAddNewButton={hideAddNewButton}
    />
  );
}
