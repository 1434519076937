
export const getKeywords = (text: string) => {
  var reBrackets = /\[\[(.*?)\]\]/g;
  var keywords = [];
  var found;
  while ((found = reBrackets.exec(text))) {
    keywords.push(found[1]);
  }
  return keywords;
};

export const validateKeywords = ({
                                   text,
                                   validKeywordList,
                                 }: {
  text: string;
  validKeywordList: Array<string>;
}) => {
  const keywords = getKeywords(text);

  const errors = keywords.filter((keyword) => {
    return !validKeywordList.includes(keyword.toLowerCase());
  });

  return errors;
};

