import { IDimensions, IFormValues } from "./index";
import React, { SetStateAction, useEffect } from "react";
import { findIndex, isEmpty, isEqual } from "lodash";
import { AlertDialog, IAlertProps } from "components/Alert";
import { useDropzone } from "react-dropzone";
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikValues,
  useFormikContext,
} from "formik";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  Typography,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingScreen } from "../LoadingScreen";
import { TextInput } from "../TextInput";
import { EntryContainer } from "../Containers";
import { IUpdateRet, SaveRetErrorType } from "../../types";
import { CountriesQuery } from "../../generated/graphql";
import { SubmitButton } from "../Buttons";
import { CenteredText, FieldWrapper, Root } from "../StyledComponents";
import { styled } from "@mui/styles";
import { useSearchParams } from "react-router-dom";
import { ILocState } from "../Invoice";
import { useConfirmDialog, ConfirmDialog } from "components/Dialogs";
import { useTopOfForm } from "../../hooks";
import { validateKeywords } from "utils";

const Img = styled("img")({
  maxHeight: "200px",
  maxWidth: "200px",
});

const StyledDropzone = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "5px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  margin: "0 auto 30px auto",
  height: 90,
  width: 200,
  transition: "border .24s ease-in-out",
  "&:hover": {
    cursor: "pointer",
  },
});

interface IProps {
  formValues: IFormValues;
  loading: boolean;
  saveData: (
    values: IFormValues,
    saveLogo: boolean,
    file?: File
  ) => Promise<IUpdateRet>;
  countries?: CountriesQuery;
  logo?: string;
  dimensions?: IDimensions;
  newTab?: string | null;
  locState?: ILocState;
  setNewTab?: React.Dispatch<SetStateAction<string | null>>;
}

interface IImageInfo {
  imageNeedSave: boolean;
  url?: string;
  file?: File;
  dimensions?: IDimensions;
  logo?: string;
}

export const CompanyEntry: React.FC<IProps> = ({
  formValues,
  saveData,
  loading,
  logo,
  dimensions,
  countries,
  newTab,
  locState,
  setNewTab,
}) => {
  const [, setSearchParams] = useSearchParams({});
  const [saveCompanyState, saveCompanyDispatch] = useConfirmDialog();
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const dataNeedSave = React.useRef<boolean>(false);
  const [showMore, toggleShowMore] = React.useState<boolean>(false);
  // const [showMorePaymentReceipt, toggleMorePaymentReceipt] = React.useState<
  //   boolean
  // >(
  //   Boolean(formValues.paymentReceiptBodyText) ||
  //     Boolean(formValues.paymentReceiptSubject)
  // );
  const [alertArgs, setAlertArgs] = React.useState<IAlertProps>({
    open: false,
    message: "",
    title: "",
  });

  const firstTime = React.useRef(true);

  const [imageInfo, setImageInfo] = React.useState<IImageInfo>({
    imageNeedSave: false,
  });

  useTopOfForm();

  const getHeightAndWidthFromDataUrl = (dataURL: string) =>
    new Promise<IDimensions>((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });

  const {
    // acceptedFiles,
    // fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    // isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 1,
    multiple: false,
    maxSize: 1000000,
    onDropRejected: () => {
      // debugger
      setAlertArgs({
        open: true,
        title: "File Error",
        message: "Only Jpeg and Png images.  1 MB limit",
      });
    },
    onDrop: async (acceptedFiles: any) => {
      if (acceptedFiles.length === 1) {
        const url: string = URL.createObjectURL(acceptedFiles[0]);
        const dimensions = await getHeightAndWidthFromDataUrl(url);
        setImageInfo({
          imageNeedSave: true,
          file: acceptedFiles[0],
          logo: url,
          url,
          dimensions,
        });
      }
    },
  });

  useEffect(() => {
    if (!loading && firstTime.current) {
      toggleShowMore(
        Boolean(formValues.invoiceEmailSubjectText) ||
          Boolean(formValues.invoiceEmailBodyText)
      );
      firstTime.current = false;
      setImageInfo({
        imageNeedSave: false,
        url: formValues.storageLogoURL,
        logo,
        dimensions,
      });
    }
  }, [
    loading,
    formValues.storageLogoURL,
    logo,
    dimensions,
    formValues.invoiceEmailBodyText,
    formValues.invoiceEmailSubjectText,
  ]);

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<IFormValues> = {};

    if (!values.name) {
      errors.name = "Enter Company Name";
    }

    return errors;
  };

  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    formikHelpers.setSubmitting(true);

    const file =
      imageInfo && imageInfo.imageNeedSave ? imageInfo.file : undefined;
    const ret = await saveData(values, imageInfo.imageNeedSave, file);
    formikHelpers.setSubmitting(false);
    if (!ret.success) {
      if (ret.errorType === SaveRetErrorType.Network) {
        setAlertArgs({
          open: true,
          title: "Network Error",
          message: "Please re-save after internet connection restored",
        });
      } else {
        setAlertArgs({
          open: true,
          title: "Errors Found - please correct",
          message: ret.errorMessage,
        });
      }
    }

    setShowSnackbar(true);

    return ret;
  };

  const errorComponent = (ret: object) => {
    return (
      <ul style={{}}>
        {Object.entries(ret).map(([, value], idx) => (
          <li key={idx}>{value}</li>
        ))}
      </ul>
    );
  };

  const PaymentInstructions = () => {
    return (
      <FieldWrapper>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">
            Invoice Payment Instructions
          </Typography>
          <Typography variant="subtitle2">
            Optional. Examples include payment terms, Venmo or Zelle
            instructions, a special remit address where clients should mail
            payments, etc.
          </Typography>
        </Box>
        <TextInput name="remit1" label="Line 1" />
        <TextInput name="remit2" label="Line 2" />
        <TextInput name="remit3" label="Line 3" />
        <TextInput name="remit4" label="Line 4" />
        <TextInput name="remit5" label="Line 5" />
      </FieldWrapper>
    );
  };

  const InvoiceLabel = () => {
    const formikProps: FormikProps<IFormValues> = useFormikContext();
    const { setFieldValue, values } = formikProps;
    return (
      <FieldWrapper>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1">Invoice Formatting</Typography>
        </Box>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="invoice-body-date-select-label">
            Date To display on invoice
          </InputLabel>
          <Select
            labelId="invoice-body-date-select-label"
            id="invoice-body-date-select"
            value={values.invoiceBodyDateFormat}
            label="Date "
            onChange={(event) => {
              const invoiceBodyDateFormat = event.target.value;
              setFieldValue("invoiceBodyDateFormat", invoiceBodyDateFormat);
            }}
          >
            <MenuItem value={"InvoiceDate"}>
              Full Invoice Date (May 1, 2022)
            </MenuItem>
            <MenuItem value={"InvoiceMonth"}>
              Month of Invoice Date (May 2022)
            </MenuItem>
            <MenuItem value={"MonthOfService"}>
              Month of Service (see below)
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="invoice-email-date-select-label">
            Date To display on email Subject & Body
          </InputLabel>
          <Select
            labelId="invoice-email-date-select-label"
            id="invoice-email-date-select"
            value={values.invoiceEmailDateFormat}
            label="Date "
            onChange={(event) => {
              const invoiceEmailDateFormat = event.target.value;
              setFieldValue("invoiceEmailDateFormat", invoiceEmailDateFormat);
            }}
          >
            <MenuItem value={"InvoiceDate"}>
              Full Invoice Date (May 1, 2022)
            </MenuItem>
            <MenuItem value={"InvoiceMonth"}>
              Month of Invoice Date (May 2022)
            </MenuItem>
            <MenuItem value={"MonthOfService"}>
              Month of Service (see below)
            </MenuItem>
          </Select>
        </FormControl>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          The Month of Service option prints the month of the sessions being
          billed rather than using the invoice date. eg. May 2022
        </Typography>
      </FieldWrapper>
    );
  };

  const InvoiceFormatting = () => {
    const formikProps: FormikProps<IFormValues> = useFormikContext();
    const { setFieldValue, values } = formikProps;
    return (
      <>
        <FieldWrapper>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Customize Invoice Email</Typography>
            <Typography variant="subtitle2" sx={{ mb: "5px" }}>
              The following options let you customize the email Subject and
              Body. See example below
            </Typography>
            <Typography variant="subtitle2">
              Keywords will be replaced with the actual data from the invoice.
              Important: Enclose keywords in DOUBLE brackets. For example,
              [[invdate]].
            </Typography>
          </Box>
          <TextInput
            name="invoiceEmailSubjectText"
            label="Invoice Email Subject"
            helperText="Allowed Keywords: invdate, invmonth, mos"
          />
          <TextInput
            multiline={true}
            rows={6}
            name="invoiceEmailBodyText"
            label="Invoice Email Text"
            helperText="Allowed Keywords: salutation, invdate, invmonth, mos"
          />
        </FieldWrapper>
        <Typography mt="8px" variant="subtitle1">
          Example:
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: "pre-line", border:"2px grey solid", padding:"3px" }}>
          {`Dear [[salutation]],
            
          Please find enclosed your [[mos]] statement.

          Sincerely,

          John Smith, PhD`}
        </Typography>
        <Typography mt="10px" variant="subtitle1">
          Keywords:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="salutation"
              secondary="Client's Salutation (if entered), otherwise uses client's first name"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="invdate"
              secondary="Invoice Date - e.g., September 1, 2022"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="invmonth"
              secondary="Month and Year of Invoice Date - e.g., September 2022"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="mos"
              secondary="Month of Service.  Prints the date of the sessions (as opposed to the invoice date.)  For example, August 2022"
            />
          </ListItem>
          {/*<ListItem>*/}
          {/*  <ListItemText*/}
          {/*    primary="invamount"*/}
          {/*    secondary="Invoice Amount.  For example, $1,200.00"*/}
          {/*  />*/}
          {/*</ListItem>*/}
        </List>
        <Box sx={{ mb: 2, mt: 3 }}>
          <Typography variant="subtitle1">Other Options:</Typography>
        </Box>
        <FormGroup sx={{ mt: "16px" }}>
          <FormControlLabel
            data-testid="hideSummary"
            control={
              <Checkbox
                checked={values.hideInvoiceEmailSummarySection}
                onChange={() => {
                  setFieldValue(
                    "hideInvoiceEmailSummarySection",
                    !values.hideInvoiceEmailSummarySection
                  );
                }}
              />
            }
            label="Hide invoice recap on email?"
          />
          <FormHelperText sx={{ mb: "10px" }}>
            Hides the blue box on the Email body that displays your address and
            Amount Due. Note: This does not affect the attached PDF invoice.
          </FormHelperText>
          <TextInput
            name="invoiceLabel"
            label="Description (Prints on Invoice)"
          />
        </FormGroup>
      </>
    );
  };

  const PaymentReceiptFormatting = () => {
    // const formikProps: FormikProps<IFormValues> = useFormikContext();
    // const { setFieldValue, values } = formikProps;
    return (
      <>
        <FieldWrapper>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">
              Customize Payment Receipt Email
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: "5px" }}>
              The following options let you customize the email Subject and
              Body. If left blank, the app will use its default values. See
              example below
            </Typography>
            <Typography variant="subtitle2">
              Keywords will be replaced with the actual data from the invoice.
              Important: Enclose keywords in DOUBLE brackets. For example,
              [[invdate]].
            </Typography>
          </Box>
          <TextInput
            name="paymentReceiptSubject"
            label="Payment Receipt Subject"
            helperText="(No keywords allowed for Subject)"
          />
          <TextInput
            multiline={true}
            rows={6}
            name="paymentReceiptBodyText"
            label="Payment Receipt Email Text"
            helperText="Allowed Keywords: salutation, date, amount"
          />
        </FieldWrapper>
        <Typography mt="8px" variant="subtitle1">
          Example Payment Receipt Email Text:{" "}
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: "pre-line",border:"2px grey solid", padding:"3px"  }}>
          {`[[date]]
            
            Dear [[salutation]],
            
            Your [[amount]] payment has been received.  Thank you very much.
          .

          Sincerely,

          John Smith, PhD`}
        </Typography>
        <Typography mt="10px" variant="subtitle1">
          Keywords:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="salutation"
              secondary="Client's Salutation (if entered), otherwise uses client's first name"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="date"
              secondary="Payment Date - e.g., September 1, 2022"
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="amount" secondary="The payment amount" />
          </ListItem>
        </List>
      </>
    );
  };

  const InvoiceFooter = () => {
    return (
      <FieldWrapper>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">Invoice Footer</Typography>
          <Typography variant="subtitle2">
            Optional. Information to print on bottom of client invoices.
            Examples include email, phone, fax, license, tax Id.
          </Typography>
        </Box>
        <TextInput name="invoiceFooter1" label="Line 1" />
        <TextInput name="invoiceFooter2" label="Line 2" />
        <TextInput name="invoiceFooter3" label="Line 3" />
      </FieldWrapper>
    );
  };

  const AddressInfo = () => {
    // const formikProps: FormikProps<IFormValues> = useFormikContext();
    // const { values } = formikProps;

    return (
      <FieldWrapper>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">Address of practice</Typography>
          <Typography variant="subtitle2">
            Optional. If included, will print on client invoices
          </Typography>
        </Box>
        <TextInput name="address1" label="Address Line 1" />
        <TextInput name="address2" label="Address Line 2" />
        <TextInput name="city" />
        <TextInput name="state" label="State / Province" />
        <TextInput name="zip" label="Zip / Postal Code" />
        <TextInput
          name="addressFinal"
          label="Additional Address Info"
          helperText="Optional last line (e.g. country)"
        />
      </FieldWrapper>
    );
  };

  const menuCountryItems = countries?.countries.map((country) => {
    return (
      <MenuItem key={country.id} value={country.id}>
        {country.name}
      </MenuItem>
    );
  });

  const handleClose = () => {
    setShowSnackbar(false);
  };

  const ConfirmExit = () => {
    const formikProps: FormikProps<IFormValues> = useFormikContext();
    const { values, setSubmitting } = formikProps;

    useEffect(() => {
      const switchRoute = (tab: string) => {
        const newSearchParams = { tab };
        setSearchParams(newSearchParams, { replace: true, state: locState });
      };

      const handleSave = async (tab: string) => {
        setSubmitting(true);
        const ret = await handleSubmit(values, formikProps);
        if (ret.success) {
          switchRoute(tab);
        } else {
          setSubmitting(false);
        }
      };

      if (newTab) {
        const tab = newTab;
        setNewTab && setNewTab(null);
        if (dataNeedSave.current || imageInfo.imageNeedSave) {
          saveCompanyDispatch({
            type: "SET_DIALOG_OPEN",
            payload: {
              handleCancelPressed: () => switchRoute(tab),
              handleOkPressed: () => handleSave(tab),
            },
          });
        } else {
          switchRoute(tab);
        }
      }
    }, [formikProps, setSubmitting, values]);

    return (
      <ConfirmDialog
        state={saveCompanyState}
        dispatch={saveCompanyDispatch}
        title=""
        dialogText="You have unsaved Changes"
        cancelButtonLabel="Discard Changes"
        buttonLabel="Save Changes"
      />
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      validate={validate}
      initialValues={formValues}
      onSubmit={(values, formikHelpers) => {
        if (values.invoiceEmailSubjectText) {
          const errors = validateKeywords({
            text: values.invoiceEmailSubjectText,
            validKeywordList: ["mos", "invdate", "invmonth"],
          });
          if (errors.length > 0) {
            formikHelpers.setSubmitting(false);
            setAlertArgs({
              open: true,
              title: "Invoice Email Subject Error",
              message: `Keyword [[${errors[0]}]] is invalid`,
            });
            return;
          }
        }

        if (values.invoiceEmailBodyText) {
          const errors = validateKeywords({
            text: values.invoiceEmailBodyText,
            validKeywordList: ["mos", "invdate", "invmonth", "salutation"],
          });
          if (errors.length > 0) {
            formikHelpers.setSubmitting(false);
            setAlertArgs({
              open: true,
              title: "Invoice Email Text Error",
              message: `Keyword [[${errors[0]}]] is invalid`,
            });
            return;
          }
        }

        handleSubmit(values, formikHelpers);
        // formikHelpers.setSubmitting(false);
      }}
    >
      {(formikHelpers) => {
        const {
          values,
          isSubmitting,
          validateForm,
          submitForm,
          setFieldValue,
        } = formikHelpers;

        // see if any fields have changed
        dataNeedSave.current = !isEqual(formValues, values);

        const countryIndex = findIndex(countries?.countries, {
          id: values.countryId,
        });
        if (countryIndex < 0) {
          throw new Error(`Missing country ${values.countryId}`);
        }

        const ShowImage = ({
          // url,
          showButton = true,
        }: {
          // url: string;
          showButton?: boolean;
        }) => {
          return (
            <Box
              sx={{
                justifyContent: "center",
                marginBottom: "25px",
                marginTop: "75px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Img alt="logo" src={imageInfo!.logo} />
              <Button
                sx={{ display: showButton ? "block" : "none" }}
                variant="text"
                onClick={() =>
                  setImageInfo({
                    ...imageInfo,
                    imageNeedSave: true,
                    logo: undefined,
                    url: undefined,
                  })
                }
              >
                click to remove
              </Button>
            </Box>
          );
        };
        return (
          <Form
            noValidate
            autoComplete="off"
            onSubmit={async (event) => {
              event.preventDefault();
              const ret = await validateForm();
              if (!isEmpty(ret)) {
                setAlertArgs({
                  open: true,
                  title: "Errors found - please correct",
                  message: errorComponent(ret),
                });
              }
              submitForm();
            }}
          >
            <LoadingScreen loading={loading} />
            <ConfirmExit />
            <AlertDialog alertProps={alertArgs} setAlertProps={setAlertArgs} />
            <Snackbar
              sx={{ width: "80vw", maxWidth: "450px" }}
              open={showSnackbar}
              autoHideDuration={1000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                severity="success"
                variant="filled"
                onClose={handleClose}
                sx={{ width: "100%" }}
              >
                <AlertTitle>Success</AlertTitle>
                Account Information Updated
              </Alert>
            </Snackbar>

            <EntryContainer>
              <Root>
                <div>
                  {imageInfo && imageInfo.url ? (
                    <ShowImage />
                  ) : (
                    <section className="container">
                      <Box sx={{ textAlign: "center", paddingTop: "30px" }}>
                        Logo
                      </Box>
                      <StyledDropzone
                        {...getRootProps({
                          style: { color: isDragReject ? "red" : "#bdbdbd" },
                        })}
                      >
                        <input {...getInputProps()} />
                        {isDragReject ? (
                          <>
                            <CenteredText>Invalid File Type</CenteredText>
                            <CenteredText>Only Jpeg and Png files</CenteredText>
                          </>
                        ) : isDragActive ? (
                          "Drop the image here"
                        ) : (
                          <>
                            <CenteredText>Drag logo here</CenteredText>
                            <CenteredText>...or click to select</CenteredText>
                            <CenteredText>png & jpeg (1MB limit)</CenteredText>
                          </>
                        )}
                      </StyledDropzone>
                    </section>
                  )}
                  <Card raised={true}>
                    <CardContent>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">
                          Required Info
                        </Typography>
                      </Box>
                      <FieldWrapper>
                        <TextInput
                          name="name"
                          label="Company/Practice Name"
                          required
                        />
                        <FormControl variant="filled" fullWidth>
                          <InputLabel id="country-select-label">
                            Country
                          </InputLabel>
                          <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={values.countryId}
                            label="Country Code"
                            onChange={(event) => {
                              const countryId = event.target.value;
                              setFieldValue("countryId", countryId);
                            }}
                          >
                            {menuCountryItems}
                          </Select>
                        </FormControl>
                      </FieldWrapper>
                    </CardContent>
                  </Card>
                  <Divider sx={{ mt: "25px", mb: "25px" }} variant="middle" />
                  <Card raised={true}>
                    <CardContent>
                      <AddressInfo />
                    </CardContent>
                  </Card>
                  <Divider sx={{ mt: "25px", mb: "25px" }} variant="middle" />
                  <Card raised={true}>
                    <CardContent>
                      <InvoiceFooter />
                    </CardContent>
                  </Card>
                  <Divider variant="middle" sx={{ mt: "25px", mb: "25px" }} />
                  <Card raised={true}>
                    <CardContent>
                      <PaymentInstructions />
                    </CardContent>
                  </Card>
                  <Divider variant="middle" sx={{ mt: "25px", mb: "25px" }} />
                  <Card raised={true}>
                    <CardContent>
                      <InvoiceLabel />
                    </CardContent>
                  </Card>
                  <Divider variant="middle" sx={{ mt: "25px", mb: "25px" }} />
                  {showMore && (
                    <div>
                      <Card raised={true}>
                        <CardContent>
                          <InvoiceFormatting />
                        </CardContent>
                      </Card>
                    </div>
                  )}
                  {showMore && (
                    <div>
                      <Divider
                        variant="middle"
                        sx={{ mt: "25px", mb: "25px" }}
                      />
                      <Card raised={true}>
                        <CardContent>
                          <PaymentReceiptFormatting />
                        </CardContent>
                      </Card>
                    </div>
                  )}
                  {!showMore && (
                    <>
                      <Box
                        typography="body1"
                        mt="15px"
                        sx={{ textDecoration: "underline" }}
                      >
                        Advanced Customizations:
                      </Box>
                      <Box typography="body2" mt="15px">
                        Click below to customize the emails used to send the
                        invoice and payment receipt.
                      </Box>
                    </>
                  )}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                      onClick={() => toggleShowMore(!showMore)}
                      type="button"
                      sx={{ mt: "5px" }}
                    >
                      {showMore ? "Hide customizations" : "customize emails"}
                    </Button>
                  </Box>
                </div>
              </Root>
              <SubmitButton
                useBottomBar={true}
                disabled={
                  isSubmitting ||
                  (!dataNeedSave.current && !imageInfo.imageNeedSave)
                }
              />
            </EntryContainer>
          </Form>
        );
      }}
    </Formik>
  );
};
