export const toFloat = (value: string|undefined|null) : number => {
  if (!value) {
    return 0.0
  }

  const ret = parseFloat(value);

  if (!ret) {
    throw new Error(`Invalid conversion to float ${value}`)
  }

  return ret;
}
