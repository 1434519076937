import { colors } from '@mui/material';

const white = '#FFFFFF';

const palette = {
  primary: {
    contrastText: white,
    // main: '#7986cb',
    dark: colors.indigo[900],
    main: colors.indigo[500],
    light: colors.indigo[100]
  },
  secondary: {
    contrastText: white,
    main: '#ec417a',
    // dark: colors.blue[900],
    // main: colors.blue.A700,
    // light: colors.blue.A400
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: 'hsl(210, 32%, 93%)',
    paper: white
  },
  divider: "rgba(0, 0, 0, 0.12)"
};

export default palette;
