import {auth} from "./Core";
import {signOut as firebaseSignOut} from "@firebase/auth";
// import 'firebase/auth'

/*
export const AuthenticationService1 = () => {
  return {
    signOut() {
      auth
        .signOut()
        .then(() => {
          // Sign-out successful.
          sessionStorage.clear();
        })
        .catch((error) => {
          console.error("signout error", error);
        });

    },
    getFirebaseAuthentication() {
      return useAuth();
    },
    currentUser() {
      return useAuth().currentUser;
    },
    async getToken() {
      const currentUser = useAuth().currentUser;
      if (currentUser === "pending" || !currentUser) {
        return currentUser;
      }
      const token = await currentUser
        .getIdToken( false)
        .then((idToken) => {
          return idToken;
        })
        .catch((error) => {
          console.log("firrebase error", error);
          throw error;
        });
      return token;
    },
  };
};
*/
class AuthenticationService {
  public static async getToken() {
    const currentUser = auth.currentUser;

    return currentUser !== null
      ? await currentUser
          .getIdToken(/* forceRefresh */ false)
          .then((idToken) => {
            // Send token to your backend via HTTPS
            return idToken;
          })
          .catch((error) => {
            console.log("firrebase error", error);
            throw error;
          })
      : currentUser;
  }

  public static currentUser() {
    const currentUser = auth.currentUser;
    return currentUser;
  }

  public static getFirebaseAuthentication() {
    return auth;
  }


  public static async signOut()  {
    try {
      // console.log("sign off called")
      // debugger
      await firebaseSignOut(auth);
      sessionStorage.clear()
    } catch (error) {
      console.log("error signing out")
    }

  }
}

export { AuthenticationService };
