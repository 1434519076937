import React from "react";
import {AlertDialog, IAlertProps} from "components/Alert";

import {Form, Formik, FormikErrors, FormikHelpers, FormikValues,} from "formik";
import {Box, Card, CardContent, Stack} from "@mui/material";
import {TextInput} from "components/TextInput";
import {BackButton, SubmitButton} from "components/Buttons";
import {EntryContainer} from "components/Containers";
import {useSendContactEmailMutation} from "generated/graphql";
import validator from "validator";
import { useTopOfForm } from "../../hooks";

interface IFormValues {
  name: string;
  email: string;
  message: string;
}

const initialValues: IFormValues = {
  name: "",
  email: "",
  message: "",
};

export const ContactEmail: React.FC = () => {
  const [sendContactEmail] = useSendContactEmailMutation();

  const [alertArgs, setAlertArgs] = React.useState<IAlertProps>({
    open: false,
    message: "",
    title: "",
  });

  useTopOfForm()

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<IFormValues> = {};

    if (!values.name) {
      errors.name = "Name Requred";
    }

    if (!validator.isEmail(values.email.trim())) {
      errors.email = "Invalid Email";
    }

    if (!values.email) {
      errors.email = "Email Requred";
    }

    if (!values.message) {
      errors.message = "Message Requred";
    }

    return errors;
  };

  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    formikHelpers.setSubmitting(true);

    try {
      const retData = await sendContactEmail({
        variables: values,
      });
      const ret = retData?.data?.sendContactEmail;

      if (ret?.success) {
        setAlertArgs({
          open: true,
          message: "Your message has been sent to Workbx",
        });
        formikHelpers.setSubmitting(false)
        formikHelpers.resetForm()
      } else {
        formikHelpers.setSubmitting(false);
        setAlertArgs({
          open: true,
          title: "Email Error",
          message: "Message not sent - please try again",
        });
      }
    } catch (e) {
      setAlertArgs({
        open: true,
        title: "Network Error",
        message: "Please re-save after internet connection restored",
      });
    }
  };

  // const errorComponent = (ret: object) => {
  //   return (
  //     <ul style={{}}>
  //       {Object.entries(ret).map(([, value], idx) => (
  //         <li key={idx}>{value}</li>
  //       ))}
  //     </ul>
  //   );
  // };

  const BasicInfo = () => {
    return (
      <Stack spacing="16px">
        <TextInput name="name" />
        <TextInput name="email" />
        <TextInput multiline={true} rows={5} name="message" />
      </Stack>
    );
  };

  return (
    <>
      <Formik
        enableReinitialize={false}
        initialValues={initialValues}
        validate={validate}
        onSubmit={(values, formikHelpers) => {
          handleSubmit(values, formikHelpers);
        }}
      >
        {(formikHelpers) => {
          const { isSubmitting  } = formikHelpers;
          return (
            <Box sx={{height:"100vh", display:"flex", flexDirection:"column"}}>
              <Form
                noValidate
                autoComplete="off"
              >
                <AlertDialog
                  alertProps={alertArgs}
                  setAlertProps={setAlertArgs}
                />
                <BackButton  />
                <EntryContainer title="Contact Workbox">
                  <>
                    <Card raised={true}>
                      <CardContent>
                        <BasicInfo />
                      </CardContent>
                    </Card>
                    <SubmitButton label="Submit" disabled={isSubmitting} />
                  </>
                </EntryContainer>
              </Form>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};
