// @ts-ignore
import React, { useContext, useEffect } from 'react'
// @ts-ignore
import { AppContext } from 'context'
import {  useTheme } from '@mui/material/styles'
import { Typography} from '@mui/material'
import {
  Theme,
} from '@mui/material/styles'
import { ColorDemo } from './ColorDemo'
import { ColorEntryDialog } from './index'
import { IThemeInfo, ThemeTypeEnum } from './types'
import { colorArray } from 'utils'
import { useUpdateThemeMutation} from '../../generated/graphql'
import {makeStyles, createStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullScreen: {
      display: 'flex',
      marginBottom: 40,
      marginTop: 20,
      justifyContent: 'space-around',
      alignContent: 'flex-start',
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
        marginLeft:20,
      },
    },
    custom: {
      width: '90px',
      margin: '24px 0 0 20px',
      textAlign: 'right',
    },
    customBody: {
      marginTop: 13,
    },
    editButton: {
      marginTop: 14,
    },
    saveButton: {
      margin: '20px 15px 20px auto',
      display: 'block',
      width: 120,
    },
    divider: {
      backgroundColor: 'lightGrey',
      margin: theme.spacing(2),
    },
    menuButton: {
      marginRight: 20,
    },
    appBar: {
      width: 'calc(100% - 240)',
      height: 80,
      left: 240,
      position: 'fixed',
      top: 'auto',
      bottom: 0,
    },
  })
)

export const CustomizeTheme = () => {
  const { updateTheme } = useContext(AppContext)
  const [initializationComplete, setInitializationComplete] = React.useState(false);
  const [updateThemeMutation] = useUpdateThemeMutation();
  const theme = useTheme()
  const [open, setOpen] = React.useState<boolean>(false)
  const [themeInfo, setThemeInfo] = React.useState<IThemeInfo>({
    themeType: ThemeTypeEnum.custom,
    presetIdx: -1,
    customPrimary: theme.palette.primary.main,
    customSecondary: theme.palette.secondary.main,
  })

  // console.log("theme", theme)

  useEffect(() => {
    colorArray.forEach((item, idx) => {
      if (
        item.primary === theme.palette.primary.main &&
        item.secondary === theme.palette.secondary.main
      ) {
        const newThemeInfo: IThemeInfo = {
          ...themeInfo,
          themeType: ThemeTypeEnum.usePreset,
          presetIdx: idx,
        }
        setThemeInfo(newThemeInfo)
      }
    })
    setInitializationComplete(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect( ()  =>  {
    const saveTheme = () => {
      const primaryMain =
        themeInfo.themeType === ThemeTypeEnum.custom
          ? themeInfo.customPrimary
          : colorArray[themeInfo.presetIdx].primary

      const secondaryMain =
        themeInfo.themeType === ThemeTypeEnum.custom
          ? themeInfo.customSecondary
          : colorArray[themeInfo.presetIdx].secondary

      // const newPrimary = theme.palette.augmentColor({ main: primaryMain })
      const newPrimary = theme.palette.augmentColor({
        color: {
          main: primaryMain,
        },
      });
      const newSecondary = theme.palette.augmentColor({
        color: {
          main: secondaryMain,
        },
      });

      // const newSecondary = theme.palette.augmentColor({ main: secondaryMain })
      const newPalette = {
        ...theme.palette,
        primary: newPrimary,
        secondary: newSecondary,
      }
      const newTheme = { ...theme, palette: newPalette }
      updateTheme(newTheme)
    }
    if (initializationComplete) {
      saveTheme()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializationComplete, themeInfo.themeType, updateTheme, themeInfo.presetIdx, themeInfo.customPrimary, themeInfo.customSecondary])

  const classes = useStyles()
  return (
    <div>
      <ColorEntryDialog
        open={open}
        setOpen={setOpen}
        themeInfo={themeInfo}
        setThemeInfo={setThemeInfo}
        updateThemeMutation={updateThemeMutation}
      />
      <div className={classes.fullScreen}>
        {colorArray.map((_, idx) => {
          return (
            <ColorDemo
              themeInfo={themeInfo}
              setThemeInfo={setThemeInfo}
              key={idx}
              idx={idx}
              isCustom={false}
              updateThemeMutation={updateThemeMutation}
            />
          )
        })}
        <div style={{ display: 'flex' }}>
          <div className={classes.custom}>
            <Typography variant="h6">Or design your own Theme</Typography>
            <Typography variant="body1" className={classes.customBody}>
              Select the Theme on the right and enter your own colors
            </Typography>
          </div>
          <div>
            <ColorDemo
              themeInfo={themeInfo}
              setThemeInfo={setThemeInfo}
              idx={-1}
              isCustom={true}
              key={999}
              setOpen={setOpen}
              updateThemeMutation={updateThemeMutation}
            />
          </div>
        </div>
      </div>
      {/*<Divider className={classes.divider} variant="middle" />*/}
      {/*<Button*/}
      {/*  color="primary"*/}
      {/*  variant="contained"*/}
      {/*  className={classes.saveButton}*/}
      {/*  onClick={saveTheme}*/}
      {/*>*/}
      {/*  Save*/}
      {/*</Button>*/}
    </div>
  )
}

// export default CustomizeTheme
