import React, { useEffect, useMemo } from "react";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Paper,
} from "@mui/material";
import { Clear, Search } from "@mui/icons-material";
import { debounce } from "lodash";
import { SearchContext } from "context/SearchContext";
import { createStyles, makeStyles } from "@mui/styles";
import { alpha, Theme } from "@mui/material/styles";
import { useLocation, useSearchParams } from "react-router-dom";

interface IUseStyles {
  hasValues:boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // grid: (props: IUseStyles) => ({
    root: (props:IUseStyles) => ({
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: "100%",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: props.hasValues ? theme.palette.common.white : alpha(theme.palette.common.white, 0.55),
      "&:hover": {
        backgroundColor:   theme.palette.common.white,
      },
      color: theme.palette.text.primary,
      zIndex: theme.zIndex.tooltip + 1,
    }),
    input: {
      // marginLeft: theme.spacing(1),
      flex: 1,
    },
    inputRoot: {
      color: "inherit",
    },
  })
);

interface IRoutes {
  // invoices:string
  // clients:string
  // payments: string
  // import: string
  // sessions: string
  [key: string]: string

}

export const SearchField: React.FC = () => {
  const { setSearchText } = React.useContext(SearchContext);
  const [value, setValue] = React.useState("");
  const prevRoute = React.useRef<string>("");
  const classes = useStyles({hasValues:Boolean(value)});
  const location = useLocation();
  const [searchParams] = useSearchParams({});
  const tab = parseInt(searchParams.get("tab") || "0") || 0;
  const lastChar = location.pathname.slice(-1);
  const path = lastChar === "/" ? location.pathname.slice(1,-1) : location.pathname.slice(1);
  const pathnameArray = path.split("/");
  const depth = pathnameArray.length;
  const route = pathnameArray[0];
  const [values, setValues] = React.useState<IRoutes>({
    invoices:"ii",
    sessions:"ss",
    payments:"pp",
    clients:"cc",
    import:"immm"
  })

  const searchVisible =
    (route === "invoices" ||
      route === "sessions" ||
      route === "payments" ||
      route === "clients" ||
      (route === "import" && tab === 1))
    && depth === 1;

  useEffect(() => {
    if (route !== prevRoute.current) {
      prevRoute.current = route;
      setSearchText!("");
      setValue("");
    }
  }, [tab, route, setSearchText, setValue]);

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((text: string) => {
        setSearchText!(text);
      }, 500),
    [setSearchText]
  );

  return (
    <FormControl
      sx={{ m: 1, width: "25ch", maxWidth: "40vw", display: searchVisible ? "block" : "none" }}
      variant="standard"
    >
      <Paper component="form" className={classes.root} >
        <Input
          // id="search"
          value={value||""}
          className={classes.input}
          disableUnderline={true}
          autoComplete="off"
          autoCorrect="off"
          placeholder="Search"
          type="text"
          onChange={(e) => {
            setValue(e.target.value);
            debouncedChangeHandler(e.target.value);
            const newValues = {...values, [route]:e.target.value}
            setValues(newValues)
          }}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="search" size="small">
                <Search />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="clear text"
                // onMouseDown={handleMouseDownPassword}
                onClick={() => {
                  setSearchText!("");
                  setValue("");
                }}
                size="small">
                <Clear color="disabled" fontSize="small" />
              </IconButton>
            </InputAdornment>
          }
        />
      </Paper>
    </FormControl>
  );
};
