import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { NoMatchRoute } from "./NoMatch";

import { CustomizeTheme } from "./Settings";
import { PrivateRoute2 as Pl } from "./PrivateRoute2";
import { CrashScreen } from "./Crash";
import { ChangeCompanyContainer } from "components/Company/ChangeCompanyContainer";
import { ChangeUserContainer } from "components/User";
import { InvoiceReportController, PaymentReportController } from "components/Reports/";
import { DisplayPrivacy, DisplayTerms } from "./Legal";
import { ContactEmail } from "./Contact";
import { Tutorials } from "./Docs";
import { CheckoutCancelled, CheckoutSuccessful, StripePaymentEntry, SubscriptionStatus } from "./Stripe";
import { LoadingScreen } from "../components/LoadingScreen";
import { SignInContainer } from "./SignIn";
import { OnboardingContainer1, Register } from "./Register";
import { ForgotPassword } from "./SignIn/ForgotPassword";
import { Home, Pricing } from "./Home";
import { SuperbillController } from "../components/Reports";

const ClientController = lazy(() =>
  import("./Clients").catch((e) => {
    console.log("got code splitting error");
    window.location.reload();
  })
);
const InvoiceController = lazy(() =>
  import("./Invoices").catch((e) => {
    console.log("got code splitting error");
    window.location.reload();
  })
);
const SessionController = lazy(() =>
  import("./Sessions").catch((e) => {
    console.log("got code splitting error");
    window.location.reload();
  })
);
const PaymentController = lazy(() =>
  import("./Payments").catch((e) => {
    console.log("got code splitting error");
    window.location.reload();
  })
);
const ImportController = lazy(() =>
  import("./Import").catch((e) => {
    console.log("got code splitting error");
    window.location.reload();
  })
);
const ServiceController = lazy(() =>
  import("./Services").catch((e) => {
    console.log("got code splitting error");
    window.location.reload();
  })
);

export function Root() {
  return (
    <React.Suspense fallback={<LoadingScreen loading={true} />}>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/sign-in" element={<SignInContainer />} />
        <Route
          path="/clients/*"
          element={
            <Pl title="Clients">
              <ClientController />
            </Pl>
          }
        />
        <Route
          path="/sessions/*"
          element={
            <Pl title="Sessions">
              <SessionController />
            </Pl>
          }
        />
        <Route
          path="/settings"
          element={
            <Pl title="Settings">
              <CustomizeTheme />
            </Pl>
          }
        />
        <Route
          path="/invoices/*"
          element={
            <Pl title="Invoices">
              <InvoiceController />
            </Pl>
          }
        />
        <Route path="/register" element={<Register />} />
        <Route path="/home" element={<Home />} />
        <Route
          path="/tutorials"
          element={
            <Pl title="Tutorials">
              {" "}
              <Tutorials />{" "}
            </Pl>
          }
        />
        <Route
          path="/payments/*"
          element={
            <Pl title="Payments">
              <PaymentController />
            </Pl>
          }
        />
        <Route
          path="/import/*"
          element={
            <Pl title="Import Sessions">
              <ImportController />
            </Pl>
          }
        />
        <Route
          path="/services/*"
          element={
            <Pl title="Services">
              <ServiceController />
            </Pl>
          }
        />
        <Route
          path="/user-profile"
          element={
            <Pl title="User Profile">
              <ChangeUserContainer />
            </Pl>
          }
        />
        <Route
          path="/onboarding"
          element={
            <Pl title="Workbox">
              <OnboardingContainer1 />
            </Pl>
          }
        />
        <Route path="/contact" element={<ContactEmail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/terms" element={<DisplayTerms />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacy" element={<DisplayPrivacy />} />
        <Route
          path="/account-settings"
          element={
            <Pl title="General">
              <ChangeCompanyContainer />
            </Pl>
          }
        />
        <Route
          path="/payment-report/*"
          element={
            <Pl title="Reports">
              <PaymentReportController />
            </Pl>
          }
        />
        <Route
          path="/superbill/*"
          element={
            <Pl title="Reports">
              <SuperbillController />
            </Pl>
          }
        />

        <Route
          path="/invoice-report/*"
          element={
            <Pl title="Reports">
              <InvoiceReportController />
            </Pl>
          }
        />
        <Route
          path="/stripe/*"
          element={
            <Pl title="Stripe">
              <StripePaymentEntry />
            </Pl>
          }
        />
        <Route
          path="/stripe-cancelled"
          element={
            <Pl title="Stripe">
              <CheckoutCancelled />
            </Pl>
          }
        />
        <Route
          path="/stripe-successful"
          element={
            <Pl title="Stripe">
              <CheckoutSuccessful />
            </Pl>
          }
        />
        <Route
          path="/subscription-status"
          element={
            <Pl title="Stripe">
              <SubscriptionStatus />
            </Pl>
          }
        />
        <Route
          path="/subscription-status-nopl"
          element={<SubscriptionStatus />}
        />
        <Route path="/crash" element={<CrashScreen />} />
        <Route path="*" element={<NoMatchRoute />} />
      </Routes>
    </React.Suspense>
  );
}
