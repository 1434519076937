import React from "react";
import { Root } from "./routes";
import { CssBaseline, NoSsr } from "@mui/material";
import { StylesProvider } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import * as Sentry from "@sentry/react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import { createUploadLink } from "apollo-upload-client";
import { AuthenticationService } from "./services";
import { billingDates, localCache } from "./graphqlDocuments";
import { AuthenticationProvider } from "./context/AuthenticationProvider";
import { AppContextProvider } from "./context";
import { SearchContextProvider } from "context/SearchContext";
import { RetryLink } from "@apollo/client/link/retry";

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 200,
    jitter: true,
  },
  attempts: {
    max: 2,
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = await AuthenticationService.getToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      companyid: localCache().companyId,
    },
  };
});

const uri = process.env.REACT_APP_API_URL
  ? `${process.env.REACT_APP_API_URL}/graphql`
  : "http://192.168.1.50:4000/graphql";
// const httpLink = createHttpLink({
//   uri,
// });

const cache = new InMemoryCache({
  typePolicies: {
    BillableSession: {
      merge: false,
    },
    BillableSessions: {
      merge: false,
    },

    Query: {
      fields: {
        billingPeriod: {
          read() {
            return billingDates();
          },
        },
        local: {
          read() {
            return localCache();
          },
        },
        billableSessions: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

export function App() {
  // const nav = useNavigate();
  // const [searchText, setSearchText] = useState("");

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    console.log("operation", operation, graphQLErrors);
    Sentry.withScope((scope) => {
      scope.setTransactionName(operation.operationName);
      scope.setContext("apolloGraphQLOperation", {
        operationName: operation.operationName,
        variables: operation.variables,
        extensions: operation.extensions,
      });

      graphQLErrors?.forEach((error) => {
        Sentry.captureMessage(error.message, {
          level: Sentry.Severity.Error,
          fingerprint: ["{{ default }}", "{{ transaction }}"],
          contexts: {
            apolloGraphQLError: {
              error,
              message: error.message,
              extensions: error.extensions,
            },
          },
        });
      });

      if (graphQLErrors && graphQLErrors?.length > 0) {
        alert(`sentry ${graphQLErrors[0].message}`);
      }

      if (networkError) {
        Sentry.captureMessage(networkError.message, {
          level: Sentry.Severity.Error,
          contexts: {
            apolloNetworkError: {
              error: networkError,
              extensions: (networkError as any).extensions,
            },
          },
        });
      }
    });
  });

  /*
  const errorLink = onError(
    ({ response, graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.log("graphqlerror ", graphQLErrors);
      }
      if (networkError) {
        console.log("network error", networkError);
      }
      console.log("response", response)

      // throw new ApolloError({networkError})
      //
      // if (graphQLErrors || networkError) {
      //   Sentry.captureException(new ApolloError({graphQLErrors, networkError}))
      // }
      //   graphQLErrors.map(({ message }) => Sentry.captureException(new ApolloError(message)));
      // if (networkError) {
      //   Sentry.captureException(new Error(networkError.message));
      // }

      // nav("/crash", { state: { graphQLErrors, networkError }, replace: true });
    }
  );
*/
  const uploadLink = (createUploadLink({
    uri,
  }) as unknown) as ApolloLink;

  //onst memoizedValue = useMemo(() => computeExpensiveValue(a, b), [a, b]);
  const client = React.useMemo(
    () =>
      new ApolloClient({
        link: from([errorLink, retryLink, authLink, uploadLink]),
        cache,
        connectToDevTools: process.env.NODE_ENV === "development",
        resolvers: {},
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "cache-and-network",
            errorPolicy: "ignore",
          },
          query: {
            fetchPolicy: "cache-first",
            errorPolicy: "none",
          },
          mutate: {
            errorPolicy: "none",
          },
        },
      }),
    [errorLink, uploadLink]
  );

  return (
    <React.StrictMode>
      <ApolloProvider client={client}>
        <AuthenticationProvider>
          <StylesProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SearchContextProvider>
                <AppContextProvider>
                  <CssBaseline />
                  <NoSsr>
                    <Root />
                  </NoSsr>
                </AppContextProvider>
              </SearchContextProvider>
            </LocalizationProvider>
          </StylesProvider>
        </AuthenticationProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
}
