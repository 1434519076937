import { makeVar } from "@apollo/client";
import { CloudServiceType } from "../utils/constants";
import dayjs from "dayjs";
import { BillingDateRangeFilterEnum, IBillingDates } from "../types";
import { utils } from "../utils";

interface ILocalCache {
  companyId: string;
  lastSessionOffset: number;
  lastSessionTab: number;
  staffId: string;
  userId: string;
  activeServiceTypes: Set<CloudServiceType>;
  locale: string;
  staySignedIn: boolean;
  lastImportDate?: Date;
  importThruDate: string;
  overrideInvoiceDate?: string
}

export const localCache = makeVar<ILocalCache>({
  companyId: "",
  lastSessionOffset: 0,
  lastSessionTab: 0,
  staffId: "",
  userId: "",
  activeServiceTypes: new Set(),
  locale: "",
  staySignedIn: false,
  importThruDate: utils.defaultImportThruDate()
});

// used for client listing screen.  If date is the 7th of month or prior,
// assume that user is actually billing the prior month
export const billingDates = makeVar<IBillingDates>({
  billingDateBase: dayjs().format("YYYY-MM-DD"),
  billingDateFilter: dayjs().date() < 6 ? BillingDateRangeFilterEnum.PriorMonth : BillingDateRangeFilterEnum.CurrentMonth,
});

// used on NewInvoice screen
export const newInvoiceDates = makeVar<IBillingDates>({
  billingDateBase: dayjs().format("YYYY-MM-DD"),
  billingDateFilter: BillingDateRangeFilterEnum.CurrentMonth,
});
