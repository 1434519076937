import { Card, CardContent, Skeleton, Stack, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BackButton } from "../Buttons";
import { LoadingScreen } from "../LoadingScreen";
import { EntryContainer } from "../Containers";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 5,
    [theme.breakpoints.up("md")]: {
      marginTop: 15,
    },
  },
}));

export const DataEntrySkeleton = ({ title }: { title: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BackButton />
      <LoadingScreen loading={true} />
      <EntryContainer title={title}>
        <Card raised={true}>
          <CardContent>
            <Stack spacing="16px">
              <Skeleton variant="rectangular" height={56} />
              <Skeleton variant="rectangular" height={56} />
              <Skeleton variant="rectangular" height={56} />
              <Skeleton variant="rectangular" height={56} />
            </Stack>
          </CardContent>
        </Card>
      </EntryContainer>
    </div>
  );
};
