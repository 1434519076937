import React, { Dispatch } from "react";
import * as locales from "@mui/material/locale";
import { DeprecatedThemeOptions } from '@mui/material/styles'
import { utils} from "utils";

type SupportedLocales = keyof typeof locales;

interface IAppContext {
  updateTheme: Dispatch<DeprecatedThemeOptions>;
  updateLocale: Dispatch<SupportedLocales>;
  currentIsoDate: string
}

const initialState: IAppContext = {
  //@ts-ignore
  updateTheme: (theme: DeprecatedThemeOptions) => {},
  //@ts-ignore
  updateLocale: (locale: SupportedLocales) => {
  },
  currentIsoDate: utils.todayIso()
};

export const AppContext = React.createContext<IAppContext>(initialState);
