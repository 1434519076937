import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


const defaultTouchProps:Partial<TooltipProps> = {
  enterTouchDelay:1,
  leaveTouchDelay:8000
}

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...{...defaultTouchProps, ...props}} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 280,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
