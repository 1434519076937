import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { EntryMode } from "../../utils";
import { ClientEntry } from "./ClientEntry";
import { IFormValues, initialState, IReducerState, ActionType } from "./index";
import {
  IMuiSelect,
  IRecordSavedProps,
  IUpdateRet,
  MasterActionType,
} from "../../types";
import { AddServiceContainer } from "../Service";

interface IProps {
  mode: EntryMode;
  initialValues: IFormValues;
  loading?: boolean;
  saveData: (values: IFormValues) => Promise<IUpdateRet>;
}

export function reducer(
  state: IReducerState,
  action: ActionType | MasterActionType
): IReducerState {
  switch (action.type) {
    case "SERVICE_ELEMENT_SELECTED": {
      const newState: IReducerState = {
        ...state,
        serviceElement: action.payload.element,
      };
      return newState;
    }
  }
  return state;
}

export const ClientEntryController: React.FC<IProps> = ({
  mode,
  initialValues,
  loading = false,
  saveData,
}) => {
  const [formValues, setFormValues] = React.useState<IFormValues>(
    initialValues
  );

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const firstTime = React.useRef(true);

  // change mode reads most current value from server
  // so update when async data received
  if (firstTime.current) {
    if (mode === EntryMode.ADD) {
      firstTime.current = false;
    } else if (!loading && mode === EntryMode.CHANGE) {
      firstTime.current = false;
      setFormValues(initialValues);
    }
  }

  const handleServiceAdded = (props: IRecordSavedProps) => {
    const service: IMuiSelect = {
      id: props.id,
      description: props.optionsDescription,
    };
    const serviceFees = formValues.serviceFees;
    if (state.serviceElement >= serviceFees.length) {
      return;
    }
    serviceFees[state.serviceElement].service = service;
    const newFormvalues: IFormValues = { ...formValues, serviceFees };
    setFormValues(newFormvalues);
  };

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            <ClientEntry
              mode={mode}
              formValues={formValues}
              setFormValues={setFormValues}
              loading={loading}
              saveData={saveData}
              dispatch={dispatch}
            />
          }
        />
        <Route
          path="add-service"
          element={<AddServiceContainer recordSaved={handleServiceAdded} />}
        />
      </Routes>
      <Outlet />
    </React.Fragment>
  );
};
