import {Client, useClientsQuery} from "../generated/graphql";
import {localCache} from "../graphqlDocuments";
import * as React from "react";
import {keyBy} from "lodash";

export type IKeyedClients = Record<string, Partial<Client> >;

export const useClientGetter = () => {

  const {data: clientData} = useClientsQuery({
    variables: {companyId: localCache().companyId},
    fetchPolicy: "cache-only",
  });

// avoid warning about complicated dependency list

  const keyedClients: IKeyedClients = React.useMemo(
    () => {
      const clientList = clientData ? clientData.clients : [];
      const keyed: IKeyedClients = keyBy(clientList, "id") as IKeyedClients
      return keyed;
    },
    [clientData]
  );

  const getOneClient = (id:string) => {
    return keyedClients[id];
  }

  return { getOneClient, keyedClients };
}
