export enum EntryMode {
  ADD,
  CHANGE,
}

export enum ThemeMode {
  LIGHT,
  DARK,
}

export const Countries = {
  usa: "United States",
  can: "Canada",
} as const;

export const Languages = {
  en: "English",
  es: "Spanish",
} as const;

export enum ClientTypeEnum {
  Individual = 0,
  CouplesAndFamily = 1,
  Company,
}

export const APP_START_DATE = "2020-01-01"

export const PENDING = "pending";

export const colorArray = [
  {
    primary: "#3f51b5",
    secondary: "#ec417a",
  },
  {
    primary: "#7986cb",
    secondary: "#ff8a80",
  },
  {
    primary: "#617d8b",
    secondary: "#e91e63",
  },
  {
    primary: "#6200ee",
    secondary: "#03dac5",
  },
  {
    primary: "#ffffff",
    secondary: "#000000",
  },
  {
    primary: "#0A8FDC",
    secondary: "#F04F47",
  },
  {
    primary: "#F0464D",
    secondary: "#00B6EF",
  },
  {
    primary: "#FD933A",
    secondary: "#5A63C8",
  },
  {
    primary: "#03A9F4",
    secondary: "#FF80AB",
  },
  {
    primary: "#9C27B0",
    secondary: "#FFCA28",
  },
  {
    primary: "#000000",
    secondary: "#ffffff",
  },
  {
    primary: "#FFC20A",
    secondary: "#0C7BDC",
  },
];

export enum CloudServiceType {
  GOOGLE = 0,
  MICROSOFT = 1
}

