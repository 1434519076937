import React, {FC} from "react";
import {Form, Formik, FormikErrors, FormikHelpers, FormikValues,} from "formik";
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import {TextInput} from "components/TextInput";
import {CountriesQuery, TimeZonesQuery,} from "generated/graphql";
import {LoadingScreen} from "../../components/LoadingScreen";
import {AlertDialog, IAlertProps} from "../../components/Alert";
import {IUpdateRet, SaveRetErrorType} from "../../types";
import {FieldWrapper, Root} from "../../components/StyledComponents";
import {EntryContainer} from "../../components/Containers";
import {SubmitButton} from "../../components/Buttons";
import {IFormValues} from './shared'
import {useNavigate} from "react-router-dom";

const validate = async (values: FormikValues) => {
  const errors: FormikErrors<IFormValues> = {};

  if (!values.name) {
    errors.name = "Required field";
  }

  return errors;
};


interface IProps {
  countries?: CountriesQuery;
  timeZones?: TimeZonesQuery;
  initialValues: IFormValues;
  saveData: (
    values: IFormValues,
  ) => Promise<IUpdateRet>;
}
export const OnboardingEntry1: FC<IProps> = (props) => {
  const {countries, timeZones, saveData} = props;
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const nav = useNavigate();
  const [alertArgs, setAlertArgs] = React.useState<IAlertProps>({
    open: false,
    message: "",
    title: "",
  });

  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    formikHelpers.setSubmitting(true);
    const ret = await saveData(values);

    if (ret.success) {
      nav("/clients", {replace:true})
      return;
    }
    formikHelpers.setSubmitting(false);
    if (!ret.success) {
      if (ret.errorType === SaveRetErrorType.Network) {
        setAlertArgs({
          open: true,
          title: "Network Error",
          message: "Please re-save after internet connection restored",
        });
      } else {
        setAlertArgs({
          open: true,
          title: "Errors Found - please correct",
          message: ret.errorMessage,
        });
      }
    }

    setShowSnackbar(true);

    return ret;
  };

  const menuItems = countries?.countries.map((country) => {
    return (
      <MenuItem key={country.id} value={country.id}>
        {country.name}
      </MenuItem>
    );
  });

  const timeZoneItems = timeZones?.timeZones.map((timeZone) => {
    return (
      <MenuItem key={timeZone.id} value={timeZone.id}>
        {timeZone.alias ? timeZone.alias : timeZone.tzName}
      </MenuItem>
    );
  });

  const handleClose = () => {
    setShowSnackbar(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      validate={validate}
      initialValues={props.initialValues}
      onSubmit={handleSubmit}
    >
      {(formikHelpers) => {
        const {
          values,
          isSubmitting,
          setFieldValue,
        } = formikHelpers;

        return (
          <Form
            noValidate
            autoComplete="off"
          >
            <LoadingScreen msg="Update in progress..." loading={isSubmitting} />
            <AlertDialog alertProps={alertArgs} setAlertProps={setAlertArgs} />
            <Snackbar
              sx={{ width: "80vw", maxWidth: "450px" }}
              open={showSnackbar}
              autoHideDuration={2000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                severity="success"
                variant="filled"
                onClose={handleClose}
                sx={{ width: "100%" }}
              >
                <AlertTitle>Success</AlertTitle>
                Account Information Updated
              </Alert>
            </Snackbar>

            <EntryContainer sx={{mt:"50px"}}>
              <Root>
                <div>
                  <Card raised={true}>
                    <CardContent>
                      <FieldWrapper spacing="20px">
                        <TextInput
                          name="name"
                          label="Company/Practice Name"
                          required
                          helperText='Name that will appear on the top of your invoices - ex. "Mary Smith, PhD",  "New Day Therapists, PC", etc.'
                        />
                        <FormControl variant="filled" fullWidth>
                          <InputLabel id="country-select-label">
                            Country
                          </InputLabel>
                          <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={values.countryId}
                            label="Country Code"
                            onChange={(event) => {
                              setFieldValue("countryId", event.target.value);
                            }}
                          >
                            {menuItems}
                          </Select>
                        </FormControl>
                        <FormControl variant="filled" fullWidth>
                          <InputLabel id="timeZone-select-label">
                            Time zone where you work
                          </InputLabel>
                          <Select
                            labelId="timeZone-select-label"
                            id="timeZone-select"
                            value={values.timeZoneId}
                            label="Country Code"
                            onChange={(event) => {
                             setFieldValue("timeZoneId", event.target.value);
                            }}
                          >
                            {timeZoneItems}
                          </Select>
                        </FormControl>

                      </FieldWrapper>
                    </CardContent>
                  </Card>
                </div>
              </Root>
              <SubmitButton
                disabled={isSubmitting}
              />
            </EntryContainer>
          </Form>
        );
      }}
    </Formik>
  );
};
