// this hook creates the "options" field needed for
// the mui Select component

import { useServicesQuery } from "../generated/graphql";
import { localCache } from "../graphqlDocuments";
import { IMuiSelect } from "../types";
import { orderBy } from "lodash";

const emptyServices: Array<IMuiSelect> = [];

interface IProps {
  setServices: React.Dispatch<React.SetStateAction<IMuiSelect[]>>;
}
export const useServicesOptions = (props: IProps) => {
  const setServices = props.setServices;

  useServicesQuery({
    variables: {companyId: localCache().companyId},
    fetchPolicy: "cache-only",
    onCompleted: (data) => {
      const services: Array<IMuiSelect> =
        !data || !data.services || data.services.length === 0
          ? emptyServices
          : orderBy(
          data.services,
          (service) => service.description.toLocaleLowerCase(),
          ["asc"]
          );
      setServices(services);
    }
  })
}
