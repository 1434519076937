import { FC, useEffect, useState } from "react";
import { Form, Formik, FormikErrors, FormikHelpers, FormikProps, FormikValues } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Paper
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextInput } from "components/TextInput";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import validator from "validator";
import { useRegisterMutation, useTimeZonesQuery, ValidateRegisterDocument } from "generated/graphql";
import { useAuth } from "context";
import { useApolloClient } from "@apollo/client";
import { LoadingScreen } from "../../components/LoadingScreen";
import { AlertDialog, IAlertProps } from "../../components/Alert";
import { useNavigate } from "react-router-dom";
import { browserLocalPersistence, browserSessionPersistence, onAuthStateChanged, setPersistence } from "firebase/auth";
import { auth } from "services/Core";
import * as Sentry from "@sentry/react";
import { GraphQLErrorScreen } from "../../components/Errors";
import { getAnalytics, logEvent } from "firebase/analytics";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  panel: {
    maxWidth: "80vw",
    width: 500,
    padding: 20,
    position: "relative",
  },
  fieldWrapper: {
    marginTop: 7,
    "& > div": {
      marginBottom: "16px",
      "&:last-child": {
        marginBottom: 1,
      },
    },
  },
  submit: {
    margin: "25px auto",
  },

  submit2: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    bottom: 20,
    left: "50%",
    "-webkit-transform": "translateX(-50%)",
  },
}));

interface IFormValues {
  email: string;
  password: string;
  password2: string;
  firstName: string;
  lastName: string;
  staySignedIn: boolean;
}

const validate = async (values: FormikValues) => {
  const errors: FormikErrors<IFormValues> = {};

  if (!validator.isEmail(values.email.trim())) {
    errors.email = "Enter valid Email";
  }

  const pw = values.password;
  const hasUppercase = pw.toLowerCase() !== pw;
  if (!hasUppercase) {
    errors.password = "Password must contain at least 1 uppercase lette";
  }

  const hasLowercase = pw.toUpperCase() !== pw;
  if (!hasLowercase) {
    errors.password = "Password must contain at least 1 lowercase lette";
  }

  const hasDigits = pw.match(/\d+/g);
  if (!hasDigits) {
    errors.password = "Password must contain at least 1 number";
  }

  if (!values.password) {
    errors.password = "Enter Password";
  }

  if (values.password !== values.password2) {
    errors.password2 = "Passwords don't match";
  }
  // if (!values.companyName) {
  //   errors.companyName = "Enter Practice Name";
  // }

  if (!values.firstName) {
    errors.firstName = "Enter First Name";
  }

  if (!values.lastName) {
    errors.lastName = "Enter Last Name";
  }

  return errors;
};

const initialValues: IFormValues = {
  email: "",
  password: "",
  lastName: "",
  firstName: "",
  password2: "",
  staySignedIn: false
};

export const Register: FC = () => {
  const classes = useStyles();
  const nav = useNavigate();
  const { registerFirebase, signOut } = useAuth();
  const [
    registerWorkboxUser,
    { data, loading: registerLoading },
  ] = useRegisterMutation();

  const client = useApolloClient();

  useEffect(() => {
    const { currentUser } = auth;
    if (currentUser) {
      signOut();
      return;
    }
  }, [signOut]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && data && !registerLoading) {
        nav("/onboarding", { replace: true });
      }
    });
    return unsubscribe;
  }, [nav, registerLoading, data]);

  // useEffect(() => {
  //   console.log("register auth status", authStatus)
  //   if (authStatus === AuthStatus.done ) {
  //     console.log("nav to payments")
  //     nav("/payments", {replace:true})
  //   }
  //   if (authStatus === AuthStatus.registrationDone) {
  //     if (currentUser && currentUser !== "pending") {
  //       debugger
  //       setAuthStatus(AuthStatus.needMeData)
  //     }
  //   }
  // },[authStatus, currentUser, nav, setAuthStatus])

  const [alertArgs, setAlertArgs] = useState<IAlertProps>({
    open: false,
    message: "",
    title: "",
  });

  const intlTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data: timeZones, loading, error } = useTimeZonesQuery({
    fetchPolicy: "cache-first",
  });


  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    formikHelpers.setSubmitting(true);

    // invocke ValidateRegister to see if all ok.  Should only find email in use error.
    // but also doing this to make sure network and connectivity are ok
    // before registering user with firebase since immediately after doing that
    // we call server to create new User, Company and Staff recs - calling
    // validate will reduce chance that one will succeed and backend fail which
    // would cause problems.
    const ret = await client.query({
      query: ValidateRegisterDocument,
      fetchPolicy: "network-only",
      variables: {
        ...values,
        companyName: "a;lksdjf",
        timeZoneId: "1",
      },
    });
    if (!ret.data.validateRegister.success) {
      formikHelpers.setSubmitting(false);
      setAlertArgs({
        open: true,
        message: ret.data.validateRegister.error.message,
        title: "Error",
      });
      return;
    }

    const myTimeZone = timeZones?.timeZones.find((tz) => {
      return tz.tzName === intlTz;
    });

    if (!values.staySignedIn) {
      await setPersistence(auth, browserSessionPersistence);
    } else {
      await setPersistence(auth, browserLocalPersistence);
    }

    try {
      const firebaseRet = await registerFirebase(values.email, values.password);
      Sentry.setUser({ email:values.email || undefined });
      registerWorkboxUser({
        variables: {
          ...values,
          timeZoneId: myTimeZone?.id || "1",
          firebaseId: firebaseRet.user.uid,
        },
        onCompleted(data) {
          const analytics = getAnalytics();
          logEvent(analytics, "sign_up", {
          email: data.register?.user?.email||"no email",
        });
        },
        onError(error) {
          formikHelpers.setSubmitting(false);
          setAlertArgs({
            open: true,
            message: error.message || "",
            title: "Error",
          });
        },
      });
    } catch (error:any) {
      setAlertArgs({ open: true, message: error.message, title: "Error" });
      formikHelpers.setSubmitting(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const Icon = showPassword ? VisibilityOff : Visibility;

  if (error) {
    return <GraphQLErrorScreen error={error} />
  }

  const overrideInputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          tabIndex={-1}
          aria-label={showPassword ? "Hide Password" : "Show Password"}
          onClick={() => setShowPassword((curValue: boolean) => !curValue)}
          size="large"
        >
          <Icon fontSize="small" />
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {(formikProps: FormikProps<IFormValues>) => {
        const { isSubmitting, values, setFieldValue } = formikProps;
        return (
          <Container maxWidth="md" sx={{ mt: 3 }} disableGutters={true}>
            <LoadingScreen loading={loading} />
            <AlertDialog alertProps={alertArgs} setAlertProps={setAlertArgs} />
            <Box textAlign="center" mb={3}>
              <Box typography="h5">Workbox</Box>
              <Box typography="h5">Start your 30-day free trial</Box>
              <Box typography="body2">
                App available in the U.S. and and Canada only
              </Box>
            </Box>
            <div className={classes.root}>
              <Form noValidate={true} autoComplete="off">
                <Paper elevation={8} className={classes.panel}>
                  <div className={classes.fieldWrapper}>
                    <TextInput name="firstName" required />
                    <TextInput name="lastName" required />
                    <TextInput type="email" name="email" required />
                    <TextInput
                      type={showPassword ? "text" : "password"}
                      name="password"
                      autoComplete="new-password"
                      id="new-password"
                      required
                      helperText="Min 8 characters including: uppercase, lowercase and number"
                      overrideInputProps={overrideInputProps}
                    />
                    {values.password && (
                      <TextInput
                        type={showPassword ? "text" : "password"}
                        label="Verify Password"
                        name="password2"
                        required
                        helperText="Min 8 characters including: uppercase, lowercase and number"
                        overrideInputProps={overrideInputProps}
                      />
                    )}
                    {/*<FormControl variant="filled" fullWidth>*/}
                    {/*  <InputLabel id="timeZone-select-label">*/}
                    {/*    Time Zone*/}
                    {/*  </InputLabel>*/}
                    {/*  <Select*/}
                    {/*    labelId="timeZone-select-label"*/}
                    {/*    id="timeZone-select"*/}
                    {/*    value={values.timeZoneId}*/}
                    {/*    label="Time Zone"*/}
                    {/*    onChange={(event) => {*/}
                    {/*      setFieldValue("timeZoneId", event.target.value);*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    {menuItems}*/}
                    {/*  </Select>*/}
                    {/*</FormControl>*/}
                  </div>
                  <FormGroup sx={{ mt: "4px", color:"rgb(96,96,96)" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.staySignedIn}
                          onChange={() => {
                            setFieldValue("staySignedIn", !values.staySignedIn);
                          }}
                        />
                      }
                      label="Stay signed in?"
                    />
                  </FormGroup>

                  <Box
                    sx={{ display: "flex", mt: 6, justifyContent: "center" }}
                  >
                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Create My Account
                    </Button>
                  </Box>
                  <Box
                    component="div"
                    mt={3}
                    typography="body2"
                    color="rgba(0,0,0,.4)"
                  >
                    By creating an account, you agree to the Workbox Software
                  </Box>
                  <Box
                    onClick={() => nav("/terms")}
                    component="span"
                    typography="body2"
                    sx={{
                      textDecoration: "underline",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    color="rgba(0,0,0,.4)"
                  >
                    Terms of Service
                  </Box>
                  <Box
                    component="span"
                    typography="body2"
                    color="rgba(0,0,0,.4)"
                  >{` and `}</Box>
                  <Box
                    onClick={() => nav("/privacy")}
                    component="span"
                    typography="body2"
                    sx={{
                      textDecoration: "underline",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    color="rgba(0,0,0,.4)"
                  >
                    Privacy policy.
                  </Box>
                </Paper>
              </Form>
            </div>
          </Container>
        );
      }}
    </Formik>
  );
};
