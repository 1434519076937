import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router } from "react-router-dom";
import { App } from "./App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { LicenseInfo } from "@mui/x-license-pro";
import "./index.css";
import { ErrorBoundaryScreen } from "./components/Errors";
import { HelmetProvider } from "react-helmet-async";
import { Head } from "./components/Head";

LicenseInfo.setLicenseKey(
  "991417e1523cf54e5f18ca7da00b7374Tz02NjQwOSxFPTE3MTU2MjIwMzAwMTMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);
// dotenv.config()
// Initialize app
// initializeApp();
// console.log("auth", auth)

Sentry.init({
  dsn:
    "https://74f5d18a4bc14cfc9aa316247cb66260@o1164001.ingest.sentry.io/6252712",
  integrations: [new BrowserTracing()],
  enabled: process.env.REACT_APP_ENV !== "development",
  environment: process.env.REACT_APP_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  // <ErrorBoundary>

  <ErrorBoundary FallbackComponent={ErrorBoundaryScreen}>
    <Router>
      <HelmetProvider>
        <Head
          keywords={[
            'best billing app for psychotherapists',
            'invoicing app for therapists',
            'best billing app for psychotherapists',
            'save time and money billing patients',
            'easy to use billing app',
          ]}
          title="Workbox Billing App for Therapists"
          description="Best Billing App for Psychotherapists"
        />
        <App />
      </HelmetProvider>
    </Router>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offlin`e and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// reportWebVitals(console.log);
