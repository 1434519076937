import { Box, Button, Container, useTheme } from "@mui/material";
import printJS from "print-js";
import { Page, pdfjs, Document } from "react-pdf/dist/esm/entry.webpack";
import * as React from "react";
import { LoadingScreen } from "../LoadingScreen";
import { useWindowSize } from "hooks";
import { b64toBlob } from "utils";
import { BackButton } from "../Buttons";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps {
  base64Pdf: string | undefined | null;
  allowEmail?: boolean;
  openEmailDialog?: Dispatch<SetStateAction<boolean>>;
}
export const PdfViewer: React.FC<IProps> = ({
  openEmailDialog,
  allowEmail,
  base64Pdf,
}) => {
  const [pdfLoading, setPdfLoading] = React.useState(true);
  const [numPages, setNumPages] = React.useState(0);
  const theme = useTheme();
  const mdWidth = theme.breakpoints.values.md;
  const size = useWindowSize();
  const pageSize = size.fullWidth > mdWidth ? mdWidth : size.fullWidth - 30;

  const contentType = "application/pdf";
  let pdfUrl: string = "";
  if (base64Pdf) {
    const blob = b64toBlob(base64Pdf, contentType);
    pdfUrl = URL.createObjectURL(blob);
  }
  const nav = useNavigate();

  useEffect(() => {
    if (!base64Pdf) {
      nav(-1);
    }
  }, [base64Pdf, nav]);

  const onDocumentLoadSuccess = (pdf: any) => {
    setPdfLoading(false);
    setNumPages(pdf.numPages);
  };

  return (
    <>
      <BackButton />
      <Container maxWidth="lg" sx={{ mt: "10px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent:"center",
            mt: "-10px",
            mb: "30px",
          }}
        >
          <Button
            sx={{ m:"0 20px" }}
            type="button"
            variant="contained"
            onClick={() => printJS(pdfUrl)}
          >
            Print
          </Button>
          {allowEmail && openEmailDialog && (
            <Button
              sx={{ m:"0 20px" }}
              type="button"
              variant="contained"
              onClick={() => openEmailDialog!(true)}
            >
              email...
            </Button>
          )}
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div>
            <LoadingScreen loading={pdfLoading} />
            <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages ? numPages : 1), (_el, index) => (
                <div key={index}>
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={pageSize}
                  />
                  <div
                    style={{
                      backgroundColor: "grey",
                      height: "5px",
                      width: "100%",
                    }}
                  />
                </div>
              ))}
            </Document>
          </div>
        </Box>
      </Container>
    </>
  );
};
