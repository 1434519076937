import AppBar from "@mui/material/AppBar";
// import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Refresh from '@mui/icons-material/Refresh';
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { useCompanyGetter, useNavigatorOnline } from "hooks";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  Theme,
  Tooltip,
} from "@mui/material";
import { SearchField } from "../Search";
import { Logout, Settings } from "@mui/icons-material";
import {useLocation} from "react-router-dom";

interface IUseStyles {
  isOffline: boolean;
  fixedHeader?: boolean;
}
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    grow: {
      flexGrow: 1,
    },
    fullScreen: (props: IUseStyles) => ({
      display: props.isOffline ? "flex" : "none",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100vw",
      height: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: theme.zIndex.drawer + 2,
      backgroundColor: "rgba(0,0,0,.20)",
    }),
    offline: (props: IUseStyles) => ({
      display: props.isOffline ? "block" : "none",
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
      textAlign: "center",
      padding: 20,
      color: theme.palette.secondary.contrastText,
      zIndex: theme.zIndex.drawer + 3,
    }),
    // menuButton: {
    //   marginRight: theme.spacing(2),
    //   [theme.breakpoints.up("lg")]: {
    //     display: "none",
    //   },
    // },
    button: {
      // margin: theme.spacing(1),
      height: "80%",
      marginLeft: "5px",
    },
    title: {
      // display: "none",
      // [theme.breakpoints.up("sm")]: {
      //   display: "block",
      // },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  });
});

type TProps = {
  headerContent?: any;
  title?: string;
  setOpen(open: boolean): void;
};

export function Header(props: TProps) {
  const { headerContent: HeaderContent, title, setOpen } = props;
  const { isOffline } = useNavigatorOnline();
  const _class = useStyles({ isOffline });
  const location = useLocation();
  const invoicing = location.pathname.toLowerCase().includes("create-invoice")
  const company = useCompanyGetter({ bypassError: true });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Profile = () => (
    <Box hidden={true}>
      <Tooltip title="General settings & Log Out">
        <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
          <Avatar sx={{ width: 32, height: 32, color: "black" }}>
            {company.companyName.substr(0, 1)}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem>
          <Avatar /> General Settings
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          General Settings
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );

  return (
    <React.Fragment>
      <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            edge="start"
            sx={{
              display: {
                lg: "none",
              },
            }}
            // className={_class.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography className={_class.title} variant="h6" noWrap>
            {invoicing ? "Invoice" : title ? title : "Workbox"}
          </Typography>

          {HeaderContent && <HeaderContent />}
          <div className={_class.grow} />
          <SearchField />
          <IconButton
            sx={{ml:'10px'}}
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => window.location.reload()}
            size="large">
            <Refresh />
          </IconButton>

          <Profile />
        </Toolbar>
        <div className={_class.offline}>
          <Typography variant="h4">App is off-line!</Typography>
          <Typography variant="h6">
            You'll be able to start working again when you re-connect to the
            internet.
          </Typography>
        </div>
        <div className={_class.fullScreen} />
      </AppBar>
    </React.Fragment>
  );
}
