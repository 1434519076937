// Handles some runtime config options such as active theme

import React, { useEffect, useState } from "react";
import { createTheme, DeprecatedThemeOptions, StyledEngineProvider, Theme, ThemeProvider } from "@mui/material/styles";
import * as locales from "@mui/material/locale";
import { AppContext, LocalStateContextProvider } from "context";
import { defaultThemeOptions } from "theme";
import { computePalette, utils } from "utils";
import { deepmerge } from "@mui/utils";

import { SnackbarProvider } from "notistack";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

type SupportedLocales = keyof typeof locales;

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {

  const [currentIsoDate, setCurrentIsoDate] = useState<string>(
    utils.todayIso()
  );

  const [themeOptions, updateTheme] = useState<DeprecatedThemeOptions>(
    function getInitialState() {
      const lsPrimary = localStorage.getItem("primaryColor");
      const lsSecondary = localStorage.getItem("secondaryColor");
      if (lsPrimary && lsSecondary) {
        const palette = computePalette(lsPrimary, lsSecondary);
        return createTheme(defaultThemeOptions, palette);
      } else {
        return createTheme(defaultThemeOptions);
      }
    }
  );

  // wake up once a minute and check for day change
  // needed to update calendar red highligh for "today".
  // maybe other "today" statistics later on
  useEffect(() => {
    const interval = setInterval(() => {
      const todayIso = utils.todayIso();
      if (todayIso > currentIsoDate) {
        setCurrentIsoDate(todayIso);
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [currentIsoDate]);

  const [locale, updateLocale] = useState<SupportedLocales>("enUS");

  return (
    <AppContext.Provider value={{ updateTheme, updateLocale, currentIsoDate }}>
      <LocalStateContextProvider>
        <StyledEngineProvider injectFirst>
          <SnackbarProvider>
            <ThemeProvider
              theme={createTheme(deepmerge(themeOptions, locales[locale]))}
            >
              {children}
              {/*<SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>*/}
            </ThemeProvider>
          </SnackbarProvider>
        </StyledEngineProvider>
      </LocalStateContextProvider>
    </AppContext.Provider>
  );
};
