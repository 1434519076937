import { Box, Button, useTheme } from "@mui/material";

import { Page, pdfjs, Document } from 'react-pdf/dist/esm/entry.webpack'
import * as React from "react";
import { FC } from "react";
import { useWindowSize } from "hooks";
import printJS from "print-js";
import {BackButton} from "../../components/Buttons";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const DisplayPrivacy: FC = () => {
  const theme = useTheme();
  const mdWidth = theme.breakpoints.values.md;
  const size = useWindowSize();
  const [numPages, setNumPages] = React.useState(1);
  const pageSize = size.fullWidth > mdWidth ? mdWidth : size.fullWidth - 30;

  const onDocumentLoadSuccess = (pdf: any) => {
    setNumPages(pdf.numPages);
  };

  return (
    <>
      <BackButton />
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          mt:"25px"
        }}
      >
        <div>
          <Document
            file="./WorkboxPrivacy.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages ? numPages : 1), (_el, index) => (
              <div key={index}>
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={pageSize}
                />
                <div
                  style={{
                    backgroundColor: "grey",
                    height: "5px",
                    width: "100%",
                  }}
                />
              </div>
            ))}
          </Document>
        </div>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          sx={{ margin: { xs: "25px 0 20px 20px", sm: "25px 0 20px 0" } }}
          type="button"
          variant="contained"
          onClick={() => printJS("./WorkboxPrivacy.pdf")}
        >
          Print
        </Button>
      </Box>

    </>
  );
};
