import {
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useField } from "formik";
import { startCase } from "lodash";
import { Clear } from "@mui/icons-material";
import { utils } from "utils";

interface IName {
  name: string;
  hideClearButton?: boolean;
  overrideInputProps?: object;
}

export const TextInput = (props: TextFieldProps & IName) => {
  const { name, label, helperText } = props;
  const { overrideInputProps, hideClearButton = false, type = "text", ...rest } = props;
  const [field, meta, helpers] = useField(name);
  // console.log("name, field, meta, helpers", name, field, meta, helpers)
  const onBlur = field.onBlur;
  const myLabel = label ? label : startCase(name);
  const errorMsg = meta.error;
  const hasError = Boolean(errorMsg);
  const adjustedType = type && type === "number" && !utils.isTouchDevice ? "text" : type;

  const inputProps = hideClearButton
    ? {} : overrideInputProps ? overrideInputProps
    : {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="Clear text field"
              onClick={() => helpers.setValue("")}
              size="large">
              <Clear color="disabled" fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      };

  return (
    <FormGroup>
      <TextField
        variant="filled"
        {...rest}
        type={adjustedType}
        name={name}
        label={myLabel}
        inputProps={{
          autoComplete: 'one-time-code'
        }}
        onFocus={(event) => {
          event.target.setAttribute('autocomplete', 'off');
        }}
        // autoComplete="one-time-code"
        helperText={errorMsg && meta.touched ? errorMsg : helperText}
        error={hasError && meta.touched}
        value={meta.value}
        onChange={(e) => {
          // numeric keyboard returns number - we want text

          let value = e.target.value;
          if (adjustedType === 'number' && typeof value === 'number') {
            const newValue:number = value
            value = newValue.toString()
          }
          helpers.setValue(value)
        }}
        onBlur={onBlur}
        InputProps={inputProps}
      />
    </FormGroup>
  );
};
