import { BillingDateRangeFilterEnum, IBillingDates } from "../types";
import dayjs from "dayjs";
import {APP_START_DATE} from "./constants";

// removed custom date option from client listing (for now)
export const computeBillingPeriodStart = (): string => {

  return APP_START_DATE;
};

export const computeBillingPeriodEnd = (
  billingDates: IBillingDates
): string => {
  let offset = 0;
  switch (billingDates.billingDateFilter) {
    case BillingDateRangeFilterEnum.PriorMonth:
      offset = -1;
      break;
    case BillingDateRangeFilterEnum.NextMonth:
      offset = 1;
  }
  const endBillingDate = dayjs(billingDates.billingDateBase)
    .add(offset, "month")
    .endOf("month")
    .format("YYYY-MM-DD");

  return endBillingDate;
};
