import { IFormValues, UserEntry } from "./index";
import {
  ClientDashboardDocument,
  ClientsDocument,
  ClientNameDisplay,
  ClientNameSort,
  UserDocument,
  useUpdateUserMutation,
  useUserQuery,
  SessionsDocument,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";
import { IUpdateRet, SaveRetErrorType } from "types";
import { billingDates, localCache } from "../../graphqlDocuments";
import {
  computeBillingPeriodEnd,
  computeBillingPeriodStart,
} from "../../utils";

export const ChangeUserContainer = () => {
  const nav = useNavigate();

  // get most recent record from database
  const { data, loading } = useUserQuery({
    fetchPolicy: "network-only",
    onError: (error1) => {
      throw new Error(error1.message);
    },
  });

  const [updateUser] = useUpdateUserMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: UserDocument,
      },
      {
        query: SessionsDocument,
        variables: { companyId: localCache().companyId },
      },
      {
        query: ClientsDocument,
        variables: { companyId: localCache().companyId },
      },
      {
        query: ClientDashboardDocument,
        variables: {
          companyId: localCache().companyId,
          startDate: computeBillingPeriodStart( ),
          endDate: computeBillingPeriodEnd(billingDates()),
        },
      },
    ],
  });

  const handleSave = async (values: IFormValues): Promise<IUpdateRet> => {
    try {
      const ret = await updateUser({
        variables: {
          ...values,
        },
      });

      const success = ret?.data?.updateUser.success;
      const error = ret?.data?.updateUser.error;

      if (success) {
        return {
          success,
        };
      }

      return {
        success: false,
        errorMessage: error?.message || "Update failed - try again later",
      };
    } catch (e) {
      return {
        success: false,
        errorMessage: "got network error",
        errorType: SaveRetErrorType.Network,
      };
    }
  };

  // avoid warnings - check to make sure all data returned
  const u = data?.user?.user;

  if (!loading) {
    // Query should return 1 row
    if (!data?.user?.user) {
      alert("User profile misssing - call Workbox");
      nav(-1);
      return null;
    }
  }

  // use latest user rec, default back to cached user if still loading
  const initialValues: IFormValues = {
    id: u?.id || "",
    firstName: u?.firstName || "",
    email: u?.email||"",
    lastName: u?.lastName || "",
    clientNameDisplay: u?.clientNameDisplay || ClientNameDisplay.FirstName,
    clientNameSort: u?.clientNameSort || ClientNameSort.FirstName,
    timeZoneId: u?.timeZone?.id || "1",
  };

  // if we're here then we have good session data to edit

  return (
    <UserEntry
      formValues={initialValues}
      loading={loading}
      saveData={handleSave}
    />
  );
};
