import * as React from "react";
import { createTheme, StyledEngineProvider, Theme, ThemeProvider, useTheme } from "@mui/material/styles";
import { Box, Container, Typography } from "@mui/material";
import { defaultThemeOptions } from "../../theme";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface IProps {
  title?: string;
  title2?: string;
  title3?: string;
  children: any;
  maxWidth?: number;
  disableGutters?: boolean;
  sx?:object;
  titleMarginBottom?: string;
}

export const EntryContainer: React.FC<IProps> = (props: IProps) => {
  const theme = useTheme();
  const { maxWidth = 600, disableGutters = false, titleMarginBottom="32px" } = props;

  // const newBreakpointValues = {
  //   ...theme.breakpoints,
  //   values: { ...theme.breakpoints.values, md: maxWidth },
  // };
  // const newTheme = { ...theme, breakpoints: { ...newBreakpointValues } };
  // const customTheme = createTheme(newTheme);

  const newTheme2 = createTheme({
    palette: theme.palette,
    components: defaultThemeOptions.components,
    breakpoints: {
      values: {...theme.breakpoints.values, md:maxWidth }
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={newTheme2}>
        <Container
          sx={{ mb: "75px", ...props.sx }}
          maxWidth="md"
          disableGutters={disableGutters}
        >
          {props.title && (
            <div>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  marginTop: 4,
                  minWidth: 320,
                }}
              >
                {props.title}
              </Typography>
              <Box
                data-testid="invoiceTitle2"
                sx={{
                  textAlign: "center",
                }}
              >
                {props.title2}
              </Box>
              <Box
                data-testid="invoiceTitle3"
                sx={{
                  textAlign: "center",
                  marginBottom: titleMarginBottom,
                }}
              >
                {props.title3}
              </Box>
            </div>
          )}
          {props.children}
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
