import { ActionType, IReducerState } from "./index";
import { useReducer } from "react";


const initialState: IReducerState = {
  dialogOpen: false,
  overrideDialogText:undefined
};

function reducer(state: IReducerState, action: ActionType): IReducerState {
  switch (action.type) {
    case "SET_DIALOG_OPEN": {
      const newState: IReducerState = {
        ...state,
        dialogOpen: true,
        handleOkPressed: action.payload.handleOkPressed,
        handleCancelPressed: action.payload.handleCancelPressed,
        overrideDialogText: action.payload.overrideDialogText
      };
      return newState;
    }
    case "SET_DIALOG_CLOSED": {
      const newState: IReducerState = {
        ...state,
        dialogOpen: false,
      };
      return newState;
    }
  }
  return state;
}

export const useConfirmDialog = () => {
  return useReducer(reducer, initialState);
};
