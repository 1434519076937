import { computeBillingPeriodEnd, computeBillingPeriodStart, EntryMode, toFloat } from "utils";
import { ClientEntryController, IFormValues, initialValues, mapFormValuesToVariables } from "./index";
import {
  ClientDashboardDocument,
  ClientDocument,
  ClientType,
  useClientQuery,
  useUpdateClientMutation
} from "../../generated/graphql";
import { billingDates, localCache } from "../../graphqlDocuments";
import { useParams } from "react-router-dom";
import { IRecordSavedProps, IUpdateRet, RecordSavedSourceEnum, SaveRetErrorType } from "types";
import { DataEntrySkeleton } from "../Skeleton";
import { GraphQLErrorScreen } from "../Errors";

interface IProps {
  recordSaved?: (args: IRecordSavedProps) => void;
}

export const ChangeClientContainer = (props: IProps) => {

  let { clientId } = useParams();

  const { data, loading, error } = useClientQuery({
    variables: { companyId: localCache().companyId, clientId: clientId! },
    skip: !clientId,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });

  const [updateClient] = useUpdateClientMutation({
    refetchQueries: [
      {
        query: ClientDocument,
        variables: { companyId: localCache().companyId, clientId },
      },
      {
        query: ClientDashboardDocument,
        variables: {
          companyId: localCache().companyId,
          clientId: clientId,
          startDate: computeBillingPeriodStart(),
          endDate: computeBillingPeriodEnd(billingDates()),
        },
      },
    ],
  });

  if (error) {
    return <GraphQLErrorScreen error={error} />
  }


  if (!loading && !data?.client ) {
    throw new Error("client read failed")
  }

  const handleSave = async (values: IFormValues): Promise<IUpdateRet> => {
    try {
      const variables = {
        ...mapFormValuesToVariables(values),
        clientId: clientId!,
        companyId: localCache().companyId,
        openingBalance: values.openingBalance
          ? toFloat(values.openingBalance)
          : undefined,
      };

      const ret = await updateClient({
        variables,
      });

      const retClient = ret?.data?.updateClient?.client;
      const retErrors = ret?.data?.updateClient?.errors;

      // check for errors or invalid data
      if (!retErrors && retClient) {
        if (props.recordSaved) {
          const snackbarDescription = `Client Updated: ${retClient.displayName}`;
          props.recordSaved({
            source: RecordSavedSourceEnum.client,
            id: retClient?.id,
            clientId: retClient?.id,
            snackbarDescription,
            optionsDescription: retClient.displayName,
          });
        }
        return { success: true };
      }

      // this shouldn't happen - but check just in case
      if (retErrors?.length === 0) {
        throw new Error("Invalid return from save client");
      }

      const err = retErrors ? retErrors[0] : { message: "Errors Found" };
      return {
        success: false,
        errorMessage: err.message,
      };
    } catch (e:any) {
      return {
        success: false,
        errorMessage: e.message||"got network error",
        errorType: SaveRetErrorType.Network,
      };
    }
  };

  const client = data?.client;
  const openingBalance = data?.invoiceOpeningBalance.openingBalance;

  if (loading) {
    return <DataEntrySkeleton title={`Update Client`} />;
  }
  const values: IFormValues = loading
    ? initialValues
    : {
      clientType: client ? client.clientType : ClientType.Individual,
      firstName: client?.firstName || "",
      lastName: client?.lastName || "",
      email: client?.email || "",
      firstName2: client?.firstName2 || "",
      lastName2: client?.lastName2 || "",
      email2: client?.email2 || "",
      companyName: client?.companyName || "",
      abbrevName: client?.abbrevName || "",
      street1: client?.street1 || "",
      street2: client?.street2 || "",
      city: client?.city || "",
      state: client?.state || "",
      zip: client?.zip || "",
      country: client?.country || "",
      attention: client?.attention || "",
      invoiceHeaderText: client?.invoiceHeaderText || "",
      openingBalance: openingBalance?.toString() || "",
      salutation: client?.salutation || "",
      otherInfo: client?.otherInfo || "",
      sendPaymentReceipt: client?.sendPaymentReceipt || false,
      serviceFees:
        client?.clientServices && client.clientServices.length
          ? client.clientServices.map((cs) => {
            return {
              service: {
                id: cs.serviceId,
                description: `${cs.cptCode} ${cs.description}`,
              },
              sessionFee: cs.sessionFee.toString(),
            };
          })
          : [{ service: null, sessionFee: "" }],
    };

  return (
    <ClientEntryController
      mode={EntryMode.CHANGE}
      initialValues={values}
      loading={loading}
      saveData={handleSave}
    />
  );
};
