import { FC } from "react";
import {
  CompanyDocument,
  useCountriesQuery,
  useTimeZonesQuery,
  useUpdateCompanyMutation, useUserQuery,
} from "../../generated/graphql";
import { localCache } from "../../graphqlDocuments";
import { IFormValues } from "./shared";
import { IUpdateRet, SaveRetErrorType } from "../../types";
import { OnboardingEntry1 } from "./index";
import { LoadingScreen } from "../../components/LoadingScreen";
import {useCompanyGetter} from "../../hooks";
import { GraphQLErrorScreen } from "../../components/Errors";

export const OnboardingContainer1: FC = () => {
  const { data: timeZones, error } = useTimeZonesQuery({
    fetchPolicy: "cache-only",
  });

  const { data: countries } = useCountriesQuery({
    fetchPolicy: "cache-only",
  });

  const {data: userData, loading:userLoading, error:userError} = useUserQuery();

  const [updateCompany, { loading }] = useUpdateCompanyMutation();

  const {countryId, invoiceBodyDateFormat, invoiceEmailDateFormat} =  useCompanyGetter();

  if (loading || userLoading) {
    return <LoadingScreen loading={loading} />;
  }

  if (error) {
    return <GraphQLErrorScreen error={error} />
  }
  if (userError) {
    return <GraphQLErrorScreen error={userError} />
  }
  const handleSave = async (values: IFormValues): Promise<IUpdateRet> => {
    try {
      // const companyId = localCache().companyId;
      // check for non-nullable fields
      if (!values.name) {
        throw new Error("saving session: missing company name");
      }

      let retData;
      let retError;
      const ret = await updateCompany({
        refetchQueries: [
          {
            query: CompanyDocument,
            variables: { companyId: localCache().companyId },
          },
        ],
        variables: {
          ...values,
        },
      });
      retData = ret.data?.updateCompany;
      retError = ret.data?.updateCompany?.error;

      if (!retError && retData) {
        return {
          success: true,
        };
      }

      return {
        success: false,
        errorMessage: retError?.message || "Update failed - try again later",
      };
    } catch (e) {
      return {
        success: false,
        errorMessage: "got network error",
        errorType: SaveRetErrorType.Network,
      };
    }
  };


  const timeZone = userData?.user?.user?.timeZone;

  //default to user's full name
  const name = userData ? `${userData.user?.user?.firstName} ${userData.user?.user?.lastName}` : '';

  const initialValues:IFormValues = {
    name,
    countryId,
    timeZoneId:timeZone?.id||"1",
    companyId: localCache().companyId,
    invoiceBodyDateFormat,
    invoiceEmailDateFormat
  }

  return (
    <OnboardingEntry1
      countries={countries}
      timeZones={timeZones}
      saveData={handleSave}
      initialValues={initialValues}
    />
  );
};
