import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  // Card,
  // CardContent,
  // Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  // Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";

import { startCase } from "lodash";
import { utils } from "utils";
import { IMuiSelect } from "types";
import React, { useEffect } from "react";
import {ErrorMessage, useField} from "formik";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 40,
  },
  heading: {
    textAlign: "center",
    marginBottom: 10,
  },
  mobileSelect: {
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
  },
  mailingAddress: {
    marginTop: 20,
  },
  autocomplete: {
    flexGrow: 1,
  },
  mobileSelectService: {
    width: "100%",
    textAlign: "left",
  },
  newSessionButton: {
    height: "54px",
    alignSelf: "flex-start",
    marginTop: "16px",
    minWidth: "110px",
  },
  newSessionIcon: {
    height: "54px",
    alignSelf: "flex-start",
    marginTop: "16px",
    display: "inherit",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  actions: {
    justifyContent: "space-between",
  },
  newSessionOr: {
    margin: "33px 9px 33px 9px",
    flexGrow: 0,
  },
  autocompleteFormControl: {
    width: "100%",
    margin: 0,
  },
  divider: {
    backgroundColor: "lightGrey",
    margin: theme.spacing(2),
  },
  panelTitle: {
    marginBottom: 10,
  },
  service: {
    flexGrow: 1,
  },
  arrow: {
    position: "relative",
    right: 12,
  },
  formControl: {
    width: "100%",
  },
  newButton: {
    marginLeft: "15px",
    width: "110px",
    height: "56px",
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
}));

interface IProps {
  name: string;
  options: Array<IMuiSelect>;
  mobileSearchLabel: string;
  label?: string; // optional override to name
  addNewItemLabel?: string;
  onShowAddScreen: () => void;
  helperText?: string;
  onItemSelected?: (id: string) => void;
  disableClearable?:boolean
  hideAddNewButton?: boolean
}

export function SelectItem(props: IProps) {
  const {
    name,
    options,
    mobileSearchLabel,
    onShowAddScreen,
    addNewItemLabel,
    helperText,
    disableClearable=false,
    hideAddNewButton=false,
  } = props;

  const label = props.label ? props.label : startCase(name);
  const addLabel = addNewItemLabel ? addNewItemLabel : label;
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState<string>("");
  const classes = useStyles();
  const [, meta, helper] = useField(name);

  const errorMsg = meta.error;


  const hasError = Boolean(errorMsg);
  // console.log("errormsg, touched, onblur", errorMsg, meta.touched, onBlur)
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      setSearchText("");
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleItemSelected = (item: IMuiSelect) => {
    helper.setValue(item);
    if (props.onItemSelected) {
      props.onItemSelected(item.id);
    }
    handleClose();
  };

  const filteredList = !searchText
    ? options
    : options.filter((item) => {
      return item.description
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  return (
    <div data-testid={name}>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="disabled" tabIndex={-1} />
              </InputAdornment>
            ),
          }}
          value={searchText}
          onChange={(e) => {
            debugger
            setSearchText(e.target.value);
          }}
          placeholder={mobileSearchLabel}
        />
        <DialogContent dividers={true} style={{ minHeight: "50vh" }}>
          {open && (
            <List>
              {options && filteredList.length > 0 ? (
                filteredList?.map((item, idx) => {
                  return (
                    <ListItem
                      divider={true}
                      key={idx}
                      onClick={() => handleItemSelected(item)}
                    >
                      <ListItemText primary={item.description} />
                    </ListItem>
                  );
                })
              ) : (
                <div>
                  Sorry - you haven't entered any {addLabel}s yet. Please
                  click on the Add New {addLabel} button below to add your first{" "}
                  {addLabel} record.
                </div>
              )}
            </List>
          )}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="outlined" size="medium" onClick={onShowAddScreen}>
            add new {addLabel}
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {utils.isTouchDevice ? (
        // <TextField
        //   className={classes.mobileSelect}
        //   InputProps={{
        //     readOnly: true,
        //     endAdornment: (
        //       <InputAdornment position="end">
        //         <ArrowForwardIcon
        //           className={classes.arrow}
        //           color="disabled"
        //           tabIndex={-1}
        //           fontSize="small"
        //         />
        //       </InputAdornment>
        //     ),
        //   }}
        //   onClick={() => setOpen(true)}
        //   value={meta?.value?.description || ""}
        //   label={label}
        //   onBlur={() => helper.setTouched(true)}
        //   helperText={errorMsg && meta.touched ? errorMsg : helperText}
        //   error={hasError && meta.touched}
        // />
        <>
          <List dense={true} sx={{ padding: 0 }}>
            <ListItem
              disablePadding
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="view list"
                  onClick={() => setOpen(true)}
                  size="large">
                  <ArrowForwardIcon fontSize="small"/>
                </IconButton>
              }
            >
              <ListItemButton sx={{ padding: "5px 11px",borderRadius:"5px 5px 0 0", backgroundColor:"rgb(241,241,241)", minHeight:"56px" }} onClick={() => setOpen(true)}>
                <ListItemText
                  secondaryTypographyProps={{
                    variant:"body1",
                    style: {
                      // fontWeight: 600,
                      color: meta.error && meta.touched ? "#e53935" :  "rgba(0,0,0,.6)",
                      fontSize: meta.value ? "inherit" : "1rem",
                      letterSpacing: ".00938em",
                      lineHeight: "1.4375em",
                      fontWeight:400,
                    },
                    // style: {
                    //   // color:" #3f51b5",
                    //   fontWeight:500,
                    //   color:"black",
                    //   opacity:1
                    // }
                  }}
                  primaryTypographyProps={{
                    variant:meta.value ? "h6" : undefined,
                    style: {
                      // fontWeight: 600,
                      color: meta.error && meta.touched ? "#e53935" : "rgba(0,0,0,.6)",
                      fontSize: meta.value ? ".8rem" : "1rem",
                      letterSpacing: ".00938em",
                      lineHeight: "1.4375em",
                      fontWeight:400,
                    },
                  }}
                  primary={`${props.label} ${meta?.value?.description ? '' : ''}`}
                  secondary={
                    meta?.value?.description || "(or add new...)"
                  }
                  // secondary={secondary ? 'Secondary text' : null}
                />
              </ListItemButton>
            </ListItem>
          </List>
          <ErrorMessage
            name={props.name}
            render={(msg) => (
              <div
                style={{
                  fontSize: ".75rem",
                  lineHeight: 1.66,
                  margin: "3px 14px 0 14px",
                  color: "#e53935",
                }}
              >
                {msg}
              </div>
            )}
          />

        </>
      ) : (
        <div style={{ display: "flex" }}>
          <Autocomplete
            // id="combo-box-demo"
            disableClearable={disableClearable}
            options={options}
            // size="small"
            className={classes.autocomplete}
            getOptionLabel={(option) => option.description}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id}>
                {option.description}
              </Box>
            )}

            noOptionsText={
              options && options?.length > 0 ? (
                `${addLabel} not found.  Click the Add New ${addLabel} button on the right to add it.`
              ) : (
                <Box color="info.main">
                  Oops! You haven't entered any {addLabel} codes yet. Click the
                  New {addLabel} button on the right to add your first one!{" "}
                </Box>
              )
            }
            onChange={(_: any, newValue: IMuiSelect | null) => {
              if (props.onItemSelected && newValue) {
                props.onItemSelected(newValue.id);
              }
              helper.setValue(newValue);
            }}
            value={meta.value}
            renderInput={(params) => (
              <FormControl className={classes.autocompleteFormControl}>
                <TextField
                  {...params}
                  name={name}
                  label={label}
                  variant="filled"
                  fullWidth={true}
                  onBlur={() => helper.setTouched(true)}
                  error={hasError && meta.touched}
                  helperText={errorMsg && meta.touched ? errorMsg : helperText}
                />
              </FormControl>
            )}
          />
          <Button
            sx={{ marginLeft: 2, padding:0, width: "116px", height:"56px", display: hideAddNewButton ? "none" : undefined }}
            tabIndex={-1}
            onClick={onShowAddScreen}
            // className={classes.newButton}
            type="button"
            size="small"
            variant="outlined"
            fullWidth={false}
          >
            or add new {addLabel}...
          </Button>
        </div>
      )}
    </div>
  );
}
