// import dayjs from "dayjs";

const FMT_SHORT = new Intl.DateTimeFormat();

// const medOptions:Intl.DateTimeFormat = {
//   weekday: "short",
//   year: "numeric",
//   month: "short",
//   day: "numeric",
// };
const FMT_MED = new Intl.DateTimeFormat(undefined, {weekday:'short', year:'numeric', month:"short", day:"numeric"});

class IsoDate {
  private readonly myIsoDate: string = "";
  private readonly myDate: Date;

  constructor(date?: Date | string) {
    if (!date) {
      this.myDate = new Date();
      this.myIsoDate = this.myDate.toISOString().substring(0, 10);
    } else if (date instanceof Date) {
      this.myDate = date;
      this.myIsoDate = date.toISOString().substring(0, 10);
    } else if (typeof date === "string") {
      const d = new Date(date);
      if (d instanceof Date && !isNaN(d.getTime())) {
        this.myIsoDate = d.toLocaleDateString().substring(0, 10);
        this.myDate = d;
      } else {
        throw new Error(`Invalid ISO Date ${date}`);
      }
    } else {
      throw new Error(`Invalid ISO Input Date ${date}`);
    }
  }

  public isoDate() {
    return this.myIsoDate;
  }

  public toDate(): Date {
    return this.myDate;
  }
  public formattedShort(): string {
    const ret = FMT_SHORT.format(this.myDate);
    return ret;
  }

  public formattedMedium() {
    const ret = FMT_MED.format(this.myDate);
    return ret;
  }
}

export { IsoDate };

/*
class CategoryController {
  private request: Request

  constructor(request: Request) {
    this.request = request
  }

  public async getCategoryById(id: number) {
    const categoryModel = new CategoryModel()
    return await categoryModel.getCategoryById(id)
  }

  public async getCategoryList(company: number) {
    const categoryModel = new CategoryModel()
    return await categoryModel.getCategoryList(company)
  }
}

export { CategoryController }
 */
