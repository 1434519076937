import {IFormValues, ServiceEntry} from "./index";
import {useCreateServiceMutation} from "generated/graphql";
import {localCache} from "../../graphqlDocuments";
import {useNavigate} from "react-router-dom";
import {IRecordSavedProps, IUpdateRet, RecordSavedSourceEnum, SaveRetErrorType} from "../../types";
import {EntryMode, utils} from "../../utils";

interface IProps {
  recordSaved: (args: IRecordSavedProps) => void;
}

const initialValues: IFormValues = {
  serviceDescription: "",
  serviceCptCode: "",
};

export const AddServiceContainer: React.FC<IProps> = (props) => {
  const nav = useNavigate();

  const [createService] = useCreateServiceMutation({
    // onCompleted: (data) => {
    //   const service = data.createService;
    //   const snackbarDescription = `Service Added: ${service.description}`;
    //   props.recordSaved({
    //     source:RecordSavedSourceEnum.service,
    //     id: service.id,
    //     optionsDescription: utils.formatService(
    //       service.description,
    //       service.cptCode
    //     ),
    //     snackbarDescription,
    //   });
    //   nav(-1);
    // },
  });

  const handleCancel = () => {
    nav(-1);
  };

  const handleSave = async (values: IFormValues): Promise<IUpdateRet> => {
    try {
      const companyId = localCache().companyId;
      if (!values.serviceDescription) {
        return {
          success: false,
          errorMessage: "Please enter Service Description",
        };
      }
      const ret = await createService({
        variables: {
          description: values.serviceDescription,
          cptCode: values.serviceCptCode,
          companyId: companyId,
        },
        update: (cache, { data }) => {
          if (data?.createService) {
            const cacheId = cache.identify(data?.createService);
            if (!cacheId) {
              throw new Error("CreateService: Invalid Cache ID");
            }
            cache.modify({
              fields: {
                services: (existingFieldData, { toReference }) => {
                  return [...existingFieldData, toReference(cacheId)];
                },
              },
            });
          }
        },
      });
      const retService = ret?.data?.createService;

      // if all ok, then return success and invoke recordSaved callback
      if (retService) {
        if (props.recordSaved) {
          const snackbarDescription = `Service Added: ${retService.description}`;
          props.recordSaved({
            source: RecordSavedSourceEnum.service,
            id: retService?.id,
            snackbarDescription,
            optionsDescription: utils.formatService(retService.description, retService.cptCode)
          });
        }
        nav(-1);
        return { success: true };
      }

      return {
        success: false,
        errorMessage: "Error encountered during update",
      };
    } catch (e) {
      return {
        success: false,
        errorMessage: "got network error",
        errorType: SaveRetErrorType.Network,
      };
    }
  };

  return (
    <ServiceEntry
      initialValues={initialValues}
      handleCancel={handleCancel}
      handleSave={handleSave}
      mode={EntryMode.ADD}
    />
  );
};
