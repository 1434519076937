import palette from "./palette";
import { ThemeOptions } from "@mui/material";

export const defaultThemeOptions: ThemeOptions = {
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 475,
      md: 768,
      lg: 1040,
      xl: 1280,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "filled"
      }
    }
  }
};
