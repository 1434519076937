export enum ThemeTypeEnum {
  useDefault,
  usePreset,
  custom,
}

export interface IThemeInfo {
  themeType: ThemeTypeEnum
  presetIdx: number
  customPrimary: string
  customSecondary: string
}
