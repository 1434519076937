import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);

const nineMonthsAgo = dayjs().subtract(9, "months");

// if format populated, use it for anything
// not "today" or "yesterday".  If not populated,
// use pure relative dates.
interface IFancyDate {
  isoDate: string,
  format?: string
}


export const fancyFormattedDate = (props: IFancyDate) => {
  const {isoDate, format = "MMM Do"} = props;
  const dayjsDate = dayjs(isoDate);
  if (dayjsDate.isToday()) {
    return "Today";
  } else if (dayjsDate.isYesterday()) {
    return "Yesterday";
  } else if (dayjsDate.isAfter(nineMonthsAgo)) {
    return dayjsDate.format(format);
  } else {
    return dayjs(isoDate).fromNow()
  }
}

