// export type ACTION_FILENAME = "SESSION" | "CLIENT" | "SERVICE";

export enum ActionFileNameEnum {
  Session = "Session",
  Client = "Client",
  Service = "Service",
}

export type MasterActionType = {
  type: "RECORD_ADDED";
  payload: {
    fileName: ActionFileNameEnum;
    id: string;
    description: string;
    toastDescription: string;
  };
};

