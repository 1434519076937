import { useNavigate } from "react-router-dom";
import { ApolloError } from "@apollo/client/errors";
import { Box, Button } from "@mui/material";
import { AuthenticationService } from "services";
import { FC, useEffect } from "react";

interface IProps {
  error: ApolloError;
  signOut?: boolean;
}

export const GraphQLErrorScreen: FC<IProps> = (props) => {

  const { graphQLErrors, networkError } = props.error;
  // const location: Location = useLocation();
  const nav = useNavigate();
  let needSignout = false;

  let errMsg1 = "An error has occurred";
  let errMsg2 = "We've been notified and will look into this right away.";

  console.log("graphql error", graphQLErrors, networkError);

  if (networkError?.message.includes("401")) {
    errMsg1 = "You are not authorized for this transaction";
    errMsg2 = "Please sign in again";
    needSignout = true;
  } else if (networkError) {
    errMsg1 = "A network error has occurred";
    errMsg2 = "Please try transaction again";
  } else {
    needSignout = true;
  }

  if (props.signOut) {
    needSignout = true;
  }

  const ErrorDetail = () => {
    return (
      <div>
        <div>Detail information:</div>
        <pre>
          {props.error.graphQLErrors.map(
            ({ message }: { message: string }, i: number) => (
              <span key={i}>{message}</span>
            )
          )}
        </pre>
        <div>
          {props.error.networkError
            ? `Network error: ${props.error.networkError.message} `
            : ""}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (needSignout) {
      AuthenticationService.signOut();
      window.alert(errMsg1);
    }
  }, [needSignout, errMsg1]);

  const handleClick = () => {
    nav("/home");
  };

  return (
    <div>
      <Box
        height="100vh"
        width="100vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: "white", padding: "20px" }}
      >
        <Box display="flex" flexDirection="column">
          <Box
            style={{ justifySelf: "center", fontSize: "100px", color: "red" }}
          >
            {"\u26A0"}
          </Box>
          <div> graphql error screen</div>
          <Box mt="20px" typography="h4">
            Oops!!
          </Box>
          <Box mt="20px" typography="h5">
            {errMsg1}
          </Box>
          <Box mt="20px" typography="h5">
            {errMsg2}
          </Box>
          <ErrorDetail />
          <Button
            onClick={handleClick}
            size="large"
            type="submit"
            variant="contained"
            sx={{ maxWidth: "250px", mt: "40px" }}
          >
            Continue
          </Button>
        </Box>
      </Box>

    </div>
  );
};

/*export const GraphQLErrorScreen = (props: any) => {
  const err = props.error
  if (!err) {
    return <div>An error has occurred</div>
  }


}
*/
