import * as React from "react";
import Button from "@mui/material/Button";
import {ArrowBack} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

interface IProps {
  onBackButtonClicked?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  label?: string;
  hidden?: boolean;
  position?: "absolute"
}

export const BackButton: React.FC<IProps> = (props: IProps) => {

  const { label, onBackButtonClicked, hidden = false, position = "relative" } = props;

  const nav = useNavigate();

  if (hidden) {
    return <div style={{ position: "relative", top: "20px" }} />;
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onBackButtonClicked) {
      onBackButtonClicked(e);
    } else {
      nav(-1);
    }
  };

  return (
    <Button
      sx={{
        left: 20,
        top: 20,
        position
      }}
      autoFocus={true}
      disableFocusRipple={true}
      color="primary"
      type="button"
      startIcon={<ArrowBack />}
      onClick={handleClick}
    >
      {label ? label : "Back"}
    </Button>
  );
};
