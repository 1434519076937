import React from 'react'
import {useReducer} from 'react'
// const { useReducer } = React

export interface ILocalState {
  currentCompanyId: number
  currentStaffId: number
  currentUserId: number
  currentCompanyName: string
}

export const initialState: ILocalState = {
  currentCompanyId: 0,
  currentStaffId: 0,
  currentUserId: 0,
  currentCompanyName: ' ',
}

export type ActionType =
  | { type: 'SET_LOCAL_STATE'; payload: ILocalState }
  | { type: 'CLEAR_LOCAL_STATE' }

// interface IContextProps {
//   state: ILocalState
//   dispatch: ({ type }: { type: string }) => void
// }

// export const LocalStateContext = createContext <[IState, React.Dispatch<ActionType>] >(null)>;
// export const LocalStateStore = React.createContext({} as IContextProps)
export const LocalStateContext = React.createContext<
  [ILocalState, React.Dispatch<ActionType>]
  >([initialState, ({ type }: { type: string }) => {
  console.log("type", type)
}])

export function reducer(state: ILocalState, action: ActionType): ILocalState {
  switch (action.type) {
    case 'SET_LOCAL_STATE':
      return { ...state, ...action.payload }
    case 'CLEAR_LOCAL_STATE':
      return initialState
    default:
      return state
  }
}

export const LocalStateContextProvider = (props: any) => {
  const [state, dispatch] = useReducer<React.Reducer<ILocalState, ActionType>>(reducer, initialState)

  return (
    <LocalStateContext.Provider value={[state, dispatch]}>
      {props.children}
    </LocalStateContext.Provider>
  )
}
