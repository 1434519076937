import {Box, Button} from "@mui/material";
import img404 from "./magnifying-404.png";

export function NoMatchRoute() {
  var getUrl = window.location;
  const url = getUrl.protocol + "//" + getUrl.host
  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: "white" }}
    >
      <Box display="flex" flexDirection="column">
        <img
          alt="404 error.  route not found"
          style={{ objectFit: "contain",justifySelf: "center", maxHeight: "225px" }}
          src={img404}
        />
        <Box mt="20px" typography="h4">Oops!!</Box>
        <Box mt="20px" typography="h5">Something went wrong...</Box>
        <Button onClick={() =>window.location.href = `${url}/home`}size="large" type="submit" variant="contained" sx={{maxWidth:"250px", mt:"40px"}}>Return to Home screen</Button>
      </Box>
    </Box>
  );
}
