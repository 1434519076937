import React, { Dispatch } from "react";
interface ISearchContextType {
  searchText: string;
  setSearchText?: Dispatch<React.SetStateAction<string>>;
}

const initialState: ISearchContextType = {
  searchText: "",
};
export const SearchContext = React.createContext<ISearchContextType>(initialState);

type TProps = {
  children: JSX.Element;
};

export function SearchContextProvider({ children }: TProps) {
  const [searchText, setSearchText] = React.useState("");

  return (
    <SearchContext.Provider
      value={{
        searchText,
        setSearchText,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}
