import React, { useState, useEffect } from 'react'
import { utils } from 'utils'
import { throttle } from 'lodash'
import { useTheme } from '@mui/material/styles';

export const useWindowSize = () => {
  const theme = useTheme();
  const [windowSize, setWindowSize] = useState(getSize());

  const tmpFunction = throttle(handleResize, 1000, true, true)
  const func = React.useRef(tmpFunction);

  function getSize() {
    const isTouchDevice = Boolean(utils.isTouchDevice);

    let width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
    let height = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;

    const fullWidth = width;

    if (width > theme.breakpoints.values.lg) {
      width -= 240;
    }

    // console.log("heigh/width", height, width)

    const isLandscape = isTouchDevice && (height < width)

    return {
      height,
      width,
      fullWidth,
      isTouchDevice,
      isLandscape
    };
  }

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    const localFuncCurrent = func.current;
    window.addEventListener('resize', localFuncCurrent);
    return () => {
      window.removeEventListener('resize',localFuncCurrent)
    };
  }, []);

  return windowSize;
}
