import { MainContainer } from "components/Main";
import React, { useContext, useEffect } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import "firebase/auth";
import { LoadingScreen } from "../components/LoadingScreen";
import { AuthenticationContext } from "../context/AuthenticationProvider";
import { FailedScreen } from "components/Startup";
import { AuthStatus } from "../types";
import { useCompanyGetter } from "../hooks";
import { StripeStatusEnum } from "../generated/graphql";
import dayjs from "dayjs";
import { getAnalytics, logEvent } from "firebase/analytics";
import { localCache } from "../graphqlDocuments";
import { AuthenticationService } from "../services";

/**
 * Component to allow authenticated access only
 */

interface IProps {
  children: React.ReactNode;
  title: string;
}

function delay(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const PrivateRoute2: React.FC<IProps> = (props: IProps) => {
  const { children, title } = props;
  const loc = useLocation();
  const company = useCompanyGetter();

  const analytics = getAnalytics();
  logEvent(analytics, "user_engagement", {
    companyId: localCache().companyId,
    title: title||'no titlexs'
  });

  const [searchParams] = useSearchParams({});

  const { authStatus, setAuthStatus } = useContext(AuthenticationContext);

  // if user is not signed-in, but goes to a private route, wait 5 seconds
  // for firebase to try and re-establish connection.  If after that time,
  // it's still not logged in, then go to sign-in screen.  This really
  // shouldn't happen much. Maybe user saved a short cut or something.
  useEffect(() => {
    let currentUser = AuthenticationService.getFirebaseAuthentication()
      .currentUser?.uid;
    if (currentUser) {
      return;
    }
    delay(5000).then(() => {
      currentUser = AuthenticationService.getFirebaseAuthentication()
        .currentUser?.uid;
      if (!currentUser && authStatus === AuthStatus.begin) {
        setAuthStatus(AuthStatus.needSignIn);
      }
    });
  }, [authStatus, setAuthStatus]);


  if (searchParams.has("last-path")) {
    console.log("auth status", authStatus)
    const lastPath = localStorage.getItem("lastPath")  ;
    if (lastPath) {
      return <Navigate to={lastPath} replace={true} />;
    }
  }

  if (
    authStatus === AuthStatus.begin ||
    authStatus === AuthStatus.needMeData ||
    authStatus === AuthStatus.needStartup
  ) {
    return (
      <MainContainer title={title}>
        <LoadingScreen />
      </MainContainer>
    );
  }

  // firebase initialization finished and determined that user not logged in
  if (authStatus === AuthStatus.needSignIn) {
    const nextPath =
      loc.pathname.substr(loc.pathname.length - 1, 1) === "*"
        ? loc.pathname.slice(0, -1)
        : loc.pathname;
    const signInPath = `/sign-in?next=${nextPath}`;
    return <Navigate to={signInPath} replace={true} />;
  }

  // will happen if user is removed from their only active company.
  if (authStatus === AuthStatus.missingCompanyAssignment) {
    return (
      <FailedScreen
        error={"Sorry - you do not have access any active businesses"}
      />
    );
  }

  // shouldn't happen - not allowing multiple company assignments yet
  if (authStatus === AuthStatus.selectCompany)
    return (
      <FailedScreen
        error={
          "Access to more than one company not allowed - please call support"
        }
      />
    );
  //

  if (!loc.pathname.startsWith("/subscription-status")) {
    if (company.stripeStatusEnum === StripeStatusEnum.Trialing) {
      const currentFormattedDate = dayjs().format("YYYY-MM-DD")
      if (currentFormattedDate > company.trialEndDate) {
        return <Navigate to="/subscription-status" replace={true} />;
      }
    } else if (company.stripeStatusEnum !== StripeStatusEnum.Active) {
      return <Navigate to="/subscription-statusl" replace={true} />;
    }
  }

  return (
    <MainContainer title={title}>
      <React.Fragment>{children}</React.Fragment>
    </MainContainer>
  );
};

export { PrivateRoute2 };
