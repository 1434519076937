import { IFormValues } from "./index";
import React from "react";
import { findIndex, isEmpty, isEqual } from "lodash";
import { AlertDialog, IAlertProps } from "components/Alert";
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikValues,
} from "formik";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import { LoadingScreen } from "../LoadingScreen";
import { TextInput } from "../TextInput";
import { EntryContainer } from "../Containers";
import { IUpdateRet, SaveRetErrorType } from "../../types";
import { useTimeZonesQuery } from "generated/graphql";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { SubmitButton } from "../Buttons";
import { useTopOfForm } from "../../hooks";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 5,
    [theme.breakpoints.up("md")]: {
      marginTop: 15,
    },
  },
  button: {
    margin: theme.spacing(1),
    fontSize: ".95rem",
  },
  divider: {
    backgroundColor: "lightGrey",
    margin: theme.spacing(2),
  },
  alert: {
    zIndex: 999999,
  },
  centered: {
    textAlign: "center",
    width: "100%",
  },
  submit: {
    margin: "30px 15px 20px auto",
    display: "block",
    width: 120,
  },
  menuItem: {
    whiteSpace: "break-spaces",
  },
  fieldWrapper: {
    marginTop: 7,
    "& > div": {
      marginBottom: "16px",
      "&:last-child": {
        marginBottom: 1,
      },
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 3,
    backgroundColor: "transparent",
    position: "fixed",
    top: 0,
    right: 0,
    // width: props.searchEnabled ? "calc(100vw - 45px)" : "calc(100vw - 150px)",
    width: "calc(100vw - 45px)",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      paddingRight: 20,
    },
  },
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "5px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    margin: "0 auto 30px auto",
    height: 90,
    width: 200,
    transition: "border .24s ease-in-out",
    "&:hover": {
      cursor: "pointer",
    },
  },
  paper: {
    padding: 30,
  },
  sample: {
    marginTop: 15,
  },
  name: {
    width: "100%",
    textAlign: "center",
    marginBottom: 30,
    position: "relative",
  },
  grow: {
    flexGrow: 1,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  editButton: {
    position: "absolute",
    top: 0,
    right: 0,
    display: "block",
  },
  img: {
    maxHeight: 200,
    maxWidth: 200,
  },
  formControl: {
    width: "100%",
  },
  dialogPaper: {
    backgroundColor: theme.palette.background.default,
  },
  imageContainer: {
    justifyContent: "center",
    marginBottom: 25,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

interface IProps {
  formValues: IFormValues;
  loading: boolean;
  saveData: (values: IFormValues, file?: File) => Promise<IUpdateRet>;
}

// const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }: { theme: Theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: "#f5f5f9",
//     color: "rgba(0, 0, 0, 0.87)",
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid #dadde9",
//   },
// }));

interface ISnackbar {
  show: boolean;
  description?: string;
}
export const UserEntry: React.FC<IProps> = ({
  formValues,
  saveData,
  loading,
}) => {
  const [alertArgs, setAlertArgs] = React.useState<IAlertProps>({
    open: false,
    message: "",
    title: "",
  });

  const [snackbarArgs, setSnackbarArgs] = React.useState<ISnackbar>({
    show: false,
  });

  const classes = useStyles();

  useTopOfForm()

  const { data: timeZones } = useTimeZonesQuery({
    fetchPolicy: "cache-only",
  });

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<IFormValues> = {};

    if (!values.firstName) {
      errors.firstName = "Enter First Name";
    }

    if (!values.lastName) {
      errors.lastName = "Enter Last Name Name";
    }
    return errors;
  };

  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    formikHelpers.setSubmitting(true);

    const ret = await saveData(values);
    formikHelpers.setSubmitting(false);
    if (!ret.success) {
      if (ret.errorType === SaveRetErrorType.Network) {
        setAlertArgs({
          open: true,
          title: "Network Error",
          message: "Please re-save after internet connection restored",
        });
      } else {
        setAlertArgs({
          open: true,
          title: "Errors Found - please correct",
          message: ret.errorMessage,
        });
      }
    } else {
      setSnackbarArgs({
        show: true,
        // title: "Network Error",
        description: "User Profile Update Successful",
      });
    }
  };

  const errorComponent = (ret: object) => {
    return (
      <ul>
        {Object.entries(ret).map(([, value], idx) => (
          <li key={idx}>{value}</li>
        ))}
      </ul>
    );
  };

  // const BasicInfo = () => {
  //   return (
  //     <div className={classes.fieldWrapper}>
  //       <TextInput name="firstName"/>
  //       <TextInput name="lastName" />
  //     </div>
  //   );
  // };

  const menuItems = timeZones?.timeZones.map((timeZone) => {
    return (
      <MenuItem key={timeZone.id} value={timeZone.id}>
        {timeZone.alias ? timeZone.alias : timeZone.tzName}
      </MenuItem>
    );
  });

  return (
    <>
      <Snackbar
        sx={{ width: "80vw", maxWidth: "450px" }}
        open={snackbarArgs.show}
        autoHideDuration={2000}
        onClose={() => setSnackbarArgs({ show: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={() => setSnackbarArgs({ show: false })}
          className={classes.alert}
        >
          <AlertTitle>Success</AlertTitle>
          {snackbarArgs.description}
        </Alert>
      </Snackbar>

      <Formik
        enableReinitialize={true}
        validate={validate}
        initialValues={formValues}
        onSubmit={(values, formikHelpers) => {
          handleSubmit(values, formikHelpers);
        }}
      >
        {(formikHelpers) => {
          const {
            values,
            isSubmitting,
            validateForm,
            submitForm,
            setFieldValue,
            handleChange,
          } = formikHelpers;
          // see if any fields have changed
          const sameData = isEqual(formValues, values);
          const timeZoneIndex: number = findIndex(timeZones?.timeZones, {
            id: values.timeZoneId,
          });
          if (timeZoneIndex < 0 || !timeZones) {
            throw new Error(`Missing timeZone ${values.timeZoneId}`);
          }
          // const timeZone = timeZones.timeZones[timeZoneIndex];
          return (
            <Form
              noValidate
              autoComplete="off"
              className={classes.root}
              onSubmit={async (event) => {
                event.preventDefault();
                const ret = await validateForm();
                if (!isEmpty(ret)) {
                  setAlertArgs({
                    open: true,
                    title: "Errors found - please correct",
                    message: errorComponent(ret),
                  });
                }
                submitForm();
              }}
            >
              <LoadingScreen loading={loading} />
              <AlertDialog
                alertProps={alertArgs}
                setAlertProps={setAlertArgs}
              />
              <EntryContainer title="User Profile">
                <Card raised={true}>
                  <CardContent>
                    {/*<Box sx={{ mb: 2 }}>*/}
                    {/*  <Typography variant="subtitle1">Required Info</Typography>*/}
                    {/*</Box>*/}
                    <div className={classes.fieldWrapper}>
                      <TextInput name="firstName" required />
                      <TextInput name="lastName" required />
                      <TextInput name="email" required />

                      <FormControl variant="filled" fullWidth>
                        <InputLabel id="timeZone-select-label">
                          Time Zone
                        </InputLabel>
                        <Select
                          labelId="timeZone-select-label"
                          id="timeZone-select"
                          value={values.timeZoneId}
                          label="Time Zone"
                          onChange={(event) => {
                            setFieldValue("timeZoneId", event.target.value);
                          }}
                        >
                          {menuItems}
                        </Select>
                      </FormControl>
                    </div>
                  </CardContent>
                </Card>
                <Divider
                  sx={{ marginTop: 3, marginBottom: 3 }}
                  className={classes.divider}
                  variant="middle"
                />
                <Card raised={true}>
                  <CardContent>
                    <div className={classes.fieldWrapper}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">
                          Client Name Display & Sort Preferences
                        </Typography>
                        <Typography variant="subtitle2">
                          Used when displaying scrolling lists of clients
                        </Typography>
                      </Box>
                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                      >
                        <InputLabel id="client-type">Display order</InputLabel>
                        <Select
                          labelId="clientNameDisplay"
                          id="clientNameDisplay"
                          value={values.clientNameDisplay}
                          name="clientNameDisplay"
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("clientNameDisplay", e.target.value);
                          }}
                        >
                          <MenuItem value="FIRST_NAME">
                            First Name, Last Name
                          </MenuItem>
                          <MenuItem value="LAST_NAME">
                            Last Name, First Name
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                      >
                        <InputLabel id="client-name-sort-order">
                          Sort on First Name or Last Name
                        </InputLabel>
                        <Select
                          labelId="clientNameSort"
                          id="clientNameSort"
                          value={values.clientNameSort}
                          name="clientNameSort"
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("clientNameSort", e.target.value);
                          }}
                        >
                          <MenuItem value="FIRST_NAME">First Name</MenuItem>
                          <MenuItem value="LAST_NAME">Last Name</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </CardContent>
                </Card>
                <SubmitButton
                  disabled={isSubmitting || sameData}
                  label="Save"
                />
              </EntryContainer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
