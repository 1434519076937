import * as React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { useWindowSize } from "../../hooks";

interface IProps {
  disabled: boolean;
  hidden?: boolean;
  label?: string;
  useBottomBar?: boolean;
}

const nonFooterSx = {
  paddingTop: 5,
};

const footerSx = {
  ml: { xs: 0, lg: "240px" },
  zIndex: 2,
  height: "64px",
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  bottom: 0,
  left: 0,
  right: 0,
  // flexDirection: "center",
  justifyContent: "center",
  borderRadius: "4px",
  backgroundColor: "white",
  paddingTop: 0,
  boxShadow:
    "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
};

export const SubmitButton: React.FC<IProps> = (props: IProps) => {
  const {
    disabled,
    label = "Save",
    hidden = false,
    useBottomBar = false,
  } = props;
  const size = useWindowSize();

  const sx =
    !size.isTouchDevice && useBottomBar && !hidden ? footerSx : nonFooterSx;

  return (
    <Box sx={sx}>
      <Box
        sx={{
          width: "100%",
          display: hidden ? "none" : "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          type="submit"
          sx={{
            display: "block",
            width: 120,
          }}
          disabled={disabled}
        >
          {label}
        </Button>
      </Box>
    </Box>
  );
};
