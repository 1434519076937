import {Box} from "@mui/material";

export const CheckoutCancelled = () => {


  return (
    <Box sx={{m: "100px auto"}}>
      Checkout Cancelled
    </Box>
  )
}
