import React from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { IFormValues } from "./index";
import { PdfViewer, SuperbillRequest } from "components/Reports";
import {
  ReportDispositionEnum,
  SuperbillMutationVariables,
  useSuperbillMutation,
} from "generated/graphql";
import { IUpdateRet, SaveRetErrorType } from "types";
import { localCache } from "graphqlDocuments";
import dayjs from "dayjs";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Snackbar,
} from "@mui/material";
import { useClientGetter } from "../../../hooks";

const initialValues: IFormValues = {
  client: null,
  dateRange: [null, null],
  reportFormat: "PDF",
  reportDisposition: "PRINT",
  showBalanceDue: false,
};

export const SuperbillController: React.FC = () => {
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [formValues, setFormValues] = React.useState<IFormValues>(
    initialValues
  );
  const nav = useNavigate();
  const [executeSuperbillReport, { data }] = useSuperbillMutation({});

  const [executeSuperbillEmail] = useSuperbillMutation({
    onCompleted() {
      setShowSnackbar(true);
    },
  });

  const getOneClient = useClientGetter().getOneClient;

  const setExecuteVars = (values: IFormValues) => {
    const companyId = localCache().companyId;
    let startDate;
    let endDate;
    const dateFormat = "YYYY-MM-DD";
    startDate = dayjs(values.dateRange[0]!).format(dateFormat);
    endDate = dayjs(values.dateRange[1]!).format(dateFormat);
    const showBalanceDue = values.showBalanceDue;

    const variables: SuperbillMutationVariables = {
      companyId,
      startDate,
      endDate,
      validate: false,
      clientId: values!.client!.id,
      reportDisposition: ReportDispositionEnum.Print,
      showBalanceDue
    };
    return variables;
  };

  const executeRequest = async (values: IFormValues): Promise<IUpdateRet> => {
    try {
      const variables = setExecuteVars(values);

      const { data } = await executeSuperbillReport({
        variables,
      });

      if (!data?.superbill.success) {
        return {
          success: false,
          errorMessage: data?.superbill.error?.message,
        };
      }

      return {
        success: true,
      };
    } catch (e) {
      console.log("error", e);
      return {
        success: false,
        errorMessage: "got network error",
        errorType: SaveRetErrorType.Network,
      };
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const values: IFormValues = formValues;
  const client = values.client?.id
    ? getOneClient(values.client?.id)
    : undefined;

  interface IHandleProps {
    values: IFormValues;
    emailClient1: boolean;
    emailClient2: boolean;
    emailMe: boolean;
  }

  const handleSendEmail = async (props: IHandleProps) => {
    const { values, emailClient1, emailClient2, emailMe } = props;
    const variables = setExecuteVars(values);
    setOpenDialog(false);

    const adjustedVars = {
      ...variables,
      reportDisposition: ReportDispositionEnum.Email,
      emailClient1,
      emailClient2,
      emailMe,
      validate: true,
      sessionCt: data?.superbill.sessionCt,
      statementAmount: data?.superbill.statementAmount,
    };

    await executeSuperbillEmail({
      variables: adjustedVars,
      onCompleted(data) {
        if (data.superbill.success) {
          nav(-1);
        } else {
          alert(data.superbill.error?.message || "Super Bill Report failed");
        }
      },
    });
  };

  const EmailDialog = () => {
    const [emailClient1, setEmailClient1] = React.useState(false);
    const [emailClient2, setEmailClient2] = React.useState(false);
    const [emailMe, setEmailMe] = React.useState(false);
    return (
      <Dialog
        maxWidth="md"
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Email Super Bill</DialogTitle>
        <DialogContentText sx={{ padding: "0 20px" }}>
          Select recipients
        </DialogContentText>
        <Box padding="0 20px">
          <FormGroup>
            {!client?.email && <FormHelperText>No Email</FormHelperText>}
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailClient1}
                  disabled={!client?.email}
                  onChange={() => setEmailClient1(!emailClient1)}
                />
              }
              label={`${client?.firstName} ${client?.lastName}`}
            />
          </FormGroup>
          {client?.lastName2 && (
            <FormGroup>
              {!client?.email2 && <FormHelperText>No Email</FormHelperText>}
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!client?.email2}
                    checked={emailClient2}
                    onChange={() => setEmailClient2(!emailClient2)}
                  />
                }
                label={`${client?.firstName2} ${client?.lastName2}`}
              />
            </FormGroup>
          )}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailMe}
                  onChange={() => setEmailMe(!emailMe)}
                />
              }
              label="Send me a copy"
            />
          </FormGroup>
        </Box>
        <DialogActions>
          <Button autoFocus onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={() =>
              handleSendEmail({ values, emailMe, emailClient1, emailClient2 })
            }
            disabled={!emailClient1 && !emailClient2 && !emailMe}
          >
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
    );
  };


  const handleClose = () => {
    setShowSnackbar(false);
  };

  return (
    <React.Fragment>
      <Snackbar
        sx={{ width: "80vw", maxWidth: "450px" }}
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={handleClose}
          sx={{ width: "100%" }}
        >
          <AlertTitle>Success</AlertTitle>
          Super Bill Statement Sent
        </Alert>
      </Snackbar>

      <EmailDialog />
      <Routes>
        <Route
          path="/"
          element={
            <SuperbillRequest
              setFormValues={setFormValues}
              initialValues={formValues}
              executeRequest={executeRequest}
            />
          }
        />
        <Route
          path="pdf-viewer"
          element={
            <PdfViewer
              base64Pdf={data?.superbill?.base64Pdf}
              allowEmail={true}
              openEmailDialog={setOpenDialog}
            />
          }
        />
      </Routes>
      <Outlet />
    </React.Fragment>
  );
};
