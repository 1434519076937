import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect} from "react";
import {useNewSubscriptionMutation} from "../../generated/graphql";
import {localCache} from "../../graphqlDocuments";
import {useReactiveVar} from "@apollo/client";
import {LoadingScreen} from "../../components/LoadingScreen";

export const CheckoutSuccessful = () => {
  const [searchParams] = useSearchParams({});
  const localCacheState = useReactiveVar(localCache);
  const sessionId = searchParams.get("session_id");
  const [updateDone, setUpdateDone] = React.useState(false);
  const nav = useNavigate();
  const [updateNewSubscription] = useNewSubscriptionMutation({
    onCompleted() {
      setUpdateDone(true);
    },
  });
  const companyId = localCacheState.companyId;

  useEffect(() => {
    if (sessionId && companyId) {
      updateNewSubscription({
        variables: {
          companyId: localCache().companyId,
          sessionId,
        },
      });
    }
  }, [sessionId, updateNewSubscription, companyId]);

  useEffect(() => {
    nav("subscription-status", {replace:true});
  }, [updateDone, nav]);

  return <LoadingScreen loading={true} />;
};
