import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ProductPurchasedEnum,
  StripeStatusEnum,
  useCreateCheckoutSessionMutation,
  useCreatePortalSessionLazyQuery,
  useNewSubscriptionMutation,
  useSubscriptionStatusQuery,
} from "../../generated/graphql";
import { localCache } from "../../graphqlDocuments";
import { LoadingScreen } from "../../components/LoadingScreen";
import { GraphQLErrorScreen } from "../../components/Errors";
import { EntryContainer } from "../../components/Containers";
import { useCompanyGetter, useTopOfForm } from "../../hooks";
import { formatCurrency } from "../../utils";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { IAlert } from "../../types";

export const SubscriptionStatus: React.FC = () => {
  useTopOfForm()
  const [loadingPortal, setLoadingPortal] = useState(false);
  const theme = useTheme();
  const { currencyIsoCode, locale } = useCompanyGetter();

  const [searchParams, setSearchParams] = useSearchParams();
  const [showAlert, setShowAlert] = React.useState<IAlert>({ show: false });
  const [updateNewSubscription] = useNewSubscriptionMutation({
    onCompleted() {
      // setUpdateDone(true);
      setSearchParams({}, { replace: true });
      setShowAlert({
        show: true,
        description: "Subscription successful",
      });
    },
  });
  // set return from checkout-session
  const gotSuccessfulCheckout = Boolean(searchParams.get("success"));
  const gotCancelCheckout = Boolean(searchParams.get("cancel"));
  const sessionId = searchParams.get("session_id");

  //setSearchParams(newSearchParams, {replace:true});

  const currentUrl = window.location.href
    .split("?")[0]
    .replace(/\/$/, "")
    .trim();

  const [createCheckout] = useCreateCheckoutSessionMutation({
    variables: {
      companyId: localCache().companyId,
      cancelUrl: currentUrl,
      successUrl: currentUrl,
      productPurchased: ProductPurchasedEnum.Standard,
    },
    onCompleted(data) {
      const newHref = data?.createCheckoutSession.sessionUrl;
      if (newHref) {
        window.location.href = newHref;
      }
    },
  });

  const [lazyQuery, { data: portalData }] = useCreatePortalSessionLazyQuery({
    variables: {
      companyId: localCache().companyId,
      returnUrl: currentUrl,
    },
  });

  useEffect(() => {
    if (!gotSuccessfulCheckout) {
      return;
    }
    if (!sessionId) {
      throw new Error(
        "Subscription not returned from Stripe - please contact Workbox"
      );
    }
    updateNewSubscription({
      variables: {
        companyId: localCache().companyId,
        sessionId,
      },
    });
  }, [gotSuccessfulCheckout, sessionId, updateNewSubscription]);

  useEffect(() => {
    if (gotCancelCheckout) {
      setShowAlert({
        show: true,
        description: "Subscription Checkout Session Cancelled",
      });
    }
  }, [gotCancelCheckout]);

  useEffect(() => {
    if (portalData?.createPortalSession?.portalSessionUrl) {
      window.location.href = portalData?.createPortalSession?.portalSessionUrl;
    }
  }, [portalData?.createPortalSession?.portalSessionUrl]);

  const currencyFormatter = React.useMemo(
    () => formatCurrency(locale, currencyIsoCode, 2, 2),
    [locale, currencyIsoCode]
  );
  const { data, loading, error } = useSubscriptionStatusQuery({
    variables: {
      companyId: localCache().companyId,
    },
    onCompleted() {
      console.log("got new subscription status");
    },
  });

  if (loading || loadingPortal) {
    return <LoadingScreen loading={true} />;
  }

  const company = data?.company;

  if (!company) {
    throw new Error("Company record missing");
  }

  const onTrial = company.stripeStatusEnum === StripeStatusEnum.Trialing;

  if (error) {
    return <GraphQLErrorScreen error={error} />;
  }

  const handleCheckoutSession = () => {
    createCheckout();
  };

  interface ITrialCard {
    trialEndDate: string;
    monthlyFee: number;
  }
  const TrialCard = (props: ITrialCard) => {
    const { trialEndDate, monthlyFee } = props;
    const formattedFee = currencyFormatter.format(monthlyFee / 100);
    const formattedDate = dayjs(trialEndDate).format("MMMM D, YYYY");
    const today = dayjs();
    const trialExpired = today.isAfter(dayjs(trialEndDate));
    const color = trialExpired ? "red" : "black";

    return (
      <Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "20px",
            gridRowGap: "10px",
          }}
        >
          <Box sx={{ justifySelf: "end" }}>Status:</Box>
          <Typography variant="body1" sx={{ color }}>
            30-day Trial
          </Typography>
          <Box sx={{ justifySelf: "end" }}>Trial End:</Box>
          <Box>{formattedDate}</Box>
          <Box sx={{ justifySelf: "end" }}>Monthly Fee:</Box>
          <Box>{formattedFee}</Box>
        </Box>
        <Box
          sx={{
            mt: "40px",
            mb: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button onClick={handleCheckoutSession} variant="contained">
            Activate Account
          </Button>
        </Box>
        {trialExpired && (
          <Box mt="20px">
            The Trial period has expired. Please click the Activate Account button
            if you would like to continue using the Workbox app.
          </Box>
        )}
      </Box>
    );
  };

  const displayPortal = () => {
    lazyQuery();
    setLoadingPortal(true);
  };

  const RaisedCard = ({
    title,
    children,
    hidden,
  }: {
    title: string;
    children: React.ReactNode;
    hidden: boolean;
  }) => {
    if (hidden) {
      return null;
    }
    return (
      <>
        <Card raised={true}>
          <CardContent>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">{title}</Typography>
            </Box>
            {children}
          </CardContent>
        </Card>
      </>
    );
  };

  const PortalCard = () => {
    return (
      <Box>
        <Box>
          Workbox uses Stripe to manage monthly credit card payments. You may
          visit the Stripe Portal to change credit cards and view payment
          history.
        </Box>
        <Box
          sx={{
            mt: "40px",
            mb: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button onClick={displayPortal} variant="contained">
            Visit Portal
          </Button>
        </Box>
      </Box>
    );
  };

  const getFormattedStatus = (status: StripeStatusEnum) => {
    switch (status) {
      case "active":
        return "Active";
      case "incomplete":
        return "Incomplete";
      case "canceled":
        return "Cancelled";
      case "incomplete_expired":
        return "Incompleted expired";
      case "past_due":
        return "Past Due";
      case "trialing":
        return "Trial";
      case "unpaid":
        return "Unpaid";
      default:
        throw new Error(`Subscription Status invalid: ${status}`);
    }
  };

  interface ISubscriptionCard {
    status: StripeStatusEnum;
    periodEndDate: string;
    monthlyFee: number;
  }
  const SubscriptionCard = (props: ISubscriptionCard) => {
    const { status, periodEndDate, monthlyFee } = props;
    const formattedFee = currencyFormatter.format(monthlyFee / 100);
    const formattedDate = dayjs(periodEndDate).format("MMMM D, YYYY");
    const formattedStatus = getFormattedStatus(status);
    const color =
      status === StripeStatusEnum.Active
        ? "green"
        : status === StripeStatusEnum.Trialing
        ? "black"
        : "red";
    return (
      <Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "20px",
            gridRowGap: "10px",
          }}
        >
          <Box sx={{ justifySelf: "end" }}>Status:</Box>
          <Typography variant="body1" sx={{ color }}>
            {formattedStatus}
          </Typography>
          <Box sx={{ justifySelf: "end" }}>Period End:</Box>
          <Box>{formattedDate}</Box>
          <Box sx={{ justifySelf: "end" }}>Monthly Fee:</Box>
          <Box>{formattedFee}</Box>
        </Box>
        <Box
          sx={{
            mt: "40px",
            mb: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button>Cancel subscription</Button>
        </Box>
      </Box>
    );
  };

  const handleClose = () => {
    setShowAlert({ show: false });
  };

  return (
    <EntryContainer
      maxWidth={theme.breakpoints.values.sm}
      sx={{ mt: "50px" }}
      title="Subscription Settings"
    >
      <Snackbar
        open={showAlert.show}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose}>{showAlert.description}</Alert>
      </Snackbar>
      <RaisedCard title="Status" hidden={!onTrial}>
        <TrialCard
          trialEndDate={company.trialEndDate!}
          {...data?.subscriptionStatus!}
        />
      </RaisedCard>
      <RaisedCard title="Status" hidden={onTrial}>
        <SubscriptionCard {...data?.subscriptionStatus!} />
      </RaisedCard>
      {!onTrial && <Divider sx={{ mt: "25px", mb: "25px" }} />}
      <RaisedCard title="Payment Portal" hidden={onTrial}>
        <PortalCard />
      </RaisedCard>
    </EntryContainer>
  );
};
