import * as React from "react";
import { makeStyles } from "@mui/styles";
import { Paper } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  panel: {
    height: 300,
    width: 300,
    padding: 20,
  },
}));

// interface IProps {
//   uid: string;
// }
export const Startup: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper elevation={8} className={classes.panel}>
        Loading...
      </Paper>
    </div>
  );
};
