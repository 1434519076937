import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const LineItemWithHover = (props: any) => {
  return (
    <Box
      typography="subtitle1"
      sx={{
        fontWeight: 500,
        ...props.sx,
        "&:hover": {
          backgroundColor: "rgb(45, 245, 245);",
          opacity: [0.9, 0.8, 0.7],
        },
      }}
    >
      {props.children}
    </Box>
  );
};
export const LineItemWithHoverx = styled(Typography)({
  variant: "h3",
  fontWeight: 500,
  component: "div",
  "&:hover": {
    backgroundColor: "rgb(45, 245, 245);",
    opacity: [0.9, 0.8, 0.7],
  },
});
