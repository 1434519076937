import Button from "@mui/material/Button";
import React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Dialog, Typography, DialogContentText } from "@mui/material";
import { Dispatch } from "react";
import { ActionType, IReducerState } from "./index";

interface IProps {
  state: IReducerState;
  dispatch: Dispatch<ActionType>;
  title: string;
  dialogText: string;
  buttonLabel: string;
  cancelButtonLabel?: string;
}

export const ConfirmDialog: React.FC<IProps> = (props) => {
  const {
    title,
    dialogText,
    buttonLabel,
    state,
    dispatch,
    cancelButtonLabel = "Cancel",
  } = props;

  const { overrideDialogText } = state;

  const closeDialog = () => {
    dispatch({ type: "SET_DIALOG_CLOSED" });
  };

  return (
    <Dialog
      open={state.dialogOpen}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent sx={{ minWidth: "250px" }}>
        <Typography variant="body1" component="div">
          <DialogContentText id="alert-dialog-description">
            {overrideDialogText || dialogText}
          </DialogContentText>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            state?.handleCancelPressed && state.handleCancelPressed();
            closeDialog();
          }}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          onClick={() => {
            state?.handleOkPressed && state.handleOkPressed();
            closeDialog();
          }}
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/*
export const ConfirmDialog: React.FC<IProps> = (props) => {
      <Dialog
        open={state.validateDelete}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Delete Invoice
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" component="div">
            <DialogContentText id="alert-dialog-description">
              {`Client: ${state.displayName}. Invoice Date: ${state.invoiceDate}`}
            </DialogContentText>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDeleteInvoice}>Delete invoice</Button>
        </DialogActions>
      </Dialog>

 */
