import * as React from "react";
import ReactPlayer from "react-player/youtube";
import { useWindowSize } from "../../hooks";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import StayCurrentLandscapeIcon from "@mui/icons-material/StayCurrentLandscape";

const mobileStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

const webStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "750px",
};

interface IProps {
  setOpen(arg: boolean): void;
  open: boolean;
  url: string;
}

const mobileModalSx = {
  width: "70vw",
  height: "100vh",
};

const webModalSx = {
  // width: "700px",
  // height: "462px"
};

export const ShowYouTubeVideo: React.FC<IProps> = (props) => {
  const { open, setOpen, url } = props;
  const size = useWindowSize();
  const isLandscape = size.isLandscape;
  const isMobile =
    size.isTouchDevice && (size.width < 1000 || size.height < 500);
  let width = size.width;
  let height = size.width * 0.66;
  if (height > size.height) {
    const adj = size.height / height;
    width = width * adj;
    height = width * 0.66;
  }
  if (!isMobile) {
    width = size.width > 850 ? 800 : size.width * 0.9;
    height = 750 * 0.66;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const style = isMobile ? mobileStyle : webStyle;

  return (
    <React.Fragment>
      <Modal
        sx={isMobile ? mobileModalSx : webModalSx}
        open={open}
        onClose={handleClose}
      >
        <div>
          <Box
            sx={{
              ...style,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box>
              <ReactPlayer
                url={url}
                width={width}
                height={height}
                playsinline={true}
                onEnded={() => handleClose()}
                controls={true}
              />
              <Box
                typography="body2"
                sx={{
                  padding: "10px",
                  backgroundColor: "white",
                  display: isMobile && !isLandscape ? "block" : "none",
                }}
              >
                <div>Recomended viewing:</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div>1) When video starts, click the Full Screen Icon</div>
                  <FullscreenIcon />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <span>2) And hold phone landscape (ie. sideways)</span>
                  <StayCurrentLandscapeIcon sx={{ ml: "3px" }} />
                </div>
              </Box>
            </Box>
            <IconButton
              onClick={() => handleClose()}
              aria-label="close"
              color="error"
              sx={{
                zIndex: 1000,
                position: "fixed",
                right: "20px",
                top: "30px",
                display: isMobile ? "block" : "none",
              }}
            >
              <CloseIcon sx={{ fontSize: "1.5em" }} />
            </IconButton>
          </Box>
        </div>
      </Modal>
    </React.Fragment>
  );
};
