import {Location, useLocation, useNavigate} from "react-router-dom";
import {GraphQLErrors, NetworkError} from "@apollo/client/errors";
import {Box, Button} from "@mui/material";
import {AuthenticationService} from "services";
import React, {useEffect} from "react";

//import {ServerError, ServerParseError} from '@apollo/client'

interface ILocState {
  graphQLErrors: Array<GraphQLErrors> | undefined;
  networkError: NetworkError | undefined;
}

export function CrashScreen() {
  const location: Location = useLocation();
  const nav = useNavigate();
  const needSignout = React.useRef(false);

  const state = (location.state as ILocState) || {};
  const graphQLErrors = state?.graphQLErrors;
  const networkError = state?.networkError;

  let errMsg1 = "An error has occurred";
  let errMsg2 = "We've been notified and will look into this right away."

  console.log("graphql error", graphQLErrors, networkError);

  if (networkError?.message.includes("401")) {
    errMsg1 = "You are not authorized for this transaction"
    errMsg2 = "Please sign in again"
    needSignout.current = true;
  } else if (networkError) {
    errMsg1 = "A network error has occurred"
    errMsg2 = "Please try transaction again"
  } else {
    needSignout.current = true;
  }

  const signOut = needSignout.current;

  useEffect(() => {
    if (signOut) {
      AuthenticationService.signOut();
      console.log("you've been logged out")
    }
  },[signOut])

  const handleClick = () => {
    if (needSignout.current) {
      nav("/sign-in", {replace: true});
    } else {
      nav("/clients")
    }
  };

  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: "white", padding: "20px" }}
    >
      <Box display="flex" flexDirection="column">
        <Box style={{ justifySelf: "center", fontSize: "100px", color: "red" }}>
          {"\u26A0"}
        </Box>
        <Box mt="20px" typography="h4">
          Oops!!
        </Box>
        <Box mt="20px" typography="h5">
          {errMsg1}
        </Box>
        <Box mt="20px" typography="h5">
          {errMsg2}
        </Box>
        <Button
          onClick={handleClick}
          size="large"
          type="submit"
          variant="contained"
          sx={{ maxWidth: "250px", mt: "40px" }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
}
