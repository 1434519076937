import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {makeStyles} from "@mui/styles";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListIcon from "@mui/icons-material/List";
import AcccountCircle from "@mui/icons-material/AccountCircle";
// import PaymentIcon from "@mui/icons-material/Payment";
import SettingsIcon from "@mui/icons-material/Settings";
import EventIcon from "@mui/icons-material/Event";
import PaidIcon from '@mui/icons-material/Paid';
import React from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {Box, Button, Collapse, Divider, ListItemButton, Theme, Toolbar,} from "@mui/material";
import { ExpandLess, ExpandMore, OndemandVideo } from "@mui/icons-material";
import SummarizeIcon from "@mui/icons-material/Summarize";
import logo from "./workbox-logo.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  activeStyle: {
    color: "red!important" as any,
  },
  navlink: {
    textDecoration: "none",
    color: "rgba(0,0,238,.7)",
    fontWeight: 600,
  },
  signOut: {
    margin: 20,
  },
  settings: {
    backgroundColor: "lightgray",
    height: 30,
    textAlign: "center",
    marginTop: 30,
    paddingTop: 4,
  },
  logo: {
    marginTop: "25px",
    padding: "0 15px",
    height: "40px",
    pointerEvents: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  list: {
    marginTop: theme.spacing(4),
    marginBottom: "65px",
  },
}));

interface IProps {
  open: boolean;
  setOpen(open: boolean): void;
}

export function Navigation(props: IProps) {
  const { open, setOpen } = props;
  const [openSettings, setOpenSettings] = React.useState(false);
  const [openReports, setOpenReports] = React.useState(false);
  const nav = useNavigate()
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const classes = useStyles();

  const moreProps = !isLargeScreen
    ? { onBackdropClick: () => setOpen(false) }
    : undefined;

  // const showActive = ({ isActive } : {isActive:boolean}) => {
  //   return {
  //     display: "block",
  //     margin: "1rem 0",
  //     color: isActive ? "red" : "",
  //     textDecoration: 'none'
  //   };
  // }

  const closeDrawer = () => {
    setOpen(false);
  };

  interface ICustomNav {
    to: string;
    primary: string;
    secondary?:string;
    padding?: boolean;
    children?: JSX.Element;
  }
  const CustomNavLink = (props: ICustomNav) => {
    return (
      <ListItem button sx={{ pl: props.padding ? 4 : undefined }}>
        <ListItemIcon>{props.children}</ListItemIcon>
        <NavLink
          onClick={closeDrawer}
          className={(navData) =>
            navData.isActive ? classes.activeStyle : classes.navlink
          }
          to={props.to}
        >
          <ListItemText
            secondaryTypographyProps={{
              color:"inherit"
            }}
            primary={props.primary} secondary={props.secondary}/>
        </NavLink>
      </ListItem>
    );
  };

  const handleSignout = () => {
    nav("/home", {replace:true})
  }

  return (
    <div>
      <Drawer
        sx={{ height: "90vh" }}
        className={classes.drawer}
        open={open}
        variant={isLargeScreen ? "permanent" : "temporary"}
        classes={{
          paper: classes.drawerPaper,
        }}
        {...moreProps}
      >
        <Toolbar />
        <img src={logo} alt="Workbox Logo" className={classes.logo} />
        <Box sx={{m: "0 auto"}} typography="body2">V1.8.2</Box>
        <List className={classes.list}>
          <CustomNavLink to="/clients" primary="Clients">
            <AcccountCircle />
          </CustomNavLink>
          <CustomNavLink to="/sessions" primary="Sessions">
            <ListIcon />
          </CustomNavLink>

          <CustomNavLink to="/import" primary="Import Sessions">
            <EventIcon />
          </CustomNavLink>

          <CustomNavLink to="/invoices" primary="Invoices">
            <ListIcon />
          </CustomNavLink>

          <CustomNavLink to="/payments" primary="Payments">
            <PaidIcon />
          </CustomNavLink>
          <ListItemButton
            className={classes.navlink}
            onClick={() => setOpenReports(!openReports)}
          >
            <ListItemIcon>
              <SummarizeIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
            {openReports ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={openReports} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <CustomNavLink
                to="/payment-report"
                primary="Payments"
                padding={true}
              />
              <CustomNavLink
                to="/invoice-report"
                primary="Invoices"
                padding={true}
              />
              <CustomNavLink
                to="/superbill"
                primary="Super Bill"
                padding={true}
              />

            </List>
          </Collapse>

          <CustomNavLink to="/tutorials" primary="Tutorials">
            <OndemandVideo />
          </CustomNavLink>

          <Divider sx={{ m: "25px 0" }} />
          <ListItemButton
            className={classes.navlink}
            onClick={() => setOpenSettings(!openSettings)}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
            {openSettings ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSettings} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <CustomNavLink
                to="/account-settings"
                primary="General"
                padding={true}
              />
              <CustomNavLink
                to="/user-profile"
                primary="User Profile"
                padding={true}
              />
              <CustomNavLink to="/services" primary="Services" padding={true} />
              <CustomNavLink to="/settings" primary="Theme" padding={true} />
              <CustomNavLink
                to="/subscription-status"
                primary="App Subscription"
                padding={true}
              />
            </List>
          </Collapse>
        </List>
        <Box sx={{ position: "fixed", bottom: 0, left: 0, background: "white" }}>
          <Button
            sx={{ m: "30px 10px 10px 10px", width: "200px" }}
            type="button"
            variant="contained"
            onClick={handleSignout}
          >
            Sign out
          </Button>

        </Box>
      </Drawer>
    </div>
  );
}
