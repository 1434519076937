import {Service, Status, useServicesQuery} from "../generated/graphql";
import {localCache} from "../graphqlDocuments";
import * as React from "react";
import {keyBy} from "lodash";
import {utils} from "../utils";

export type IKeyedServices = Record<
  string,
  Partial<Service> & { fullDescription?: string }
>;

// interface IServiceGetter {
//   getSingleService:(id:string) => Partial<Service>
// }

export const useServiceGetter = () => {
  const [keyedServices, setKeyedServices] = React.useState<IKeyedServices>({})

   useServicesQuery({
    variables: { companyId: localCache().companyId },
    fetchPolicy: "cache-only",
    onCompleted:(data) => {
      const newServices = data?.services.filter(item => {
        return item.status === Status.Active
      }).map((service) => {
        const fullDescription = utils.formatService(service.description, service.cptCode)
        return { ...service, fullDescription };
    })
      const serviceList = newServices ? newServices : [];
      const keyed: IKeyedServices = keyBy(serviceList, "id");
      setKeyedServices(keyed)
  }});

  const getOneService = React.useCallback((id:string) => {
    if (keyedServices[id]) {
      return keyedServices[id];
    }
    return null;
   },[keyedServices])

  return { getOneService, keyedServices };
};
