import {createTheme} from "@mui/material/styles";
import {defaultThemeOptions} from "../theme";

export const computePalette = (
  primary: string | null | undefined,
  secondary: string | null | undefined
) => {
  if (!primary || !secondary) {
    return {};
  }

  const computedTheme = createTheme({
    breakpoints: defaultThemeOptions.breakpoints,
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      background: {
        default: "hsl(210, 32%, 93%)",
        paper: "#FFFFFF",
      },
    },
  });

  return computedTheme;
};
