import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { IFormValues } from "./index";
import { PaymentReportRequest, PdfViewer } from "components/Reports";
import {
  ReportDispositionEnum,
  ReportFormatEnum,
  usePaymentReportMutation,
} from "generated/graphql";
import { IUpdateRet, SaveRetErrorType } from "types";
import { localCache } from "../../../graphqlDocuments";
import dayjs from "dayjs";
import {AuthenticationService} from "../../../services";

export const PaymentReportController: React.FC = () => {
  const [executePaymentReport, { data }] = usePaymentReportMutation({});

  const executeRequest = async (values: IFormValues): Promise<IUpdateRet> => {
    try {
      const companyId = localCache().companyId;
      let startDate;
      let endDate;
      const dateFormat = "YYYY-MM-DD";
      switch (values.dateChoice) {
        case "ytd": {
          startDate = dayjs().startOf("year").format(dateFormat);
          endDate = dayjs().endOf("year").format(dateFormat);
          break;
        }
        case "lastYear": {
          startDate = dayjs()
            .startOf("year")
            .subtract(1, "year")
            .format(dateFormat);
          endDate = dayjs()
            .endOf("year")
            .subtract(1, "year")
            .format(dateFormat);
          break;
        }
        case "all": {
          startDate = "1000-01-01";
          endDate = "3000-01-01";
          break;
        }
        case "custom": {
          startDate = dayjs(values.dateRange[0]!).format(dateFormat);
          endDate = dayjs(values.dateRange[1]!).format(dateFormat);
          break;
        }
      }
      const variables = {
        companyId,
        startDate,
        endDate,
        reportFormat:
          values.reportFormat === "PDF"
            ? ReportFormatEnum.Pdf
            : ReportFormatEnum.Csv,
        reportDisposition:
          values.reportFormat === "PDF"
            ? ReportDispositionEnum.Print
            : ReportDispositionEnum.Download,
      };

      const { data } = await executePaymentReport({
        variables,
      });

      if (!data?.paymentReport.success) {
        return {
          success: false,
          errorMessage: data?.paymentReport.error?.message,
        };
      }

      if (values.reportFormat === "CSV") {
        const fileName = data?.paymentReport?.fileName;
        if (!fileName) {
          return {
            success: false,
            errorMessage: "Error occurred - please try again",
          };
        }

        const token = await AuthenticationService.getToken();
        const input = `${process.env.REACT_APP_API_URL}/download/${fileName}`;
        await fetch(input, {
          headers: {
            authorization: token ? `Bearer ${token}` : "",
            companyid: localCache().companyId,
          }
        })
          .then(response => {
            response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = 'payments.csv';
              a.click();
            });
          })

      }
      return {
        success: true,
      };
    } catch (e) {
      console.log("error", e);
      return {
        success: false,
        errorMessage: "got network error",
        errorType: SaveRetErrorType.Network,
      };
    }
  };

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={<PaymentReportRequest executeRequest={executeRequest} />}
        />
        <Route
          path="pdf-viewer"
          element={<PdfViewer base64Pdf={data?.paymentReport?.base64Pdf} />}
        />
      </Routes>
      <Outlet />
    </React.Fragment>
  );
};
