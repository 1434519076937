import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  useFormikContext,
} from "formik";
import React, { Dispatch } from "react";
import { TextInput } from "components/TextInput";
import isDecimal from "validator/lib/isDecimal";
import {
  Box,
  Button,
  Card,
  CardContent, Checkbox,
  Divider, FormControlLabel, FormGroup,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { ActionType, IFormValues } from "./index";
import { EntryMode, validateEmailList } from "utils";
import { ClientType } from "generated/graphql";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingScreen } from "components/LoadingScreen";
import { AlertDialog, IAlertProps } from "components/Alert";
import { SelectService } from "../Service";
// import Toolbar from "@mui/material/Toolbar";
import { BackButton, SubmitButton } from "../Buttons";
import { EntryContainer } from "../Containers";
import { IUpdateRet, SaveRetErrorType } from "../../types";
import { DeleteForever } from "@mui/icons-material";
import { DataEntrySkeleton } from "../Skeleton";
import { LightGreyDivider, Root } from "../StyledComponents";
import { useTopOfForm } from "../../hooks";

interface IProps {
  mode: EntryMode;
  formValues: IFormValues;
  setFormValues: (arg: IFormValues) => void;
  loading: boolean;
  saveData: (values: IFormValues) => Promise<IUpdateRet>;
  dispatch: Dispatch<ActionType>;
}

export const ClientEntry: React.FC<IProps> = (props) => {
  const {
    mode,
    formValues,
    setFormValues,
    saveData,
    loading,
    dispatch,
  } = props;

  useTopOfForm();
  const nav = useNavigate();
  const { state: locState } = useLocation();
  const savePlus = React.useRef(false);
  // const size = useWindowSize();

  const [alertArgs, setAlertArgs] = React.useState<IAlertProps>({
    open: false,
    message: "",
  });

  const [showMore, toggleShowMore] = React.useState<boolean>(
    Boolean(formValues.firstName2) ||
      Boolean(formValues.attention) ||
      Boolean(formValues.street1) ||
      Boolean(formValues.salutation)
  );

  const showAddServiceScreen = (values: IFormValues, element: number) => {
    dispatch({ type: "SERVICE_ELEMENT_SELECTED", payload: { element } });
    setFormValues(values);
    nav("add-service", { state: locState });
  };

  const validate = (values: IFormValues) => {
    const errors: FormikErrors<IFormValues> = {};

    switch (values.clientType) {
      case ClientType.Individual:
        if (!values.firstName) {
          errors.firstName = "Enter first name";
        }
        if (!values.lastName) {
          errors.lastName = "Enter last name";
        } else if (values.lastName.length > 50) {
          errors.lastName = "Length too long - cannot be > 50 characters";
        }
        break;
      case ClientType.Couples:
        if (!values.companyName) {
          errors.companyName = "Enter client name";
        }
        break;
      case ClientType.Company: {
        if (!values.companyName) {
          errors.companyName = "Enter company Name";
        }
      }
    }

    if (values.email) {
      const emailError = validateEmailList(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    // check for invalid numerics
    values.serviceFees.forEach((serviceFee, idx) => {
      if (
        !isDecimal(serviceFee.sessionFee.trim() || "0", {
          decimal_digits: "0,2",
        })
      ) {
        if (!errors.serviceFees) {
          errors.serviceFees = [];
          errors.serviceFees[idx] = {
            sessionFee: "Invalid number",
          };
        }
      }
    });

    // See if any service rows are partially filled in.
    values.serviceFees.forEach((serviceFee, idx) => {
      if (serviceFee.sessionFee.trim() || serviceFee.service) {
        if (!serviceFee.sessionFee.trim()) {
          if (!errors.serviceFees) {
            errors.serviceFees = [];
            errors.serviceFees[idx] = {
              sessionFee: "Please enter Fee (or delete row)",
            };
          }
        }
      }
    });

    // Now see if there are any duplicate services entered
    // map serviceIds into simple array and then check to index of first occurance.
    // if different it means that there's a dup
    values.serviceFees
      .map((serviceFee) => serviceFee.service?.id || null)
      .forEach((serviceId, idx, array) => {
        if (serviceId) {
          const el = array.indexOf(serviceId);
          if (el !== idx) {
            if (!errors.serviceFees) {
              errors.serviceFees = [];
              errors.serviceFees[idx] = {
                service: "Duplicate Service",
              };
            }
          }
        }
      });

    if (
      !isDecimal(values.openingBalance.trim() || "0", { decimal_digits: "0,2" })
    ) {
      errors.openingBalance = "Invalid Number";
    }

    if (values.openingBalance.trim()) {
      const opening = parseFloat(values.openingBalance.trim());
      if (opening === 0) {
        errors.openingBalance = "Leave blank if there's no Opening Balance";
      }
    }

    return errors;
  };

  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    formikHelpers.setSubmitting(true);

    const ret = await saveData(values);
    if (!ret.success) {
      formikHelpers.setSubmitting(false);
      if (ret.errorType === SaveRetErrorType.Network) {
        setAlertArgs({
          open: true,
          title: "Network Error",
          message: ret.errorMessage,
        });
      } else {
        setAlertArgs({
          open: true,
          title: "Errors Found - please correct",
          message: ret.errorMessage,
        });
      }
    } else if (savePlus.current) {
      savePlus.current = false;
      formikHelpers.resetForm();
      formikHelpers.setSubmitting(false);
    } else {
      nav(-1);
    }
  };

  const BasicInfo = () => {
    const formikProps: FormikProps<IFormValues> = useFormikContext();
    const { values } = formikProps;
    // const autofocus = mode === EntryMode.ADD && !utils.isTouchDevice
    return (
      <Card raised={true}>
        <CardContent>
          <Stack spacing="16px">
            {values.clientType === ClientType.Individual ? (
              <>
                <TextInput name="firstName" required autoFocus={false} />
                <TextInput name="lastName" required />
              </>
            ) : values.clientType === ClientType.Company ? (
              <TextInput name="companyName" label="Company Name" />
            ) : (
              <TextInput name="companyName" label="Client Name" />
            )}
            <TextInput name="email" type="email" />
            {showMore && (
              <>
                <Typography variant="body1" sx={{ mt: "28px" }}>
                  Additional Client Name (e.g. a couples session)
                </Typography>
                <TextInput label="First Name" name="firstName2" />
                <TextInput label="Last Name" name="lastName2" />
                <TextInput label="Email" name="email2" />
                <Typography variant="body1" sx={{ mt: "28px" }}>
                  Mailing Address
                </Typography>
                <TextInput label="Street" name="street1" />
                <TextInput label="Street" name="street2" />
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ mr: 1, flexGrow: 10, minWidth: 90 }}>
                    <TextInput name="city" hideClearButton={true} />
                  </Box>
                  <Box sx={{ mr: 1, width: 83 }}>
                    <TextInput name="state" hideClearButton={true} />
                  </Box>
                  <Box sx={{ width: 117 }}>
                    <TextInput
                      type="number"
                      name="zip"
                      hideClearButton={true}
                    />
                  </Box>
                </Box>
                <TextInput name="country" />
                <TextInput name="attention" />
                <Typography variant="body1" sx={{ mt: "28px" }}>
                  Other Info
                </Typography>
                <TextInput
                  name="companyName"
                  helperText="Enter if client is a business.  The name will print on invoice."
                />
                <TextInput
                  name="abbrevName"
                  label="Override Abbreviated Name"
                  helperText="Name to use on Calendar. Leave blank unless default causes a problem"
                />
                <TextInput
                  name="salutation"
                  helperText="Optional formatting field for emailed invoices - e.g, Dear 'xxxxx,'"
                />
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    );
  };

  const ServiceRow = ({
    el,
    arrayHelpers,
  }: {
    el: number;
    arrayHelpers: FieldArrayRenderProps;
  }) => {
    const formikProps: FormikProps<IFormValues> = useFormikContext();

    const { values } = formikProps;
    return (
      <>
        <Box sx={{ display: "flex", marginBottom: "20px" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ marginBottom: "10px" }}>
              <TextInput
                label="Session Fee"
                type="number"
                name={`serviceFees.${el}.sessionFee`}
              />
            </Box>
            <div>
              <SelectService
                name={`serviceFees.${el}.service`}
                onShowAddScreen={() => showAddServiceScreen(values, el)}
                disableClearable={true}
              />
            </div>
          </Box>

          <IconButton
            sx={{ alignSelf: "center" }}
            onClick={() => arrayHelpers.remove(el)}
            size="large"
          >
            <DeleteForever />
          </IconButton>
        </Box>
      </>
    );
  };

  const BillingInfo = () => {
    // const formikProps: FormikProps<IFormValues> = useFormikContext();
    // const { values } = formikProps;

    return (
      <div>
        <BillingInfoHeading />
        <Stack spacing="16px">
          <TextInput
            name="invoiceHeaderText"
            label="Invoice Header"
            rows={4}
            multiline={true}
          />
        </Stack>
      </div>
    );
  };

  const BillingInfoHeading = () => {
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">
          Information to print on top of invoice
        </Typography>
        <Typography variant="subtitle2">e.g., A diagnostic code.</Typography>
      </Box>
    );
  };

  const OpeningBalanceInfo = () => {
    return (
      <div>
        <OpeningBalanceTitle />
        <Stack spacing="16px">
          <TextInput type="number" name="openingBalance" />
        </Stack>
      </div>
    );
  };

  const PaymentReceipt = () => {
    const formikProps: FormikProps<IFormValues> = useFormikContext();
    const { values, setFieldValue } = formikProps;
    return (
      <div>
        <PaymentReceiptTitle />
        <Stack spacing="16px">
          <FormGroup>
            <FormControlLabel
              data-testid="sendReceipt"
              control={
                <Checkbox
                  checked={values.sendPaymentReceipt}
                  onChange={() =>
                    setFieldValue("sendPaymentReceipt", !values.sendPaymentReceipt)
                  }
                />            }
              label="Send payment receipts?"
            />
          </FormGroup>

        </Stack>
      </div>
    );
  };

  const OpeningBalanceTitle = () => {
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Opening Balance</Typography>
        <Typography variant="subtitle2">
          Used when entering a client that has unpaid invoice(s).
        </Typography>
      </Box>
    );
  };

  const PaymentReceiptTitle = () => {
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Payment Receipts</Typography>
        <Typography variant="subtitle2">
          Send client a receipt when a payment is entered?
        </Typography>
        <Typography variant="subtitle2" sx={{mt:"5px"}}>
          (Note:  This is a default - it can overriden when the payment is entered.)
        </Typography>
      </Box>
    );
  };

  const OtherInfo = () => {
    return (
      <div>
        <OtherInfoTitle />
        <Stack spacing="16px">
          <TextInput multiline={true} rows={6} name="otherInfo" />
        </Stack>
      </div>
    );
  };

  const OtherInfoTitle = () => {
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Other Info</Typography>
        <Typography variant="subtitle2">
          Any other information about the client that you'd like to keep track
          of.
        </Typography>
      </Box>
    );
  };

  if (loading) {
    return (
      <DataEntrySkeleton
        title={mode === EntryMode.ADD ? `Add Client` : `Update Client`}
      />
    );
  }

  return (
    <Formik
      enableReinitialize={false}
      validate={validate}
      initialValues={formValues}
      onSubmit={(values, formikHelpers) => {
        handleSubmit(values, formikHelpers);
      }}
    >
      {(formikProps: FormikProps<IFormValues>) => {
        const { values, isSubmitting, isValidating } = formikProps;
        return (
          <Root>
            <LoadingScreen loading={loading} />
            <AlertDialog alertProps={alertArgs} setAlertProps={setAlertArgs} />
            <Form
              noValidate
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
            >
              <BackButton label="Cancel" />
              <EntryContainer
                title={mode === EntryMode.ADD ? `Add Client` : `Update Client`}
                // maxWidth={700}
              >
                <div>
                  <BasicInfo />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                      onClick={() => toggleShowMore(!showMore)}
                      type="button"
                      sx={{ mt: "10px" }}
                    >
                      {showMore ? "Show Less" : "Show More"}
                    </Button>
                    <Box
                      sx={{
                        marginTop: "10px",
                        letterSpacing: ".02857em",
                        fontWeight: 500,
                        fontSize: ".9rem",
                      }}
                    >
                      (couples session, address, etc)
                    </Box>
                  </Box>
                  <LightGreyDivider sx={{ mt: 1 }} />
                  <Card raised>
                    <CardContent>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Fees</Typography>
                      </Box>
                      <Box sx={{ mb: 1 }}>
                        <Typography variant="subtitle2">
                          Enter session fee and primary service provided.
                        </Typography>
                      </Box>
                      <FieldArray
                        name="serviceFees"
                        render={(arrayHelpers) => (
                          <>
                            {values.serviceFees.map((_, idx) => {
                              return (
                                <div key={idx}>
                                  {idx === 1 ? (
                                    <Divider sx={{ mb: "30px" }}>
                                      Additional Services
                                    </Divider>
                                  ) : idx > 1 ? (
                                    <Divider sx={{ mb: "30px" }} />
                                  ) : null}
                                  <ServiceRow
                                    arrayHelpers={arrayHelpers}
                                    key={idx}
                                    el={idx}
                                  />
                                </div>
                              );
                            })}
                            <Button
                              onClick={() =>
                                arrayHelpers.push({
                                  service: null,
                                  sessionFee: "",
                                })
                              }
                              type="button"
                              sx={{
                                mt: "5px",
                                paddingLeft: 0,
                                paddingBottom: "2px",
                              }}
                            >
                              Add Another Service
                            </Button>
                            <Typography mt="-5px" variant="subtitle2">
                              (Click 'Add ANOTHER SERVICE' if you have other services you provide this client)
                            </Typography>
                          </>
                        )}
                      />
                    </CardContent>
                  </Card>
                  <LightGreyDivider />
                  <Card raised>
                    <CardContent>
                      <BillingInfo />
                    </CardContent>
                  </Card>

                  <LightGreyDivider />
                  <Card raised>
                    <CardContent>
                      <OpeningBalanceInfo />
                    </CardContent>
                  </Card>
                  <LightGreyDivider />
                  <Card raised>
                    <CardContent>
                      <OtherInfo />
                    </CardContent>
                  </Card>
                  <LightGreyDivider />
                  <Card raised>
                    <CardContent>
                      <PaymentReceipt />
                    </CardContent>
                  </Card>
                  <SubmitButton disabled={isSubmitting || isValidating} />
                </div>
              </EntryContainer>
            </Form>
          </Root>
        );
      }}
    </Formik>
  );
};
