import {IMuiSelect} from "types";
import {Status, useClientsQuery} from "../../generated/graphql";
import {localCache} from "../../graphqlDocuments";
import {orderBy} from "lodash";
import {ISelect, SelectItem} from "../Select";

export function SelectClient(props:ISelect) {


  const { data } = useClientsQuery({
    variables: { companyId: localCache().companyId },
    fetchPolicy: "cache-only",
    onError: (error1) => {
      console.log("error1", error1);
    },
  });

  const filteredData = data?.clients.filter(item => {
    return item.status === Status.Active
  })

  const options: Array<IMuiSelect> = orderBy(filteredData, "sortName").map(
    (client) => {
      return {
        id: client.id,
        description: client.displayName,
      };
    }
  );

  return (
    <SelectItem
      onItemSelected={props.onItemSelected}
      name="client"
      label="Select Client"
      options={options}
      mobileSearchLabel="Search Clients"
      addNewItemLabel="Client"
      onShowAddScreen={props.onShowAddScreen}
      hideAddNewButton={props.hideAddNewButton}
    />
  );
}
