import { makeStyles } from "@mui/styles";
import { Header } from "components/Header";
import { Navigation } from "components/Navigation";
import React, { useRef } from "react";
import { Container } from "@mui/material";
import IdleTimer from "react-idle-timer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { localCache } from "../../graphqlDocuments";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthenticationService } from "../../services";
import { styled } from "@mui/system";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 0,
  },
  // appBarSpacer: theme.mixins.toolbar,
  wrapper: {
    display: "flex",
  },
  main: {
    flexGrow: 1,
    position: "relative",
  },
}));


type TProps = {
  children: JSX.Element;
  headerContent?: any;
  title?: string;
};

export function MainContainer(props: TProps) {
  const { children, headerContent, title } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  // const { currentUser } = useAuth();
  const nav = useNavigate()
  const loc = useLocation();
  const pathname = loc.pathname.toLowerCase();
  let lastPath = "";
  if (pathname.startsWith("/clients")) {
    lastPath = "/clients"
  } else if (pathname.startsWith("/payments")) {
    lastPath = "/payments"
  } else if (pathname.startsWith("/invoices")) {
    lastPath = "/invoices"
  } else if (pathname.startsWith("/sessions")) {
    lastPath = "/sessions"
  } else if (pathname.startsWith("/import")) {
    lastPath = "/import"
  } else if (pathname.startsWith("/services")) {
    lastPath = "/services"
  } else if (pathname.startsWith("/settings")) {
    lastPath = "/settings"
  } else if (pathname.startsWith("/user-profile")) {
    lastPath = "/user-profile"
  } else if (pathname.startsWith("/payment-report")) {
    lastPath = "/payment-report"
  } else if (pathname.startsWith("/invoice-report")) {
    lastPath = "/invoice-report"
  }
  localStorage.setItem("lastPath", lastPath);

  // @ts-ignore
  const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);
  const sessionTimeoutRef = useRef<any>(null);
  const staySignedIn = localCache().staySignedIn;
  let IDLE_TIMEOUT =  staySignedIn ? 1000 * 60 * 60 * 24 * 7 : 1000 * 60 * 60
  // let IDLE_TIMEOUT = !currentUser ? 1000 * 60 * 60 * 24 * 100 : 1000 * 10


  const handleIdle = () => {
    //TODO: remove next line and figure out how to offer dialog.
    // but if computer went to sleep - force logoff - don't
    // show dialog
    if (true) {
      handleSignOut()
    } else if (document.hidden) {
      handleSignOut()
    } else {
      setDialogOpen(true);
      sessionTimeoutRef.current = setTimeout(handleSignOut, 1000 * 30);
    }
  };

  const classes = useStyles();
  const idleTimerRef = useRef(null);

  const handleClose = () => {
    clearTimeout(sessionTimeoutRef.current);
    setDialogOpen(false);
  };

  const  handleSignOut = async () =>  {
    setDialogOpen(false);
    clearTimeout(sessionTimeoutRef.current);
    await AuthenticationService.signOut();
    nav("/home", {replace:true})
  };

  return (
    <div className={classes.wrapper}>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Stay Signed In?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Yes - stay signed in
          </Button>
          <Button onClick={handleSignOut} color="primary" autoFocus>
            Sign me out
          </Button>
        </DialogActions>
      </Dialog>

      <IdleTimer
        ref={idleTimerRef as any}
        timeout={IDLE_TIMEOUT}
        onIdle={handleIdle}
      />
      <Header setOpen={setOpen} title={title} headerContent={headerContent} />
      <Navigation open={open} setOpen={setOpen} />
      <Container
        disableGutters={true}
        maxWidth={false}
        className={classes.container}
      >
        <Offset />
        <main className={`${classes.main} `}>{children}</main>
      </Container>
    </div>
  );
}
