import {
  Box,
  Button,
  Link,
  responsiveFontSizes,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import gettingStarted from "./getting-started.jpg";
import importSessions from "./import-sessions.jpg";
import billingAndPayments from "./billing-and-paying.jpg";
import React, { FC, useState } from "react";
import { ShowYouTubeVideo } from "components/Dialogs";
import { useNavigate, useSearchParams } from "react-router-dom";

export const Tutorials: React.FC = () => {
  const [gettingStartedOpen, setGettingStartedOpen] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [billngOpen, setBillingOpen] = useState(false);
  const nav = useNavigate();
  const theme = useTheme();

  const [searchParams] = useSearchParams();
  const fromOnboard = searchParams.has("onboard");

  const newPalette = {
    ...theme.palette,
    info: {
      main: "#000000",
    },
  };

  const newTheme = {
    palette: { ...newPalette },
  };
  let customTheme = createTheme(newTheme);
  customTheme = responsiveFontSizes(customTheme);

  interface IShowPanel {
    url: string;
    alt: string;
    setOpen(arg: boolean): void;
    open: boolean;
    image: any;
    Description: React.ComponentType;
  }

  const GettingStartedHeading: FC = () => (
    <Box sx={{ width: "100%", maxWidth: "500px" }}>
      <Link
        fontWeight="bold"
        variant="h5"
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => setGettingStartedOpen(true)}
      >
        Getting Started Video
      </Link>
      <Box color="black" typography="subtitle" mb="5px">
        The 3 things you should do first
      </Box>
    </Box>
  );

  const ImportHeading: FC = () => (
    <Box sx={{ maxWidth: "500px" }}>
      <Link
        fontWeight="bold"
        variant="h5"
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => setImportOpen(true)}
      >
        Importing Sessions
      </Link>

      <Box color="black" typography="subtitle" mb="5px">
        Transfer appointments from your Google calendar into Workbox.
      </Box>
    </Box>
  );

  const BillingHeading: FC = () => (
    <Box sx={{ maxWidth: "500px" }}>
      <Link
        fontWeight="bold"
        variant="h5"
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => setBillingOpen(true)}
      >
        Billing Clients and Entering Payments
      </Link>
      <Box color="black" typography="subtitle" mb="5px">
        Watch how to send an invoice and enter a client payment.
      </Box>
    </Box>
  );

  // const BillingHeading: FC = () => (
  //   <Box sx={{ mb: "5px" }}>
  //     <Box typography="h5">Billing Clients and Entering Payments</Box>
  //   </Box>
  // );

  const ShowPanel = (props: IShowPanel) => {
    const { url, open, setOpen, Description } = props;
    return (
      <Box
        sx={{
          width: "500px",
          maxWidth: "100vw",
          backgroundColor: "white",
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px 0 0 0",
        }}
      >
        <ShowYouTubeVideo setOpen={setOpen} open={open} url={url} />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#103A93",
              // paddingBottom: "10px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                alignItems: "flex-start",
                // justifyItems: "center",
                gridTemplateColumns: "1fr",
                p: "20px 30px",
              }}
            >
              <Description />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          sx={{
            color: "black",
            alignItems: "center",
            minHeight: "100vh",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            pt:"50px"
          }}
        >
          <Box typography="h4"  p={3} width="500px" maxWidth="100vw">
            Workbox Video Tutorials
          </Box>
          <ShowPanel
            alt="Getting Started"
            image={gettingStarted}
            url="https://youtu.be/zdUD0j6zQo8"
            open={gettingStartedOpen}
            setOpen={setGettingStartedOpen}
            Description={GettingStartedHeading}
          />
          <ShowPanel
            alt="Importing Appointments"
            image={importSessions}
            url="https://youtu.be/jf-bU_A0MIU"
            open={importOpen}
            setOpen={setImportOpen}
            Description={ImportHeading}
          />
          <ShowPanel
            alt="Billing and Payments"
            image={billingAndPayments}
            url="https://youtu.be/2iPgeStqgz4"
            open={billngOpen}
            setOpen={setBillingOpen}
            Description={BillingHeading}
          />
          <Box
            onClick={() => nav("/clients")}
            sx={{
              display: fromOnboard ? "flex" : "none",
              backgroundColor: "white",
              // justifyItems: "center",
              alignItems: "center",
              justifyContent: "center",
              padding: "30px 0",
            }}
          >
            {/*<Box mr="10px" typography="h6">*/}
            {/*  All Done?*/}
            {/*</Box>*/}
            <Button variant="outlined">Go to client screen</Button>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};
