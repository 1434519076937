import React, { Dispatch, SetStateAction } from "react";
import classnames from "classnames";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {  Theme } from "@mui/material/styles";
import { IThemeInfo } from "./types";
import { MutationFunctionOptions } from "@apollo/client";
import { Exact, UpdateThemeMutation } from "../../generated/graphql";
import {makeStyles, createStyles} from "@mui/styles";

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  themeInfo: IThemeInfo;
  setThemeInfo: Dispatch<SetStateAction<IThemeInfo>>;
  updateThemeMutation: (
    options?:
      | MutationFunctionOptions<
          UpdateThemeMutation,
          Exact<{ primaryColor: string; secondaryColor: string }>
        >
      | undefined
  ) => Promise<any>;
}

const isValidColor = (hexColor: string) =>
  /^#?([0-9a-f]{3})$|^#?([0-9a-f]){6}$/i.test(hexColor);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textGroup: {
      display: "flex",
      alignContent: "flex-start",
      margin: "10px 0",
    },
    textField: {
      // margin: '90px 20px 0, 0',
    },
    icon: {
      alignSelf: "center",
      marginLeft: 10,
      marginTop: 15,
      fontSize: 25,
    },
    good: {
      color: theme.palette.success.dark,
      content: "✓",
      fontSize: 25,
    },
    bad: {
      color: theme.palette.error.dark,
      content: "✗",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
  })
);

interface IColors {
  primary: string;
  secondary: string;
}

export const ColorEntryDialog: React.FC<IProps> = ({
  open,
  setOpen,
  themeInfo,
  setThemeInfo,
  updateThemeMutation,
}) => {
  // const handleClickOpen = () => {
  //   setOpen(true)
  // }
  const classes = useStyles();

  const [colors, setColors] = React.useState<IColors>({
    primary: themeInfo.customPrimary,
    secondary: themeInfo.customSecondary,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setColors({
      primary: themeInfo.customPrimary,
      secondary: themeInfo.customSecondary,
    });

    handleClose();
  };
  const handleOK = () => {
    const newThemeInfo = {
      ...themeInfo,
      customPrimary: colors.primary,
      customSecondary: colors.secondary,
    };
    localStorage.setItem("primaryColor", colors.primary);
    localStorage.setItem("secondaryColor", colors.secondary);

    setThemeInfo(newThemeInfo);
    setOpen(false);
    updateThemeMutation({
      variables: {
        primaryColor: colors.primary,
        secondaryColor: colors.secondary,
      },
    });
  };

  const StatusIcon = ({ hexColor }: { hexColor: string }) => {
    return (
      <div
        className={classnames(classes.icon, {
          [classes.good]: isValidColor(hexColor),
          [classes.bad]: !isValidColor(hexColor),
        })}
      >
        {isValidColor(hexColor) ? "✓" : "✗"}
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Custom Colors</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the Theme's Primary and Secondary Color
          </DialogContentText>
          <div className={classes.form}>
            <div className={classes.textGroup}>
              <TextField
                className={classes.textField}
                label="Primary Color"
                value={colors.primary}
                type="text"
                onChange={(event) => {
                  const newColors = {
                    ...colors,
                    primary: event.target.value,
                  };
                  setColors(newColors);
                }}
              />
              <StatusIcon hexColor={colors.primary} />
            </div>
            <div className={classes.textGroup}>
              <TextField
                className={classes.textField}
                label="Secondary Color"
                value={colors.secondary}
                type="text"
                onChange={(event) => {
                  const newColors = {
                    ...colors,
                    secondary: event.target.value,
                  };
                  setColors(newColors);
                }}
              />
              <StatusIcon hexColor={colors.secondary} />\{" "}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            disabled={
              !isValidColor(colors.primary) || !isValidColor(colors.secondary)
            }
            onClick={handleOK}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
