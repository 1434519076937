import { Box } from "@mui/system";
import React from "react";
import {
  Button, Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/Check";
import { useTopOfForm } from "../../hooks";
// const container = {
//   width:"90vw",
//   minWidth:"350px",
//   maxWidth: "600px",
//   backgroundColor:"pink"
// }

const features = [
  "Easy client billing",
  "Easy client payments",
  "At-a-glance client status",
  "Import sessions from Google Calendar",
  "Reporting",
  "Use on mobile, tablet, laptop or desktop",
  "Support from real humans",
];
export const Pricing: React.FC = () => {
  useTopOfForm()
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: "aliceblue",
      }}
    >
      {/*<Typography variant="h5" sx={{mb:"20px"}}>*/}
      {/*  Simple Pricing*/}
      {/*</Typography>*/}
      <Box className="Shadow1">
        <Box
          sx={{
            width: "350px",
            height: "390px",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgb(207,238,219)",
              height: "160px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box typography="h4">$29/Month</Box>
            <Box typography="body1" marginTop="20px">
              No long-term contracts
            </Box>
            <Box typography="body1">Cancel anytime</Box>
          </Box>
          <Box sx={{ backgroundColor: "white", height: "230px" }}>
            <List>
              {features.map((feature, idx) => {
                return (
                  <ListItem disablePadding dense={true} key={idx}>
                    <ListItemButton>
                      <ListItemIcon>
                        <CheckBoxIcon />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Box>
        <Box sx={{ backgroundColor: "white", padding:"20px" }}>
          <Divider sx={{m:2}} />
          <Box width="100%">
            <Button
              sx={{ ml:"auto", mr:"auto", display: "block" }}
              variant="contained"
              size="large"
            >
              Start your free trial
            </Button>
          </Box>
          <Typography m="30px" mb="3px" variant="body1" textAlign="center">
            Fully functional 30-day trial.
          </Typography>
          <Typography textAlign="center" variant="body1">
            No credit card required.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
