import { CompanyEntry, IDimensions, IFormValues } from "./index";
import {
  CompanyDocument,
  CompanyLogoDocument,
  InvoiceDateFormatEnum,
  useCompanyLogoLazyQuery,
  useCompanyLogoQuery,
  useCompanyQuery,
  useCountriesQuery,
  useUpdateCompanyAndLogoMutation,
  useUpdateCompanyMutation
} from "../../generated/graphql";
import { localCache } from "../../graphqlDocuments";
import { useNavigate } from "react-router-dom";
import { IUpdateRet, SaveRetErrorType } from "types";
import { ILocState } from "../Invoice";
import { SetStateAction } from "react";

// if any callers care to be notified that session was saved

interface IProps {
  newTab?: string | null;
  locState?: ILocState;
  setNewTab?: React.Dispatch<SetStateAction<string | null>>;
}

export const ChangeCompanyContainer: React.FC<IProps> = (props) => {
  const nav = useNavigate();
  // get most recent record from database
  const { data, loading } = useCompanyQuery({
    variables: { companyId: localCache().companyId },
    fetchPolicy: "network-only",
    nextFetchPolicy:"cache-first",
    onError: (error1) => {
      throw new Error(error1.message);
    },
  });

  let logo;
  let dimensions: IDimensions = { height: 0, width: 0 };

  const [lazyGetLogo] = useCompanyLogoLazyQuery({
    variables: {
      companyId: localCache().companyId,
    },
    onCompleted(data) {
      if (data?.companyLogo.success && data?.companyLogo?.base64Logo) {
        logo = "data:image/png;base64," + logoData?.companyLogo?.base64Logo;
        dimensions = {
          height: logoData?.companyLogo?.height || 0,
          width: logoData?.companyLogo?.width || 0,
        };
      }
    },
  });
  const { data: logoData, loading: logoLoading } = useCompanyLogoQuery({
    fetchPolicy: "cache-first",
    variables: {
      companyId: localCache().companyId,
    },
  });

  const { data: countries } = useCountriesQuery({
    fetchPolicy: "cache-only",
  });

  const [
    updateCompanyAndLogo,
    { loading: savingAndLogo },
  ] = useUpdateCompanyAndLogoMutation({
    awaitRefetchQueries: true,
  });

  const [updateCompany, { loading: saving }] = useUpdateCompanyMutation();

  if (!logoLoading) {
    if (logoData?.companyLogo.success && logoData?.companyLogo?.base64Logo) {
      logo = "data:image/png;base64," + logoData?.companyLogo?.base64Logo;
      dimensions = {
        height: logoData?.companyLogo?.height || 0,
        width: logoData?.companyLogo?.width || 0,
      };
    }
  }

  const handleSave = async (
    values: IFormValues,
    updateLogo?: boolean,
    file?: File
  ): Promise<IUpdateRet> => {
    try {
      // const companyId = localCache().companyId;
      // check for non-nullable fields
      if (!values.name) {
        throw new Error("saving session: missing company name");
      }

      let retData;
      let retError;

      if (updateLogo) {
        const ret = await updateCompanyAndLogo({
          variables: {
            ...values,
            file,
          },
        });
        retData = ret.data?.updateCompanyAndLogo;
        retError = ret.data?.updateCompanyAndLogo?.error;
      } else {
        const ret = await updateCompany({
          refetchQueries: [
            {
              query: CompanyLogoDocument,
              variables: { companyId: localCache().companyId },
            },
            {
              query: CompanyDocument,
              variables: { companyId: localCache().companyId },
            },
          ],
          variables: {
            ...values,
          },
        });
        retData = ret.data?.updateCompany;
        retError = ret.data?.updateCompany?.error;
      }

      // const retData = ret?.data?.updateCompanyAndLogo?.company;
      // const retError = ret?.data?.updateCompanyAndLogo?.error;
      await lazyGetLogo();
      if (!retError && retData) {
        // localCache().locale = retData.country.locale
        return {
          success: true,
        };
      }

      return {
        success: false,
        errorMessage: retError?.message || "Update failed - try again later",
      };
    } catch (e) {
      return {
        success: false,
        errorMessage: "got network error",
        errorType: SaveRetErrorType.Network,
      };
    }
  };

  if (!loading) {
    // Query should return 1 row
    if (!data?.company) {
      alert("Company Record misssing - call Workbox");
      nav(-1);
      return null;
    }
  }

  // avoid warnings - check to make sure all data returned
  const c = data?.company;

  const initialValues: IFormValues = {
    companyId: c?.id || "x",
    name: c?.name || "",
    address1: c?.address1 || "",
    address2: c?.address2 || "",
    city: c?.city || "",
    state: c?.state || "",
    zip: c?.zip || "",
    addressFinal: c?.addressFinal || "",
    remit1: c?.remit1 || "",
    remit2: c?.remit2 || "",
    remit3: c?.remit3 || "",
    remit4: c?.remit4 || "",
    remit5: c?.remit5 || "",
    invoiceFooter1: c?.invoiceFooter1 || "",
    invoiceFooter2: c?.invoiceFooter2 || "",
    invoiceFooter3: c?.invoiceFooter3 || "",
    languageId: c?.language.id || "",
    languageName: c?.language.name || "",
    currencyId: c?.currency.id,
    currencyName: c?.currency.name,
    countryId: c?.country.id || "1",
    storageLogoURL: c?.logoURL || undefined,
    invoiceLabel: c?.invoiceLabel || "",
    invoiceBodyDateFormat: c?.invoiceBodyDateFormat || InvoiceDateFormatEnum.InvoiceMonth,
    invoiceEmailDateFormat: c?.invoiceEmailDateFormat || InvoiceDateFormatEnum.MonthOfService,
    hideInvoiceEmailSummarySection: c?.hideInvoiceEmailSummarySection || false,
    invoiceEmailSubjectText: c?.invoiceEmailSubjectText || "",
    invoiceEmailBodyText : c?.invoiceEmailBodyText || "",
    hidePaymentReceipt: c?.hidePaymentReceipt || false,
    paymentReceiptSubject: c?.paymentReceiptSubject || '',
    paymentReceiptBodyText:c?.paymentReceiptBodyText || ''
  };

  // if we're here then we have good session data to edit

  return (
    <CompanyEntry
      formValues={initialValues}
      loading={loading || logoLoading || saving || savingAndLogo}
      saveData={handleSave}
      countries={countries}
      logo={logo}
      dimensions={dimensions}
      newTab={props.newTab}
      locState={props.locState}
      setNewTab={props.setNewTab}
    />
  );
};
