import { Box, Button, Link as MailLink, responsiveFontSizes, ThemeProvider, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { useWindowSize } from "hooks";
import logo from "./workbox-logo.png";
import fullClient from "./full-client.jpg";
import fullSession from "./full-sessions.jpg";
import mobileClient from "./mobile-client.jpg";
import sessions from "./sessions.png";
import invoices from "./invoices.png";
import payments from "./payments.png";
import therapists from "./therapists.jpg";
import invoicePreview from "./invoice-preview.jpg";
import mobileInvoicePreview from "./mobile-invoice-preview.png";
import mobileSessions from "./mobile-sessions.jpg";
import mobileInvoices from "./mobile-invoice.jpg";
import React, { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../context/AuthenticationProvider";
import { AuthStatus } from "../../types";
import ReactPlayer from "react-player/youtube";
import { ShowYouTubeVideo } from "components/Dialogs";
import { Head } from "components/Head";

export const Home: React.FC = () => {
  const theme = useTheme();
  const size = useWindowSize();
  const nav = useNavigate();
  const isSmall = size.fullWidth < 744;
  const m = size.fullWidth / 75 - 2;
  const { authStatus, setAuthStatus, signOut } = useContext(
    AuthenticationContext
  );
  // const [showMore, setShowMore] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    if (authStatus === AuthStatus.done) {
      signOut();
      setAuthStatus(AuthStatus.begin);
    }
  }, [authStatus, setAuthStatus, signOut]);

  // const [dialogOpen, setDialogOpen] = React.useState(false);
  const newPalette = {
    ...theme.palette,
    info: {
      main: "#000000",
    },
  };

  const newTheme = {
    palette: { ...newPalette },
  };
  let customTheme = createTheme(newTheme);
  customTheme = responsiveFontSizes(customTheme);

  const Nav = () => {
    // const minWidth = isSmall ? "100vw" : "1000px";
    return (
      <Box
        component="nav"
        sx={{
          alignItems: "center",
          backgroundColor: "#D4E3FD",
          padding: 3,
          paddingLeft: m,
          paddingRight: m,
          display: "grid",
          width: "100vw",
          gridTemplateColumns: "1fr 1.5fr 1fr",
        }}
      >
        <Link style={{ textDecoration: "none" }} to="/pricing">
          Pricing
        </Link>
        <img
          alt="Workbox Logo"
          style={{ justifySelf: "center", maxHeight: "75px", maxWidth: "40vw" }}
          src={logo}
        />
        <Button
          color="info"
          variant="outlined"
          size={isSmall ? "small" : "large"}
          sx={{ justifySelf: "flex-end" }}
          onClick={() => {
            nav("/sign-in");
          }}
        >
          Log In
        </Button>
      </Box>
    );
  };

  const LargePanel2 = () => {
    // const factor = size.fullWidth > 900 ? 1 : .8;
    return (
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 0",
          width: "100vw",
        }}
      >
        <Box sx={{ maxWidth: "1000px", width: "90vw" }}>
          <Box
            // component="span"
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#103A93",
              paddingBottom: "30px",
            }}
          >
            <div>
              <Typography
                component="div"
                variant="h3"
                sx={{ textAlign: "center" }}
              >
                Workbox Does It All
              </Typography>
              <Typography
                component="div"
                variant="body1"
                fontWeight={500}
                sx={{ textAlign: "center", pt: 2 }}
              >
                Organize clients and gain back time all from one easy-to-use
                interface.
              </Typography>
            </div>
            <Box
              sx={{
                display: "grid",
                alignItems: "flex-start",
                justifyItems: "center",
                gridTemplateColumns: "1fr 1fr 1fr",
                p: m / 2,
              }}
            >
              <img
                style={{
                  objectFit: "contain",
                  width: "145px",
                  height: "274px",
                }}
                src={sessions}
                alt="sessions"
              />
              <img
                style={{ objectFit: "contain", width: "149px", height: "260" }}
                src={invoices}
                alt="invoices"
              />
              <img
                style={{
                  objectFit: "contain",
                  width: "165px",
                  height: "274px",
                }}
                src={payments}
                alt="payments"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const SmallPanel2 = () => {
    return (
      <Box
        component="header"
        sx={{
          paddingTop: m / 2,
          backgroundColor: "white",
          display: "flex",
          minHeight: "70vmin",
          width: "100vw",
        }}
      >
        <Box width="100%">
          <Typography
            component="div"
            variant="h3"
            sx={{ mt: 3, textAlign: "center" }}
          >
            Workbox Does It All
          </Typography>
          <Typography
            component="div"
            variant="body1"
            fontWeight={500}
            sx={{ textAlign: "center", pt: 2, pl: 3, pr: 3 }}
          >
            Organize clients and gain back time all from one easy-to-use
            interface.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              p: m / 2,
            }}
          >
            <img
              style={{ objectFit: "contain", width: "145px", height: "274px" }}
              src={sessions}
              alt="sessions"
            />
            <img
              style={{ objectFit: "contain", width: "149px", height: "260" }}
              src={invoices}
              alt="invoices"
            />
            <img
              style={{ objectFit: "contain", width: "165px", height: "274px" }}
              src={payments}
              alt="payments"
            />
          </Box>
        </Box>
        <div />
      </Box>
    );
  };

  const SmallPanel1 = () => {
    const width = size.fullWidth < 748 ? "80vw" : "38vw";

    return (
      <Box
        component="header"
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#D4E3FD",
          width: "100vw",
          overflow: "hidden",
          padding: "30px 0",
          flexWrap: "wrap",
        }}
      >
        <Head
          keywords={[
            "billing app",
            "invoicing app for therapists",
            "best billing app for psychotherapists",
            "save time and money",
            "easy to use billing app",
            "email statements",
          ]}
          title="Workbox Billing App for Therapists"
          description="Best Billing App for Psychotherapists"
        />
        <Box
          component="span"
          sx={{
            minWidth: "320px",
            width,
            maxWidth: "450px",
            minHeight: "300px",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-around",
            ml: "auto",
            mr: "auto",
            flexDirection: "column",
            color: "#103A93",
            // paddingBottom: "30px",
          }}
        >
          <div>
            <Typography
              variant="h4"
              sx={{
                fontSize: "38px",
                fontFamily: "lora",
                fontWeight: 500,
                lineHeight: "46.08px",
                letterSpacing: ".08em",
                color: "navyBlue",
                // lineHeight: 1.2,
              }}
            >
              Simple Accounting For Therapists
            </Typography>
          </div>

          <Box mt={3}>
            <Typography variant="h6" fontSize="20px">
              Save time and money. Take care of your day-to-day accounting needs
              with Workbox's advanced, yet easy-to-use app.
            </Typography>
          </Box>
          <Button
            size="large"
            color="secondary"
            sx={{ mt: m > 5 ? "60px" : m, alignSelf: "center", mb: "30px" }}
            variant="contained"
            onClick={() => nav("/register")}
          >
            Sign up for free
          </Button>
        </Box>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "320px",
            maxWidth: "450px",
            width,
            // padding: "30px",
            // width:"350px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <img
            style={{ objectFit: "contain", width: "100%" }}
            src={therapists}
            alt="therapists"
          />
        </span>
      </Box>
    );
  };

  const LargePanel1 = () => {
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1000px 1fr",
          backgroundColor: "#D4E3FD",
          minWidth: "1000px",
          width: "100vw",
        }}
      >
        <div />
        <Box
          component="header"
          sx={{
            paddingTop: "60px d",
            paddingBottom: size.fullWidth > 500 ? m : 0,
            backgroundColor: "#D4E3FD",
            // minHeight: "60vmin",
            display: "grid",
            overflow: "hidden",
            flexWrap: "wrap",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Box
            component="span"
            sx={{
              width: "500px",
              minHeight: "300px",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-around",
              padding: "30px",
              paddingLeft: "50px",
              // paddingRight: 0.65 * m,
              flexDirection: "column",
              color: "#103A93",
              // paddingBottom: "30px",
            }}
          >
            <div>
              <Typography
                sx={{
                  fontSize: "42px",
                  // letterSpacing: ".08em",
                  // lineHeight: 1.2,
                  fontFamily: "lara",
                  fontWeight: "500",
                  lineHeight: "46.08px",
                  letterSpacing: ".08em",
                }}
              >
                Simple Accounting For Therapists
              </Typography>
            </div>

            <Box mt={3}>
              <Typography variant="h6">
                Save time and money. Take care of your day-to-day accounting
                needs with Workbox's advanced, yet easy-to-use app.
              </Typography>
            </Box>
            <Button
              size="large"
              color="secondary"
              sx={{ mt: m > 5 ? "60px" : m, alignSelf: "center" }}
              variant="contained"
              onClick={() => nav("/register")}
            >
              Sign up for free
            </Button>
          </Box>
          <span
            style={{
              // width,
              // height: "325px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // height: "400px",
              // backgroundColor: "yellow",
            }}
          >
            <img
              style={{ objectFit: "contain", width: "400px" }}
              src={therapists}
              alt="therapists"
            />
          </span>
          {/*</Box>*/}
        </Box>
        <div />
      </Box>
    );
  };

  const LargeInvoiceScreen = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#D4E3FD",
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 0",
          width: "100vw",
        }}
      >
        <Box sx={{ maxWidth: "1000px", width: "90vw" }}>
          <Box
            // component="span"
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#103A93",
              paddingBottom: "30px",
            }}
          >
            <div>
              <Typography sx={{ mb: 1 }} variant="h5">
                Invoicing
              </Typography>
            </div>
            <div>
              <Typography sx={{ mb: 1 }} variant="body1">
                When you select a client to bill, you can preview what the final
                invoice will look like before sending (either email or text.)
              </Typography>
            </div>
            <Box>
              <img
                className="Shadow1"
                style={{ objectFit: "contain", width: "100%" }}
                src={invoicePreview}
                alt="full-client-list"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const LargeSessionScreen = () => {
    return (
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 0",
          width: "100vw",
        }}
      >
        <Box sx={{ maxWidth: "1000px", width: "90vw" }}>
          <Box
            // component="span"
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#103A93",
              paddingBottom: "30px",
            }}
          >
            <div>
              <Typography sx={{ mb: 1 }} variant="h5">
                Sessions
              </Typography>
            </div>
            <div>
              <Typography sx={{ mb: 1 }} variant="body1">
                View your sessions in list format or calendar. Sessions can be
                added directly on this screen - or use the Import Sessions link
                to quickly import sessions directly from your Google Calendar
                (Support for Microsoft calendars coming in a future release).
              </Typography>
            </div>
            <Box>
              <img
                className="Shadow1"
                style={{ objectFit: "contain", width: "100%" }}
                src={fullSession}
                alt="full-client-list"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const SmallSessionScreen = () => {
    return (
      <Box
        component="header"
        sx={{
          paddingTop: 4,
          paddingBottom: 4,
          // padding: 5,
          backgroundColor: "white",
          display: "grid",
          width: "100vw",
          gridTemplateColumns: "1fr",
          justifyItems: "center",
          overflow: "hidden",
        }}
      >
        <Box justifySelf="center">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#103A93",
              paddingBottom: "30px",
            }}
          >
            <div>
              <Typography sx={{ mb: 1, pl: 3 }} variant="h5">
                Sessions
              </Typography>
            </div>
            <div>
              <Typography sx={{ mb: 3, pl: 3, pr: 3 }} variant="body1">
                View your sessions in list format or calendar. Sessions can be
                added directly on this screen - or use the Import Sessions link
                to quickly import sessions directly from your Google Calendar
                (Support for Microsoft calendars coming in a future release).
              </Typography>
            </div>
            <Box alignSelf="center">
              <img
                className="Shadow1"
                style={{
                  objectFit: "contain",
                  width: "80vw",
                  minWidth: "350px",
                  maxWidth: "500px",
                }}
                src={mobileSessions}
                alt="full-client-list"
              />
            </Box>
          </Box>
        </Box>
        <div />
      </Box>
    );
  };

  const SmallInvoicesScreen = () => {
    return (
      <Box
        component="header"
        sx={{
          paddingTop: 4,
          paddingBottom: 4,
          // padding: 5,
          backgroundColor: "D4E3FD",
          display: "grid",
          width: "100vw",
          gridTemplateColumns: "1fr",
          justifyItems: "center",
          overflow: "hidden",
        }}
      >
        <Box justifySelf="center">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#103A93",
              paddingBottom: "30px",
            }}
          >
            <div>
              <Typography sx={{ mb: 1, pl: 3 }} variant="h5">
                Invoice List
              </Typography>
            </div>
            <div>
              <Typography sx={{ mb: 3, pl: 3, pr: 3 }} variant="body1">
                From the invoice listing screen you can click on the "3 dots"
                button for options to view the original invoice, edit the
                invoice, re-send a copy to your client and more.
              </Typography>
            </div>
            `{" "}
            <Box alignSelf="center">
              <img
                className="Shadow1"
                style={{
                  objectFit: "contain",
                  width: "80vw",
                  minWidth: "350px",
                  maxWidth: "500px",
                }}
                src={mobileInvoices}
                alt="invoice-screen"
              />
            </Box>
            `{" "}
            <Box p="30px" typography="h4" alignSelf="center">Sample Invoice</Box>
            <Box alignSelf="center">
              <img
                className="Shadow1"
                style={{
                  objectFit: "contain",
                  width: "80vw",
                  minWidth: "350px",
                  maxWidth: "500px",
                }}
                src={mobileInvoicePreview}
                alt="mobile-invoice-preview"
              />
            </Box>
          </Box>
        </Box>
        <div />
      </Box>
    );
  };

  const SmallClientScreen = () => {
    return (
      <Box
        component="header"
        sx={{
          paddingTop: 4,
          // padding: 5,
          backgroundColor: "#D4E3FD",
          minHeight: "60vmin",
          overflow: "hidden",
          display: "grid",
          width: "100vw",
          gridTemplateColumns: "1fr",
          justifyItems: "center",
        }}
      >
        <Box justifySelf="center">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#103A93",
              paddingBottom: "30px",
            }}
          >
            <div>
              <Typography sx={{ mb: 1, pl: 3 }} variant="h5">
                Clients
              </Typography>
            </div>
            <div>
              <Typography sx={{ mb: 3, pl: 3, pr: 3 }} variant="body1">
                View and search your current client list. See at a glance the
                unbilled sessions for the current billing month as well as the
                total amount you're owed. From this screen you can edit clients,
                enter payments, bill clients are more.
              </Typography>
            </div>
            <Box alignSelf="center">
              <img
                className="Shadow1"
                style={{
                  objectFit: "contain",
                  width: "80vw",
                  minWidth: "350px",
                  maxWidth: "500px",
                }}
                src={mobileClient}
                alt="full-client-list"
              />
            </Box>
          </Box>
        </Box>
        <div />
      </Box>
    );
  };

  const LargeClientScreen = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#D4E3FD",
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 0",
          width: "100vw",
        }}
      >
        <Box sx={{ maxWidth: "900px", width: "90vw" }}>
          <Box
            // component="span"
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#103A93",
              paddingBottom: "30px",
            }}
          >
            <div>
              <Typography sx={{ mb: 1 }} variant="h5">
                Clients
              </Typography>
            </div>
            <div>
              <Typography sx={{ mb: 1 }} variant="body1">
                View and search your current client list. See at a glance the
                unbilled sessions for the current billing month as well as the
                total amount you're owed. We put a little red dot next to the
                amount owed to indicate that the payment is late. From this
                screen you can edit clients, enter payments, bill clients are
                more.
              </Typography>
            </div>
            <Box>
              <img
                className="Shadow1"
                style={{ objectFit: "contain", width: "100%" }}
                src={fullClient}
                alt="full-client-list"
              />
            </Box>
            <Box
              sx={{
                paddingTop: "30px",
                gridColumnGap: "20px",
                display: "grid",
                gridTemplateColumns: "2fr 10fr 7fr 2fr",
              }}
            >
              <div />
              <img
                className="Shadow1"
                style={{ objectFit: "contain", width: "80%" }}
                src={mobileClient}
                alt="full-screen-client-list"
              />

              <Box
                sx={{
                  justifySelf: "center",
                  paddingTop: "30px",
                  font: "Georgia",
                }}
              >
                <Box typography="h4">On the Go? Take Workbox with you</Box>
                <Box typography="body1" sx={{ mt: "30px" }}>
                  Workbox is mobile-friendly, so anything that you need to do to
                  run your business can be handled wherever you are.
                </Box>
              </Box>
              <div />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  // const LargeInvoiceScreen = () => {
  //   return (
  //     <Box
  //       component="header"
  //       sx={{
  //         paddingTop: 4,
  //         padding: 5,
  //         backgroundColor: "#D4E3FD",
  //         minWidth: "1000px",
  //         minHeight: "60vmin",
  //         display: "grid",
  //         gridTemplateColumns: "1fr 1000px 1fr",
  //         justifyItems: "center",
  //       }}
  //     >
  //       <div />
  //       <div>
  //         <Box
  //           // component="span"
  //           sx={{
  //             display: "flex",
  //             alignItems: "flex-start",
  //             flexDirection: "column",
  //             color: "#103A93",
  //             paddingBottom: "30px",
  //             maxWidth: "920px",
  //           }}
  //         >
  //           <div>
  //             <Typography sx={{ mb: 1 }} variant="h5">
  //               Invoicing
  //             </Typography>
  //           </div>
  //           <div>
  //             <Typography sx={{ mb: 1 }} variant="body1">
  //               When you select a client to bill, you can preview what the final
  //               invoice will look like before pressing Send.
  //             </Typography>
  //           </div>
  //           <Box>
  //             <img
  //               className="Shadow1"
  //               style={{ objectFit: "contain", maxWidth: "100%" }}
  //               src={invoicePreview}
  //               alt="full-screen-invoice-preview"
  //             />
  //           </Box>
  //         </Box>
  //       </div>
  //       <div />
  //     </Box>
  //   );
  // };

  const invoiceDescription = isSmall
    ? "Invoices clients"
    : "Invoices clients (see sample invoice below)";
  const WhatItIs = () => {
    let width = size.width;
    let height = size.width * 0.62;
    if (height > size.height) {
      const adj = size.height / height;
      width = width * adj;
      height = width * 0.62;
    }
    return (
      <>
        <Box
          sx={{
            display: "flex",
            backgroundColor: "white",
            width: "100vw",
            // gridTemplateColumns: isSmall ? "100%" : ".9fr 1.1fr",
            // gridTemplateColumns: "100%",
            color: "#103A93",
            justifyContent: "center",
          }}
        >
          {/*<Typography sx={{m:"30px 10%",gridColumn:"1/-1"}} variant="h4">Workbox is different than most billing apps.  It's very useful for small practices with relatively simple and straitforward billing needs.</Typography>*/}

          <Box
            color="#103A93"
            sx={{ m: "80px 30px 5% 30px", maxWidth: "600px" }}
          >
            <Typography variant="h4">What it does...</Typography>
            <Typography variant="h6">
              <ol>
                <li>{invoiceDescription}</li>
                <li>Manages payments received</li>
                <li>Keeps track of amounts owed</li>
                <li>Transfers appointments from your Google calendar into Workbox (so they can be billed)</li>
                <li>Provides reports for yourself and your accountant</li>
              </ol>
            </Typography>
            {/*<Typography variant="h6">*/}
            {/*  And you can continue to use your regular phone or desktop calendar*/}
            {/*  for client appointments! No need to use a special scheduling*/}
            {/*  function inside the app (Google calendars supported now, Microsoft*/}
            {/*  coming in a future release.)*/}
            {/*</Typography>*/}
          </Box>
          {/*<Box color="#103A93" sx={{ m: "10% 10% 5% 20%" }}>*/}
          {/*  <Typography variant="h4">What it doesn't do...</Typography>*/}
          {/*  <Typography variant="h6">*/}
          {/*    <ol>*/}
          {/*      <li>Bill insurance companies</li>*/}
          {/*    </ol>*/}
          {/*  </Typography>*/}
          {/*  /!*<Box display={showMore ? "none" : "block"}>*!/*/}
          {/*  /!*  <Typography variant="caption" sx={{ display: "inline" }}>*!/*/}
          {/*  /!*    * With Workbox, you use the regular calendar that comes with*!/*/}
          {/*  /!*    your phone (or laptop) to manage patient appointments. These*!/*/}
          {/*  /!*    appointments are imported into Workbox when you're ready to*!/*/}
          {/*  /!*    bill. Currently, we support Google Calendars.*!/*/}
          {/*  /!*  </Typography>*!/*/}
          {/*  /!*  /!*<Typography*!/*!/*/}
          {/*  /!*  /!*  variant="body2"*!/*!/*/}
          {/*  /!*  /!*  onClick={() => setShowMore(!showMore)}*!/*!/*/}
          {/*  /!*  /!*  sx={{*!/*!/*/}
          {/*  /!*  /!*    pl: "4px",*!/*!/*/}
          {/*  /!*  /!*    color: "red",*!/*!/*/}
          {/*  /!*  /!*    display: "inline-block",*!/*!/*/}
          {/*  /!*  /!*    textDecoration: "underline",*!/*!/*/}
          {/*  /!*  /!*    "&:hover": {*!/*!/*/}
          {/*  /!*  /!*      cursor: "pointer",*!/*!/*/}
          {/*  /!*  /!*    },*!/*!/*/}
          {/*  /!*  /!*  }}*!/*!/*/}
          {/*  /!*  /!*  component="div"*!/*!/*/}
          {/*  /!*  /!*>*!/*!/*/}
          {/*  /!*  /!*  {" "}*!/*!/*/}
          {/*  /!*  /!*  Show More*!/*!/*/}
          {/*  /!*  /!*</Typography>*!/*!/*/}
          {/*  /!*</Box>*!/*/}
          {/*  <Box display={showMore ? "block" : "none"}>*/}
          {/*    <Typography variant="body2" sx={{ display: "inline" }}>*/}
          {/*      * With Workbox, you use the regular calendar that comes with*/}
          {/*      your phone (or laptop) to manage patient appointments. These*/}
          {/*      calendars are easy to use, powerful and reliable. When you're*/}
          {/*      ready to bill, you go to Workbox's Import menu and transfer your*/}
          {/*      appointments into Workbox. Currently, we support Google*/}
          {/*      calendars. Microsoft will be available in a future release.*/}
          {/*    </Typography>*/}
          {/*    <Typography*/}
          {/*      variant="body2"*/}
          {/*      onClick={() => setShowMore(!showMore)}*/}
          {/*      sx={{*/}
          {/*        pl: "4px",*/}
          {/*        color: "red",*/}
          {/*        display: "inline-block",*/}
          {/*        textDecoration: "underline",*/}
          {/*        "&:hover": {*/}
          {/*          cursor: "pointer",*/}
          {/*        },*/}
          {/*      }}*/}
          {/*      component="div"*/}
          {/*    >*/}
          {/*      {" "}*/}
          {/*      Show Less*/}
          {/*    </Typography>*/}
          {/*  </Box>*/}
          {/*</Box>*/}
        </Box>

        <Box
          bgcolor="white"
          width="100%"
          sx={{ padding: "10px", display: "flex", justifyContent: "center" }}
        >
          {showVideo && false && (
            <ReactPlayer
              // style={{ margin: "20px auto" }}
              url="https://www.youtube.com/watch?v=MaL_Amzy0PI?rel=0"
              // url="https://youtu.be/Gzcr87TNmmc/watch?v=MaL_Amzy0PI?rel=0"

              width={width}
              height={height}
              playsinline={false}
            />
          )}
        </Box>
        <Box
          sx={{
            paddingTop: showVideo ? "15px" : 0,
            paddingBottom: "90px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          {/*<Typography*/}
          {/*  sx={{ m: "0 20px", maxWidth: "700px" }}*/}
          {/*  color="#103A93"*/}
          {/*  variant="h6"*/}
          {/*>*/}
          {/*  WANT TO LEARN MORE? Click below to watch a short video. It could*/}
          {/*  help you decide if you'd like to continue and sign up.*/}
          {/*</Typography>*/}
          {/*<IconButton*/}
          {/*  onClick={() => setShowVideo((value) => !value)}*/}
          {/*  disableRipple={true}*/}
          {/*  sx={{ paddingBottom: 0, display: showVideo ? "none" : "block" }}*/}
          {/*  color="primary"*/}
          {/*  aria-label="Watch WorkboxDemo"*/}
          {/*>*/}
          {/*  <YouTube sx={{ fontSize: "80px", color: "red" }} fontSize="large" />*/}
          {/*</IconButton>*/}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowVideo((value) => !value)}
          >
            {showVideo ? "Hide Video" : "Watch Video"}
          </Button>
        </Box>
      </>
    );
  };

  const MediumPanel3 = () => {
    return (
      <Box
        sx={{
          display: "grid",
          backgroundColor: "white",
          width: "100vw",
          gridTemplateColumns: "1fr 1000px 1fr",
        }}
      >
        <div />
        <Box
          component="header"
          sx={{
            padding: 7,
            backgroundColor: "#fff",
            maxWidth: "100vw",
            display: "grid",
            gridTemplateColumns: "2fr 2fr",
          }}
        >
          <Box
            sx={{
              flexGrow: 5,
              fontFamily: "garamond",
              fontWeight: 400,
              fontSize: "48px",
            }}
          >
            <Typography sx={{ letterSpacing: ".08em" }} variant="h4">
              Simple Accounting,
            </Typography>
            <Typography variant="h4">Simple Pricing</Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                typography: "body1",
                fontSize: "16px",
                fontFamily: "garamond",
                mt: "20px",
              }}
            >
              <Box>
                <Box mb={2}>{"\u2713"} Purpose-built for therapists</Box>
                <Box mb={2}>{"\u2713"} Track clients and sessions</Box>
                <Box mb={2}>{"\u2713"} Unlimited invoices and billing</Box>
                <Box mb={2}>{"\u2713"} Manage payments</Box>
                <Box mb={2}>{"\u2713"} Full suite of reporting</Box>
                <Box mb={2}>{"\u2713"} Access on any device</Box>
              </Box>
              <Box />
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#D4E3FD",
              borderRadius: "5px",
              height: "290px",
            }}
          >
            <Box
              sx={{
                p: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mb: "20px",
              }}
            >
              <Box sx={{ fontSize: "35px", fontWeight: 500 }}>
                30-Day Free Trial
              </Box>
              <Box sx={{ mt: "20px", fontSize: "20px" }}>
                <Box>Then only $29/month.</Box>
                <Box>Cancel Anytime</Box>
              </Box>
              <Button
                size="large"
                sx={{ mt: "30px" }}
                color="info"
                variant="contained"
              >
                Sign up for free
              </Button>
            </Box>
          </Box>
        </Box>
        <div />
      </Box>
    );
  };

  const SmallPanel3 = () => {
    return (
      <Box
        sx={{
          display: "grid",
          backgroundColor: "white",
          width: "100vw",
          gridTemplateColumns: "1fr 1000px 1fr",
        }}
      >
        <div />
        <Box
          component="header"
          sx={{
            padding: 7,
            backgroundColor: "#fff",
            maxWidth: "100vw",
            display: "grid",
            gridTemplateColumns: "1fr",
          }}
        >
          <Box
            sx={{
              flexGrow: 5,
              fontFamily: "garamond",
              fontWeight: 400,
              fontSize: "48px",
            }}
          >
            <Typography sx={{ letterSpacing: ".08em" }} variant="h4">
              Simple Accounting,
            </Typography>
            <Typography variant="h4">Simple Pricing</Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                typography: "body1",
                fontSize: "16px",
                fontFamily: "garamond",
                mt: "20px",
              }}
            >
              <Box>
                <Box mb={2}>{"\u2713"} Purpose-built for therapists</Box>
                <Box mb={2}>{"\u2713"} Track clients and sessions</Box>
                <Box mb={2}>{"\u2713"} Unlimited invoices and billing</Box>
                <Box mb={2}>{"\u2713"} Manage payments</Box>
                <Box mb={2}>{"\u2713"} Full suite of reporting</Box>
                <Box mb={2}>{"\u2713"} Access on any device</Box>
              </Box>
              <Box sx={{ backgroundColor: "orange" }} />
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#D4E3FD",
              borderRadius: "5px",
              height: "290px",
            }}
          >
            <Box
              sx={{
                p: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mb: "20px",
              }}
            >
              <Box sx={{ fontSize: "35px", fontWeight: 500 }}>
                30-Day Free Trial
              </Box>
              <Box sx={{ mt: "20px", fontSize: "20px" }}>
                <Box>Then only $29/month.</Box>
                <Box>Cancel Anytime.</Box>
              </Box>
              <Button
                size="large"
                sx={{ mt: "30px" }}
                color="info"
                variant="contained"
              >
                Sign up for free
              </Button>
            </Box>
          </Box>
        </Box>
        <div />
      </Box>
    );
  };

  const LargePanel3 = () => {
    return (
      <Box
        sx={{
          display: "grid",
          backgroundColor: "white",
          width: "100vw",
          gridTemplateColumns: "1fr 1000px 1fr",
        }}
      >
        <div />
        <Box
          component="header"
          sx={{
            padding: 10,
            backgroundColor: "#fff",
            width: "1000px",
            display: "grid",
            gridTemplateColumns: "5fr 3fr",
          }}
        >
          <Box
            sx={{
              flexGrow: 5,
              fontFamily: "garamond",
              fontWeight: 400,
              fontSize: "48px",
            }}
          >
            <Typography sx={{ letterSpacing: ".08em" }} variant="h4">
              Simple Accounting,
            </Typography>
            <Typography variant="h4">Simple Pricing</Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                typography: "body1",
                fontSize: "16px",
                fontFamily: "garamond",
                mt: "20px",
              }}
            >
              <Box>
                <Box mb={2}>{"\u2713"} Purpose-built for therapists</Box>
                <Box mb={2}>{"\u2713"} Track clients and sessions</Box>
                <Box mb={1}>{"\u2713"} Unlimited invoices and billing</Box>
              </Box>
              <Box>
                <Box mb={2}>{"\u2713"} Manage payments</Box>
                <Box mb={2}>{"\u2713"} Full suite of reporting</Box>
                <Box mb={1}>{"\u2713"} Access on any device</Box>
              </Box>
              <Box sx={{ backgroundColor: "orange" }} />
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#D4E3FD",
              borderRadius: "5px",
              height: "100%",
            }}
          >
            <Box
              sx={{
                p: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mb: "20px",
              }}
            >
              <Box sx={{ fontSize: "35px", fontWeight: 500 }}>
                30-Day Free Trial
              </Box>
              <Box sx={{ mt: "20px", fontSize: "20px" }}>
                <Box>Then only $29/month.</Box>
                <Box>Cancel Anytime.</Box>
              </Box>
              <Button
                size="large"
                sx={{ mt: "30px" }}
                color="info"
                variant="contained"
              >
                Sign up for free
              </Button>
            </Box>
          </Box>
        </Box>
        <div />
      </Box>
    );
  };

  const SmallFooter = () => {
    return (
      <Box
        component="header"
        sx={{
          backgroundColor: "#000",
          padding: "10px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          color: "white",
          alignItems: "center",
          width: "100vw",
        }}
      >
        <Typography
          variant="body2"
          onClick={() => nav("/terms")}
          sx={{
            m: 1,
            color: "white",
            fontSize: ".82rem",
            fontWeight: 500,
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          }}
        >
          Terms of Service
        </Typography>
        <Typography
          variant="body2"
          onClick={() => nav("/privacy")}
          sx={{
            mb: 0,
            m: 1,
            color: "white",
            fontSize: ".82rem",
            fontWeight: 500,
            justifySelf: "flex-end",
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          }}
        >
          Privacy Policy
        </Typography>
        {/*<Typography*/}
        {/*  variant="body2"*/}
        {/*  onClick={() => nav("/contact")}*/}
        {/*  sx={{*/}
        {/*    m: 1,*/}
        {/*    color: "white",*/}
        {/*    fontSize: ".82rem",*/}
        {/*    fontWeight: 500,*/}
        {/*    justifySelf: "flex-end",*/}
        {/*    "&:hover": {*/}
        {/*      textDecoration: "underline",*/}
        {/*      cursor: "pointer",*/}
        {/*    },*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Contact Us*/}
        {/*</Typography>*/}
        <MailLink
          sx={{
            ml: 1,
            mb: 1,
            gridColumn: "1 / 4",
            fontWeight: 500,
            typography: "body2",
            color: "inherit",
          }}
          href={`mailto:support@workboxsoftware.com`}
        >
          support@workboxsoftware.com
        </MailLink>
        <Box
          sx={{
            ml: 1,
            mb: 1,
            typography: "body2",
            fontSize: "12px",
            gridColumn: "1 / 4",
            fontWeight: 500,
          }}
        >
          2022 Copyright Workbox Software LLC. All rights reserved.
        </Box>
      </Box>
    );
  };

  const LargeFooter = () => {
    return (
      <Box
        component="header"
        sx={{
          backgroundColor: "#000",
          padding: "20px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 4fr",
          color: "white",
          alignItems: "center",
          width: "100vw",
        }}
      >
        <Typography
          variant="body2"
          onClick={() => nav("/terms")}
          sx={{
            m: 1,
            color: "white",
            fontSize: ".82rem",
            fontWeight: 500,
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          }}
        >
          Terms of Service
        </Typography>
        <Typography
          variant="body2"
          onClick={() => nav("/privacy")}
          sx={{
            m: 1,
            color: "white",
            fontSize: ".82rem",
            fontWeight: 500,
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          }}
        >
          Privacy Policy
        </Typography>
        <Box textAlign="right" typography="body2" marginRight="8px">
          2022 Copyright Workbox Software LLC. All rights reserved.
        </Box>
        {/*<Typography*/}
        {/*  variant="body2"*/}
        {/*  onClick={() => nav("/contact")}*/}
        {/*  sx={{*/}
        {/*    m: 1,*/}
        {/*    color: "white",*/}
        {/*    fontSize: ".82rem",*/}
        {/*    fontWeight: 500,*/}
        {/*    "&:hover": {*/}
        {/*      textDecoration: "underline",*/}
        {/*      cursor: "pointer",*/}
        {/*    },*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Contact Us*/}
        {/*</Typography>*/}
        <Typography
          variant="body2"
          sx={{
            m: 1,
            color: "white",
            fontSize: ".82rem",
            fontWeight: 500,
          }}
        >
          Version 1.0.1
        </Typography>
        <MailLink
          sx={{
            mr: "8px",
            textAlign: "right",
            fontSize: "14px",
            color: "inherit",
          }}
          href={`mailto:support@workboxsoftware.com`}
        >
          support@workboxsoftware.com
        </MailLink>
      </Box>
    );
  };

  const Panel1 = size.fullWidth < 1000 ? SmallPanel1 : LargePanel1;
  const Panel3 = size.fullWidth < 1000 ? MediumPanel3 : LargePanel3;

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <ShowYouTubeVideo
          setOpen={setShowVideo}
          open={showVideo}
          url="https://www.youtube.com/watch?v=MaL_Amzy0PI?rel=0"
        />
        {/*<Dialog open={dialogOpen}>*/}
        {/*  <DialogTitle>Workbox Accounting for Therapists</DialogTitle>*/}
        {/*  <DialogContent>Coming 2nd Quarter 2022!</DialogContent>*/}
        {/*</Dialog>*/}
        {!isSmall ? (
          <Box color="black">
            <Nav />
            <Panel1 />
            <WhatItIs />
            {false && <LargePanel2 />}
            <LargeClientScreen />
            <LargeSessionScreen />
            <LargeInvoiceScreen />
            <Panel3 />
            <LargeFooter />
          </Box>
        ) : (
          <Box width="100vw" overflow="hidden">
            <Nav />
            <SmallPanel1 />
            {false && <SmallPanel2 />}
            <WhatItIs />
            <SmallClientScreen />
            <SmallSessionScreen />
            <SmallInvoicesScreen />
            <SmallPanel3 />
            <SmallFooter />
          </Box>
        )}
      </ThemeProvider>
    </>
  );
};
