import { Box, Button, Container, useTheme } from "@mui/material";
import printJS from "print-js";

import { Page, pdfjs, Document } from "react-pdf/dist/esm/entry.webpack";
import * as React from "react";
import { LoadingScreen } from "../LoadingScreen";
import { useWindowSize } from "../../hooks";
import { useInvoicePdfQuery } from "../../generated/graphql";
import { b64toBlob } from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { localCache } from "../../graphqlDocuments";
import { BackButton } from "../Buttons";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ViewInvoicePdf: React.FC = () => {
  const [pdfLoading, setPdfLoading] = React.useState(true);
  const [numPages, setNumPages] = React.useState(0);
  const nav = useNavigate();
  const theme = useTheme();
  const mdWidth = theme.breakpoints.values.md;
  const size = useWindowSize();
  const pageSize = size.fullWidth > mdWidth ? mdWidth : size.fullWidth - 30;
  const { invoiceId = "adsfasfasdfsd" } = useParams(); // will fail if invalid and nav(-1)
  const { loading, data } = useInvoicePdfQuery({
    variables: {
      companyId: localCache().companyId,
      invoiceId, // fail if no valid invoiceId
    },
    onCompleted(data) {
      if (!data.invoicePdf.success) {
        nav(-1);
      }
    },
  });

  if (loading || !data?.invoicePdf.base64Pdf) {
    return <LoadingScreen loading={true} />;
  }

  const contentType = "application/pdf";
  let pdfUrl: string = "";
  if (data?.invoicePdf.base64Pdf) {
    const blob = b64toBlob(data.invoicePdf.base64Pdf, contentType);
    pdfUrl = URL.createObjectURL(blob);
  }

  const onDocumentLoadSuccess = (pdf: any) => {
    setPdfLoading(false);
    setNumPages(pdf.numPages);
  };

  const emailedLabel = pdfLoading
    ? ""
    : loading
    ? ""
    : data.invoicePdf.emailedDate
    ? `Sent to client: ${data.invoicePdf.emailedDate}`
    : "";

  return (
    <>
      <BackButton />
      <Container maxWidth="lg" sx={{ mt: "10px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: "-10px",
            mb: "30px",
          }}
        >
          <Button
            sx={{ ml: "auto", mr: "auto" }}
            // sx={{ margin: { xs: "25px 0 20px 20px", sm: "25px 0 20px 0" } }}
            type="button"
            variant="contained"
            onClick={() => printJS(pdfUrl)}
          >
            Print
          </Button>
        </Box>
        <Box
          // typography="body1"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent:"center",
            mt: "-10px",
            mb: "10px",
          }}
        >
          <div>{emailedLabel}</div>
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div>
            <LoadingScreen loading={pdfLoading} />
            {/*<Box m="0 auto">{emailedLabel}</Box>*/}
            <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages ? numPages : 1), (_el, index) => (
                <div key={index}>
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={pageSize}
                  />
                  <div
                    style={{
                      backgroundColor: "grey",
                      height: "5px",
                      width: "100%",
                    }}
                  />
                </div>
              ))}
            </Document>
          </div>
        </Box>
      </Container>
    </>
  );
};
