import {useNavigate} from 'react-router-dom'
import React, {useEffect} from 'react'
import {GraphQLError} from "graphql";
import {ServerError, ServerParseError} from '@apollo/client'


interface IErrors {
  graphQLErrors?: GraphQLError|null,
  networkError?: ServerError|ServerParseError|Error|null
}

export const useCrash = () => {
  const [error, setError] = React.useState<IErrors>({})
  const nav = useNavigate();

  useEffect(() => {
    if (error) {
      const graphQLErrors = error.graphQLErrors;
      const networkError = error.networkError;
      nav("/crash", {replace: true, state: {graphQLErrors, networkError}})
    }
  }, [error, nav])

  return setError;
}
