import * as React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Paper, Typography } from "@mui/material";
import { AuthenticationService } from "../../services";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
  },
  panel: {
    height: 300,
    width: 300,
    padding: 20,
    position: "relative",
  },
  errorMsg: {
    margin: "20px auto",
  },
  submit: {
    margin: "20px 15px 20px auto",
    display: "block",
    width: 120,
  },
}));

interface IProps {
  error: string;
}
export const FailedScreen: React.FC<IProps> = ({ error }) => {
  const classes = useStyles();

  const handleSubmit = () => {
    AuthenticationService.getFirebaseAuthentication().signOut();
  };

  return (
    <div className={classes.root}>
      <Paper elevation={8} className={classes.panel}>
        <form onSubmit={handleSubmit}>
          <Typography variant='h6' className={error}>
            {error}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.submit}
          >
            Close
          </Button>
        </form>
      </Paper>
    </div>
  );
};
