import {
  ClientEntryController,
  IFormValues,
  initialValues,
  mapFormValuesToVariables,
} from "./index";
import { useCreateClientMutation } from "../../generated/graphql";
import {
  IClientService,
  IRecordSavedProps,
  IUpdateRet,
  RecordSavedSourceEnum,
  SaveRetErrorType
} from "../../types";
import { EntryMode, toFloat } from "../../utils";

interface IProps {
  recordSaved?: (args: IRecordSavedProps) => void;
}

export const AddClientContainer = (props: IProps) => {
  const [createClient] = useCreateClientMutation();

  const handleSave = async (values: IFormValues): Promise<IUpdateRet> => {
    try {
      // const variables = mapFormValuesToVariables(values);
      const variables = {
        ...mapFormValuesToVariables(values),
        openingBalance: values.openingBalance
          ? toFloat(values.openingBalance)
          : undefined,
      };

      const ret = await createClient({
        variables,
        update: (cache, { data }) => {
          if (data?.createClient?.client) {
            const cacheId = cache.identify(data?.createClient.client);
            if (!cacheId) {
              throw new Error("CreateClient: Invalid Cache ID");
            }
            cache.modify({
              fields: {
                clients: (existingFieldData, { toReference }) => {
                  return [...existingFieldData, toReference(cacheId)];
                },
              },
            });
          }

          if (data?.createClient?.clientDashboard) {
            const cacheId = cache.identify(data?.createClient.clientDashboard);
            if (!cacheId) {
              throw new Error(
                "CreateClient: Invalid Cache ID for client dashboard"
              );
            }
            cache.modify({
              fields: {
                clientDashboard: (existingFieldData, { toReference }) => {
                  return [...existingFieldData, toReference(cacheId)];
                },
              },
            });
          }
        },
      });
      const retClient = ret?.data?.createClient?.client;
      const retErrors = ret?.data?.createClient?.errors;

      const retClientServices:Array<IClientService> = retClient?.clientServices ?  retClient?.clientServices.map(cs => {
        return {
          description:cs.description,
          serviceId:cs.serviceId,
          id:cs.id,
          sessionFee:cs.sessionFee,
        }
      }) : []
      // if all ok, then return success and invoke recordSaved callback
      if (!retErrors && retClient) {
        if (props.recordSaved) {
          const snackbarDescription = `Client Added: ${retClient.displayName}`;
          props.recordSaved({
            source: RecordSavedSourceEnum.client,
            id: retClient?.id,
            snackbarDescription,
            clientId: retClient?.id,
            optionsDescription: retClient.displayName,
            clientServices:retClientServices
          });
        }
        return { success: true };
      }

      const err = retErrors ? retErrors[0] : { message: "Errors Found" };
      console.log("client save", err)
      return {
        success: false,
        errorMessage: err.message,
      };
    } catch (e:any) {
      console.log("client save", e.message||"Got network error")
      return {
        success: false,
        errorMessage: e.message||"Got network error",
        errorType: SaveRetErrorType.Network,
      };
    }
  };

  return (
    <ClientEntryController
      mode={EntryMode.ADD}
      initialValues={initialValues}
      saveData={handleSave}
      {...props}
    />
  );
};
