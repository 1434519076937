import { IFormValues } from "./index";
import React, { Dispatch } from "react";
import { AlertDialog, IAlertProps } from "components/Alert";
import { Form, Formik, FormikErrors, FormikHelpers, FormikValues } from "formik";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  TextField
} from "@mui/material";
import { EntryContainer } from "components/Containers";
import { SubmitButton } from "components/Buttons";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { IUpdateRet } from "../../../types";
import { useNavigate } from "react-router-dom";
import { useTopOfForm } from "../../../hooks";
import { SelectClient } from "../../Client";
import dayjs from "dayjs";

interface IProps {
  executeRequest: (values: IFormValues, file?: File) => Promise<IUpdateRet>;
  initialValues: IFormValues;
  setFormValues: Dispatch<React.SetStateAction<IFormValues>>;
}

export const SuperbillRequest: React.FC<IProps> = ({
  setFormValues,
  executeRequest,
  initialValues,
}) => {
  const [alertArgs, setAlertArgs] = React.useState<IAlertProps>({
    open: false,
    message: "",
    title: "",
  });

  const nav = useNavigate();
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);

  // const classes = useStyles();

  useTopOfForm();

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<IFormValues> = {};

    if (!values.client) {
      errors.client = "Please select client";
    }

    if (!values.dateRange[0] || !values.dateRange[1]) {
      errors.dateRange = "Select dates";
    }

    const formattedStartDate = dayjs(values.dateRange[0]).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(values.dateRange[1]).format("YYYY-MM-DD");
    if (formattedStartDate > formattedEndDate) {
      errors.dateRange = "Start date is after End date";
    }

    return errors;
  };

  const handleSubmit = async (
    values: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    setFormValues(values);
    const ret = await executeRequest(values);
    formikHelpers.setSubmitting(false);
    if (ret.success) {
      if (values.reportFormat === "PDF") {
        nav("pdf-viewer");
      } else {
        setAlertArgs({
          open: true,
          title: "Status",
          message: "CSV Download complete",
        });
      }
    } else {
      setAlertArgs({
        open: true,
        title: "Report Unsuccessful",
        message: ret.errorMessage,
      });
    }
  };

  return (
    <Box mt="15px">
      <AlertDialog alertProps={alertArgs} setAlertProps={setAlertArgs} />
      <Formik
        enableReinitialize={true}
        validate={validate}
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          handleSubmit(values, formikHelpers);
        }}
      >
        {(formikHelpers) => {
          const {
            values,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            errors,
            touched,
          } = formikHelpers;
          const dateRangeError =
            touched.dateRange && !datePickerOpen ? errors.dateRange : "";

          return (
            <Form noValidate autoComplete="off">
              <EntryContainer
                maxWidth={500}
                title="Super Bill"
                title2="Reports on all billed sessions within a date range"
              >
                <Card raised={true}>
                  <CardContent>
                    <Stack spacing="16px">
                      <SelectClient
                        hideAddNewButton={true}
                        onShowAddScreen={() => null}
                      />
                      <Box
                        typography="subtitle"
                        mt="4px"
                        sx={{ mb: "-10px !important" }}
                      >
                        Session dates to include:
                      </Box>
                      <DateRangePicker
                        onOpen={() => {
                          setDatePickerOpen(true);
                          setFieldTouched("dateRange");
                        }}
                        open={datePickerOpen}
                        onClose={() => setDatePickerOpen(false)}
                        value={values.dateRange}
                        onChange={(newValue: any) => {
                          // console.log("keyboard input", keyboardInputValue)
                          setFieldValue("dateRange", newValue);
                        }}
                        renderInput={(startProps: any, endProps: any) => (
                          <React.Fragment>
                            <TextField
                              sx={{ flexGrow: 1 }}
                              {...startProps}
                              error={Boolean(dateRangeError)}
                              helperText={dateRangeError}
                            />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <TextField
                              sx={{ flexGrow: 1 }}
                              {...endProps}
                              helperText=""
                            />
                          </React.Fragment>
                        )}
                      />
                      <FormGroup>
                        <FormControlLabel
                          data-testid="showBalanceDue"
                          control={
                            <Checkbox
                              checked={values.showBalanceDue}
                              onChange={() =>
                                setFieldValue("showBalanceDue", !values.showBalanceDue)
                              }
                            />
                          }
                          label="Show Balance Due?"
                        />
                        <FormHelperText sx={{mt:"-9px", pl:"32px"}}>Check this box to include the client's current Balance Due on the report.</FormHelperText>
                      </FormGroup>
                    </Stack>
                  </CardContent>
                </Card>
                <SubmitButton disabled={isSubmitting} label="Submit" />
              </EntryContainer>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
