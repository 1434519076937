import {useClientGetter} from "../hooks";
import { utils } from "../utils";

interface IFullNameRet {
  fullName1:string;
  fullName2?:string
}
export const useFullName = (clientId: string):IFullNameRet => {
  const client = useClientGetter().getOneClient(clientId)

  return utils.fullClientName(client)
  // let fullName1, fullName2;
  // if (!client?.firstName2 && !client?.lastName2) {
  //   fullName1 = `${client?.firstName} ${client?.lastName}`;
  // } else if (client?.firstName2 && !client?.lastName2) {
  //   fullName1 = `${client?.firstName} and ${client?.firstName2} ${client?.lastName}`;
  // } else {
  //   fullName1 = `${client?.firstName} ${client?.lastName}`;
  //   fullName2 = `${client?.firstName2} ${client?.lastName2}`;
  // }
  // return {fullName1, fullName2}
}
